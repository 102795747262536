/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CurrencySwitch, CalendarSwitch } from 'components'
import './inner-controls.scss'

class InnerControls extends Component {
    static propTypes = {
        hide: PropTypes.array,

        request: PropTypes.object,
        workHours: PropTypes.object,
        actions: PropTypes.object,
        timeZoneName: PropTypes.string,

        update: PropTypes.func,
        onChange: PropTypes.func,
        onActiveDate: PropTypes.func,

        shiftDisabled: PropTypes.bool,
        useGrowing: PropTypes.bool,
        showCurrency: PropTypes.bool,
        showGrowing: PropTypes.bool,
        hideDetalization: PropTypes.bool,
    }

    update = ( data ) => this.props.update( data )
    change = ( data ) => this.props.onChange( data )

    render () {
        const {
            hide,
            request,
            actions,
            workHours,
            useGrowing,
            showGrowing,
            showCurrency,
            hideDetalization,
            onActiveDate,
            timeZoneName,
            shiftDisabled,
            predef
        } = this.props

        return (
            <div className="inner-controls view-type-switch">
                <CalendarSwitch
                    hide={hide}
                    request={request}
                    actions={actions}
                    workHours={workHours}
                    update={this.update}
                    useGrowing={showGrowing || useGrowing}
                    hideDetalization={hideDetalization}
                    onActiveDate={onActiveDate}
                    timeZoneName={timeZoneName}
                    shiftDisabled={shiftDisabled}
                />

                {showCurrency && (
                    <CurrencySwitch
                        request={request}
                        update={this.change}
                        showGrowing={showGrowing}
                    />
                )}
            </div>
        )
    }
}

export default InnerControls
