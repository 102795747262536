/* IMPORT */

import { restaurantsRatingActions, requestActions } from 'services'
import { IState }                                   from 'store'

/* EXPORT */

export const mapStateToProps = ( state: IState ) => ({
    categories: { shouldUpdate: state.restaurantsRating.shouldUpdate },
    user: state.user.info,
    request: state.request,
    sorter: state.restaurantsRating.sorter
})

export const allActions = {
    ...requestActions,
    ...restaurantsRatingActions,
}

export type RatingStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
