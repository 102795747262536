/* VENDOR */
import React, { Component }         from 'react'
import PropTypes                    from 'prop-types'
import { connect }                  from 'react-redux'
import { Card, Checkbox, Dropdown } from 'antd'


/* APPLICATION */
import { Icons, ReportTable } from 'components'

import './report.scss'

const SETTINGS_STORAGE_PREFIX = 'settings'

class Report extends Component {
    static propTypes = {
        unit: PropTypes.oneOf([ 'percent', 'currency', 'none' ]),

        data: PropTypes.object,
        columns: PropTypes.array.isRequired,
        columnHideSettings: PropTypes.object, // storageKey, disabledItems

        rowKey: PropTypes.string,
        filter: PropTypes.bool,

        prepare: PropTypes.func,
        onMore: PropTypes.func,
        onSort: PropTypes.func
    }

    constructor ( props ) {
        super( props )

        const { columnHideSettings } = props

        this.state = {
            isSettingsOpen: false,
            hided: []
        }

        this.storageKey = columnHideSettings ? `${SETTINGS_STORAGE_PREFIX}_${columnHideSettings.storageKey}` : null
        this.isHideable = Boolean( this.storageKey )

        if ( this.storageKey ) {
            const hided = localStorage.getItem( this.storageKey )

            if ( hided ) {
                this.state.hided = JSON.parse( hided )
            }
        }
    }


    static defaultProps = { rowKey: 'label' }

    handleSettingsOpenChange = ( flag ) => {
        this.setState({ isSettingsOpen: flag })
    }

    getSettingsItems = () => {
        const { columns, columnHideSettings } = this.props
        const { hided } = this.state

        const items = columns.map( col => {
            const title = col.settingsTitle || col.title
            return {
                key: col.dataIndex,
                label: <Checkbox
                    onClick={() => this.handleSettingsItemClick( col.dataIndex )}
                    checked={!hided.includes( col.dataIndex )}>
                    {title}
                </Checkbox>
            }
        })

        return items.filter( item => !columnHideSettings?.disabledItems?.includes( item.key ))
    }

    handleSettingsItemClick = ( key ) => {
        const { hided } = this.state

        const itemIndex = hided.indexOf( key )

        if ( itemIndex >= 0 ) {
            hided.splice( itemIndex, 1 )
        } else {
            hided.push( key )
        }

        localStorage.setItem( this.storageKey, JSON.stringify( hided ))

        this.setState({ hided })
    }

    render () {
        return (
            <Card bordered={false} className="report-table report-page">
                {this.props.children}
                {this.isHideable && <Dropdown
                    placement="bottomRight"
                    trigger="click"
                    menu={{ items: this.getSettingsItems() }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onOpenChange={this.handleSettingsOpenChange}
                    open={this.state.isSettingsOpen}
                >
                    <div>
                        <Icons.Settings.def className="settings-icon" />
                    </div>
                </Dropdown>}
                <ReportTable
                    unit={this.props.request.unitOfMeasure}
                    {...this.props}
                    columns={this.props.columns.filter( col => !this.state.hided.includes( col.dataIndex ))}
                />
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request })

export default connect( mapStateToProps )( Report )
