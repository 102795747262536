import { TableGroupType }                   from '../../Rating'
import { useEffect, useState }              from 'react'
import { RatingDataQueries, RatingFilters } from './useLoadRatingData'
import { RatingSorter }                     from './useRatingSorter'

const STORAGE_KEY = 'ratingTableGroup'

export const useRatingTableGroup = ( queries: RatingDataQueries, filters: RatingFilters, currentTab: string, sorter: RatingSorter ): [TableGroupType, ( ratingTableGroup: TableGroupType ) => void] => {
    const [ ratingTableGroup, setRatingTableGroup ] = useState<TableGroupType>( 'RESTAURANTS' )

    const changeRatingTableGroup = ( ratingTableGroup: TableGroupType ) => {
        setRatingTableGroup( ratingTableGroup )
        sessionStorage.setItem( STORAGE_KEY, ratingTableGroup )
    }

    useEffect(() => {
        const request = {
            ...queries.request,
            franchiseeGroupCodes: filters.partner,
            cities: filters.cities,
            regionCoachCodes: filters.regionCoaches,
            marketCoachCodes: filters.marketCoaches,
            areaCoachCodes: filters.areaCoaches,
            factsNumbers: filters.factsNumbers,
            facilityTypeIds: filters.facilityTypes,
            dataGroup: ratingTableGroup || 'RESTAURANTS'
        } as Record<string, any>

        if ( sorter.tab && sorter.field && sorter.order ) {
            request.sort = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
        }

        if ( request.restaurantIds?.length ) {
            request.factsNumbers = request.restaurantIds
            delete request.restaurantIds
        }

        delete request.employeeId
        delete request.zoneId

        if ( currentTab === 'turnover' ) {
            queries.flushTurnoverRating()
            queries.flushTurnoverChart()
            queries.fetchRatingTurnover( request )
            queries.fetchAllTurnover( request )
            queries.fetchTurnoverPlanAndFact( request )
        }

        if ( currentTab === 'average' ) {
            queries.flushAverageRating()
            queries.fetchAllAverageCheck( request )
            queries.fetchRatingAverageCheck( request )
        }

        if ( currentTab === 'performance' ) {
            queries.flushPerformanceRating()
            queries.flushPerformanceChart()
            queries.fetchRatingPerformance( request )
            queries.fetchPerformancePlanAndFact( request )
            queries.fetchSummaryPerformance( request )
        }

        if ( currentTab === 'time' ) {
            queries.flushTimeRating()
            queries.fetchAllServiceTime({ ...request, serviceType: null })
            queries.fetchServiceTime({ ...request, serviceType: null })
        }
        if ( currentTab === 'categories' && filters.category?.categoryId ) {
            queries.flushManagerRestaurants()
            queries.fetchManagerRestaurants({
                ...request,
                categoryId: filters.category.categoryId,
                userId: filters.manager.userId
            })
        }
    }, [ ratingTableGroup ])

    return [ ratingTableGroup, changeRatingTableGroup ]
}
