import React, { useEffect, useRef, useState }            from 'react'
import { Layout }                                        from 'antd'
import { AppHeader, InnerControls }                      from 'components'
import { format }                                        from 'tools'
import { connect }                                       from 'react-redux'
import { allActions, mapStateToProps, RatingStoreProps } from './connector'
import { groupName }                                     from 'components/rating/GroupSwitch/const'
import RatingTabs                                        from 'components/rating/RatingTabs/RatingTabs'
import { useLoadRatingData }                             from './helpers/hooks/useLoadRatingData'
import { useLoadChartData }                              from './helpers/hooks/useLoadChartData'
import { addFiltersToRequest }                           from './helpers/utils'
import { useRatingTableGroup }                           from './helpers/hooks/useRatingTableGroup'
import './rating.scss'
import { useRatingSorter }                               from './helpers/hooks/useRatingSorter'
import { setCurrency, setGrowing }                       from '../../services/request'

const { Content, Header } = Layout
const STORAGE_KEY = 'ratingTableGroup'
const filterCodes = {
    cities: 'CITIES',
    regionCoaches: 'REGION_COACHES',
    marketCoaches: 'MARKET_COACHES',
    areaCoaches: 'AREA_COACHES',
    factsNumbers: 'RESTAURANTS',
    facilityTypes: 'FACILITY_TYPES',
    partner: 'FRANCHISEE_GROUPS'
} as const

export type TableGroupType = keyof typeof groupName
export type FilterNames = 'partner' | 'cities' | 'regionCoaches' | 'marketCoaches' | 'areaCoaches' | 'factsNumbers' | 'facilityTypes'

const Rating = ( props: RatingStoreProps ) => {
    const [ partner, setPartner ] = useState<number[]>([])
    const [ cities, setCities ] = useState<number[]>([])
    const [ regionCoaches, setRegionCoaches ] = useState<number[]>([])
    const [ marketCoaches, setMarketCoaches ] = useState<number[]>([])
    const [ areaCoaches, setAreaCoaches ] = useState<number[]>([])
    const [ factsNumbers, setFactsNumbers ] = useState<number[]>([])
    const [ facilityTypes, setFacilityTypes ] = useState<number[]>([])
    const [ selected, setSelected ] = useState<number[]>([])

    const [ lastModifiedFilter, setLastModifiedFilter ] = useState<string | null>( null )
    const [ category, setCategory ] = useState<any>( null )
    const [ manager, setManager ] = useState<any>( null )

    const [ view, setView ] = useState<string>( 'turnover' )

    const { request } = props
    const filters = {
        partner,
        cities,
        regionCoaches,
        marketCoaches,
        areaCoaches,
        factsNumbers,
        facilityTypes,
        category,
        manager,
        selected,
    }

    const [ sorter, setSorter ] = useRatingSorter( props, filters, view )
    const [ ratingTableGroup, setRatingTableGroup ] = useRatingTableGroup( props, filters, view, sorter )
    const load = useLoadRatingData( props, filters, view, sorter )
    const loadChart = useLoadChartData( props, filters, view )

    const handleSelect = ( selected: number[]) => {
        const rids = selected && selected.length > 0 ? selected : null
        setSelected( rids || [])
    }

    const handleTabChange = ( key: string ) => {
        setSorter({})
        setView( key )
    }

    useEffect(() => {
        props.setGrowing( false )
        props.setCurrency( 'currency' )

        setRatingTableGroup( sessionStorage.getItem( STORAGE_KEY ) as TableGroupType || 'RESTAURANTS' )
    }, [])

    useEffect(() => {
        load()
        loadChart()
    }, [ request.dateStart, request.dateEnd, request.dateGroup, request.unitOfMeasure, request.currentPredef, request.restaurantId, view ])

    useEffect(() => {
        loadChart()
    }, [ request.growing, selected ])


    useEffect(() => {
        if ( props.categories.shouldUpdate ) {
            props.flushShouldUpdate()
            load( true )
        }
    }, [ props.categories.shouldUpdate ])

    useEffect(() => {
        const request = {
            ...props.request,
            franchiseeGroupCodes: partner,
            cities: cities,
            regionCoachCodes: regionCoaches,
            marketCoachCodes: marketCoaches,
            areaCoachCodes: areaCoaches,
            factsNumbers: factsNumbers,
            facilityTypeIds: facilityTypes,
            dataGroup: ratingTableGroup || 'RESTAURANTS'
        } as Record<string, any>

        if ( request.restaurantIds?.length ) {
            request.factsNumbers = request.restaurantIds
            delete request.restaurantIds
        }

        if ( sorter.tab && sorter.field && sorter.order ) {
            request.sort = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
        }

        delete request.restaurantId
        delete request.employeeId
        delete request.zoneId

        props.flushCategoriesRating()
        props.flushTurnoverRating()
        props.flushTurnoverChart()

        if ( view === 'turnover' ) {
            props.fetchRatingTurnover( request )
            props.fetchAllTurnover( request )
            props.fetchTurnoverPlanAndFact( request )
        }

        if ( view === 'average' ) {
            props.flushAverageRating()
            props.fetchAllAverageCheck( request )
            props.fetchRatingAverageCheck( request )
        }

        if ( view === 'time' ) {
            props.flushTimeRating()
            props.fetchAllServiceTime({ ...request, serviceType: null })
            props.fetchServiceTime({ ...request, serviceType: null })
        }

        if ( view === 'categories' ) {
            props.fetchCategoriesRating( request )
        }

        if ( view === 'performance' ) {
            props.fetchRatingPerformance( request )
            props.fetchPerformancePlanAndFact( request )
            props.fetchSummaryPerformance( request )
        }
    }, [ partner, cities, regionCoaches, marketCoaches, areaCoaches, factsNumbers, facilityTypes ])

    useEffect(() => {
        const { user } = props
        const reqObj = { ...props.request } as Record<string, any>
        delete reqObj.zoneId

        if ( user.marketManager ) {
            props.flushCategoryManagers()
            category &&
                props.fetchCategoryManagers({
                    ...reqObj,
                    categoryId: category.categoryId,
                })
        } else {
            category && setManager({ ...user })
        }
    }, [ category ])

    useEffect(() => {
        const { user } = props
        const request = {
            ...props.request,
            franchiseeGroupCodes: partner,
            cities: cities,
            regionCoachCodes: regionCoaches,
            marketCoachCodes: marketCoaches,
            areaCoachCodes: areaCoaches,
            factsNumbers: factsNumbers,
            facilityTypeIds: facilityTypes,
            dataGroup: ratingTableGroup || 'RESTAURANTS'
        }

        const reqObj = { ...request } as Record<string, any>
        delete reqObj.zoneId

        props.flushManagerRestaurants()
        manager &&
            props.fetchManagerRestaurants({
                ...reqObj,
                categoryId: category.categoryId,
                userId: manager.userId,
            })

        if ( !user.marketManager && !manager ) {
            setCategory( null )
        }
    }, [ manager ])

    const handleFocus = () => {
        const req: Record<string, any> = {}
        addFiltersToRequest( req, filters )
        req.lastModifiedFilter = lastModifiedFilter

        props.fetchFilters( req )
    }

    const setFilter = ( filter: {name: FilterNames, value: number[]}) => {
        const filterSetters: Record<FilterNames, ( arg: number[]) => void> = {
            partner: setPartner,
            cities: setCities,
            regionCoaches: setRegionCoaches,
            marketCoaches: setMarketCoaches,
            areaCoaches: setAreaCoaches,
            factsNumbers: setFactsNumbers,
            facilityTypes: setFacilityTypes,
        }

        filterSetters[ filter.name ]( filter.value )
        setSelected([])
        setLastModifiedFilter( filterCodes[ filter.name ])
        setCategory( null )
        setManager( null )
    }

    const flushFilters = () => {
        setPartner([])
        setCities([])
        setRegionCoaches([])
        setMarketCoaches([])
        setAreaCoaches([])
        setFactsNumbers([])
        setFacilityTypes([])
    }

    const showFlushFiltersButton = Boolean(
        partner.length ||
        cities.length ||
        regionCoaches.length ||
        marketCoaches.length ||
        areaCoaches.length ||
        factsNumbers.length ||
        facilityTypes.length
    )

    const appendQuery = ( service: 'average' | 'turnover' | 'performance' | 'serviceTime' ) => {
        const queries = {
            average: props.appendRatingAverageCheck,
            turnover: props.appendRatingTurnover,
            performance: props.appendRatingPerformance,
            serviceTime: props.appendServiceTime
        } as Record<string, ( req: Record<string, any> ) => void>
        const query = queries[ service ]

        return ( params: Record<string, any> ) => {
            const req = {
                ...params,
                factsNumbers: params.restaurantIds,
                dataGroup: ratingTableGroup || 'RESTAURANTS'
            } as Record<string, any>

            addFiltersToRequest( req, filters )

            if ( sorter.order ) {
                req.sort = `${sorter.field},${ sorter.order === 'ascend' ? 'asc' : 'desc'}`
            }

            delete req.restaurantId
            delete req.employeeId
            delete req.zoneId

            query( req )
        }
    }

    const onCategory = ( raw: Record<string, any> ) => {
        const cat =
                !raw || ( category && category.categoryId === raw.categoryId )
                    ? null
                    : raw
        setCategory( cat )
    }

    const onManger = ( raw: Record<string, any> ) => {
        const man = !raw || ( manager && manager.userId === raw.userId ) ? null : raw
        setManager( man )
    }

    return (
        <section className="kfc-report kfc-rating">
            <Layout>
                <Header>
                    <AppHeader hideRestaurants />
                </Header>
                <Content>
                    <InnerControls
                        hide={[ 'shift' ]}
                        actions={format.extract.actions( props )}
                        request={props.request}
                        update={load}
                        onChange={props.setRequest}
                        shiftDisabled
                    />
                    <RatingTabs
                        ratingTableGroup={ratingTableGroup}
                        changeRatingTableGroup={setRatingTableGroup}
                        view={view}
                        setView={handleTabChange}
                        loadTrigger={load}
                        ratingFilters={filters}
                        onSelect={handleSelect}
                        onFocus={handleFocus}
                        appendQuery={appendQuery}
                        setFilter={setFilter}
                        flushFilters={flushFilters}
                        showFlushFiltersButton={showFlushFiltersButton}
                        onCategory={onCategory}
                        onManger={onManger}
                        setSorter={setSorter}
                    />
                </Content>
            </Layout>
        </section>
    )
}

export default connect( mapStateToProps, allActions )( Rating )
