/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

/* APPLICATION */
import { AssignShift } from 'components'
import { format }      from 'tools'
import config          from 'config'

import ScheduleChartView from './ScheduleChartView'

import logic        from '../../logic'
import * as helpers from '../helpers'

const keys = {
    position: [ 'positionGuid', 'position' ],
    shift: [
        '_temp_id',
        'workScheduleId',
        'employeeName',
        'employeeId',
        'shiftStart',
        'shiftEnd',
        '_error',
        '_mc_error',
        '_outstaff_error',
        '_warning',
        'guid',
        'fixed',
        'confirmed',
        'shiftSupervisor',
        '_was_supervisor',
        '_underage_error',
        'isUnderage',
        'isUnderage2130',
        'isUnderage2200',
        'rateDictionary',
    ],
}

class ScheduleChart extends Component {
    static propTypes = {
        day: PropTypes.string,
        from: PropTypes.object,
        to: PropTypes.object,
        compact: PropTypes.bool,
        fixedHead: PropTypes.bool,

        date: PropTypes.object,
        data: PropTypes.object,
        open: PropTypes.object,

        workHours: PropTypes.object,
        positions: PropTypes.array,
        employees: PropTypes.array,

        onAdd: PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onError: PropTypes.func,
        onAssign: PropTypes.func,
        onTop: PropTypes.func,
        changeSelected: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            data: null,
            toAssign: null,
            assign: false,
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        this.updateData()
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { open, data, day } = this.props,
              { assign } = this.state

        if ( prevProps.day !== day ) {
            this.set.data( null, this.updateData )
            return
        }

        ( prevProps.data !== data || prevProps.open !== open ) && this.updateData()
        assign !== prevState.assign && ( window._schedule_popup = assign )
    }

    updateData = () => {
        setTimeout(() => this.set.data( this.data()), 0 )
    }

    empty = ( pos, str ) => ({
        as: 'label',
        employeeName: pos.positionName + str,
        positionGuid: pos.positionGuid,
        full: true,
        open: true,
    })

    spoiler = ( pos ) => {
        const count = pos.shifts.length,
              sp = this.empty( pos, format.strings.count( count, config.defs.shifts ))

        this.props.spoiler( 'details', sp )

        return sp
    }

    hour = ( row, date ) => {
        return ( index ) =>
            ( row[ 'hour-' + index ] =
        row.shiftStart && row.shiftEnd
            ? format.generate.greenLineExt(
                index,
                row.shiftStart,
                row.shiftEnd,
                row.fixed,
                date,
                row.lowRest,
                row.muchWork
            )
            : console.log( row ))
    }

    noMCDay = () => {
        const { data } = this.props,
              day = logic.datetime.find( data.days, this.props.day )

        if (
            data.dateStart !== dayjs().startOf( 'week' ).format( config.format.dayAPI )
        ) { return false }

        const tomorrow = parseInt( dayjs().add( 1, 'day' ).format( 'd' )),
              dow = day?.dayOfWeek === 7 ? 0 : day?.dayOfWeek

        if ( tomorrow === dow && tomorrow < 7 ) {
            const position = day.positions.filter(
                ( p ) => p.positionGuid === config.mcGuid
            )[ 0 ]

            if ( !position ) { return false }

            const supervisors = position.shifts.filter(( s ) => s.shiftSupervisor )

            return supervisors.length === 0
        }

        return false
    }

    isOutstaffMC = ( row, position ) => {
        if ( row.notAssignedToTheRestaurant ) {
            row._outstaff_error = true
        }

        if ( position.positionGuid !== config.mcGuid || !row.employeeId ) { return }

        const { employees } = this.props,
              found = employees.find(( e ) => e.id === row.employeeId )

        row._outstaff_error = !!found?.outstaff
    }

    isNoMC = ( row, position ) => {
        const { date } = this.props

        if ( position.positionGuid !== config.mcGuid ) { return }

        if (
            !date.startOf( 'day' ).isSame( dayjs().startOf( 'day' )) &&
      !date.startOf( 'day' ).isSame( dayjs().startOf( 'day' ).add( 1, 'day' ))
        ) { return }

        row._mc_error = position._mc_error
        if ( position._mc_error ) { return }

        const supervisors = position.shifts.filter(( s ) => s.shiftSupervisor )

        if ( supervisors.length === 0 ) {
            position._mc_error = true
            row._mc_error = true
        }
    }

    isUnderageOverwork = ( row ) => {
        if ( !row.isUnderage ) { return }

        const { date } = this.props,
              diff =
        Math.abs( dayjs( row.shiftEnd ).diff( dayjs( row.shiftStart ), 'minutes' )) /
        60,
              maxRates = {
                  0.25: 1.75,
                  0.5: 3.5,
                  0.75: 5.25,
                  1: 7,
              },
              maxHours = maxRates[ row.rateDictionary?.rate ] ?? Infinity,
              error =
        diff > maxHours
            ? `Смена для ставки ${
                row.rateDictionary?.rate
            } несовершеннолетнего не может превышать ${format.strings.readableTime(
                format.strings.mktime( maxHours )
            )} (${row.employeeName} ${date.format( config.format.onlyDayView )})`
            : false

        row._underage_error = error
    }

    isEmpty = ( row ) => {
        const check = [ 'employeeId' ]

        if ( format.check.anyEmpty( row, check )) {
            row._warning = true
        }
    }

    flush = ( position ) => {
        position._mc_error = false
        position.shifts.forEach(( shift ) => {
            shift._error = false
            shift._mc_error = false
        })
    }

    errors = () => ( position ) =>
        position.shifts.forEach(( shift ) => {
            this.isEmpty( shift )
            this.isNoMC( shift, position )
            this.isOutstaffMC( shift, position )
        })

    prepare = ( original ) => format.copy.object( original )

    shifts = ( res, pos, date, show ) => {
        return ( original ) => {
            const { from, to } = this.props,
                  row = {},
                  shift = this.prepare( original )

            row.lowRest = shift.lowRest
            row.muchWork = shift.muchWork
            row.isUnderage = shift.isUnderage
            row.rate = shift.rateDictionary?.rate

            format.extract.selection( shift, row, keys.shift )
            format.extract.selection( pos, row, keys.position )

            helpers.hours( this.hour( row, date ), from, to )

            row.__real_date = date

            row.rowClass = !show ? 'row-hidden' : ''

            if ( pos.positionGuid === '44081e01-c6d1-4e00-b51c-0a86f7ac69b6' ) {
                res.unshift( row )
            } else {
                res.push( row )
            }
        }
    }

    position = ( res, date ) => {
        return ( pos ) => {
            const { open, day } = this.props

            if ( pos.positionGuid === '44081e01-c6d1-4e00-b51c-0a86f7ac69b6' ) {
                pos.shifts
                    .sort( this.byTime )
                    .reverse()
                    .map(
                        this.shifts( res, pos, date, !open[ 'details' + day + pos.positionGuid ])
                    )

                res.unshift( this.spoiler( pos ))
            } else {
                res.push( this.spoiler( pos ))

                pos.shifts
                    .sort( this.byTime )
                    .map(
                        this.shifts( res, pos, date, !open[ 'details' + day + pos.positionGuid ])
                    )
            }

        }
    }

    byTime = ( a, b ) => dayjs( a.shiftStart ).unix() - dayjs( b.shiftStart ).unix()

    data = () => {
        if ( !this.props.data ) { return }

        const { day, data, date, onError, clearError, ignoreTomorrowMC } =
        this.props,
              current = logic.datetime.find( data.days, day ),
              res = []

        if ( current ) {
            current.positions.forEach( this.flush )
            current.positions.forEach( this.errors())
            current.positions.forEach(
                logic.shifts.errors(( row, shift ) => {
                    row._error = true
                    shift._error = true
                })
            )

            current.positions.forEach( this.position( res, date ))
        }

        if ( onError ) {
            if ( this.noMCDay() && !ignoreTomorrowMC ) {
                onError( 'tomorrow_mc_error' )
            }

            clearError()

            if ( res.map(( r ) => r._mc_error ).includes( true )) {
                onError( 'mc_error' )
            }

            if ( res.map(( r ) => r._outstaff_error ).includes( true )) {
                onError( 'outstaff_error' )
            }

            if ( res.map(( r ) => !!r._underage_error ).includes( true )) {
                onError(
                    'underage_error',
                    res.find(( r ) => !!r._underage_error )._underage_error
                )
            }

            onError( res.map(( row ) => row._error ).includes( true ))
        }

        res.forEach(( row ) => {
            const start = dayjs( row.shiftStart ),
                  end = dayjs( row.shiftEnd )

            if ( row.confirmed || start.isBefore( date ) || end.isBefore( dayjs())) {
                row.disabled = true
                row.disableRemove = true
            }
        })

        return res
    }

    assign = ( data ) => {
        const target = this.state.toAssign

        this.hidePopup()

        setTimeout(() => this.props.onAssign( data, target ), 100 )
    }

    showPopup = ( shift ) => {
        return ( e ) => {
            e.preventDefault()
            this.set.state({
                toAssign: shift,
                assign: true,
            })
        }
    }

    hidePopup = () =>
        this.set.state({
            toAssign: null,
            assign: false,
        })

    render () {
        const {
            from,
            to,
            compact,
            date,
            day,
            helpers,
            positions,
            employees,
            fixedHead,
            workHours,
            inlineHours,
        } = this.props,
              { data, assign, toAssign } = this.state

        return (
            <>
                <ScheduleChartView
                    fixedHead={fixedHead}
                    inlineHours={inlineHours}
                    loading={!data}
                    from={from}
                    to={to}
                    compact={compact}
                    date={date}
                    day={day}
                    data={data}
                    workHours={workHours}
                    helpers={helpers}
                    positions={positions}
                    employees={employees}
                    changeSelected={this.props.changeSelected}
                    onAdd={this.props.onAdd}
                    onAssign={this.showPopup}
                    onChange={this.props.onChange}
                    onRemove={this.props.onRemove}
                    onTop={this.props.onTop}
                    onDone={this.props.onDone}
                />

                {!compact && (
                    <AssignShift
                        visible={assign}
                        onSubmit={this.assign}
                        onCancel={this.hidePopup}
                        date={date}
                        shift={toAssign}
                    />
                )}
            </>
        )
    }
}

export default ScheduleChart
