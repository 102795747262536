import { addFiltersToRequest, getRequest }  from '../utils'
import { RatingDataQueries, RatingFilters } from './useLoadRatingData'
import { TableGroupType }                   from '../../Rating'

type LoadChartFilters = RatingFilters & {selected: number[]}
const STORAGE_KEY = 'ratingTableGroup'

export const useLoadChartData = ( queries: RatingDataQueries, filters: LoadChartFilters, view: string ) => {
    return () => {
        const ratingTableGroup = sessionStorage.getItem( STORAGE_KEY ) as TableGroupType || 'RESTAURANTS'

        const request = getRequest({ ...filters, ratingTableGroup }, queries.request ),
              req = { ...request }

        if ( sessionStorage.getItem( 'filtersData' )) {
            const filtersDataObj = JSON.parse( sessionStorage.getItem( 'filtersData' ) || '{}' ) as Record<string, string>
            req.dateStart = filtersDataObj?.start
            req.dateEnd = filtersDataObj?.end
            req.currentPredef = filtersDataObj?.predef
            req.dateGroup = filtersDataObj?.group
        }

        addFiltersToRequest( req, filters )
        if ( request.restaurantIds ) { req.factsNumbers = request.restaurantIds }

        delete req.restaurantIds
        delete req.restaurantId
        delete req.zoneId
        delete req.employeeId

        if ( !req.currentPredef && !req.dateStart && !req.dateEnd ) {
            return
        }

        switch ( view ) {
            case 'performance':
                queries.flushPerformanceChart()
                queries.fetchPerformancePlanAndFact( req )
                break
            case 'turnover':
                queries.flushTurnoverChart()
                queries.fetchTurnoverPlanAndFact( req )
                break
            default:
            // Do nothing
        }

    }
}
