/* VENDOR */
import React, { useState, useEffect } from 'react'
import dayjs, { OpUnitType }          from 'dayjs'
import { Form }                       from '@ant-design/compatible'
import type { WrappedFormUtils }      from '@ant-design/compatible/lib/form/Form'
import { Button, Modal }              from 'antd'

/* APPLICATION */
import { FormField, Link } from 'components'
import { format }          from 'tools'
import config              from 'config'

import './generate-schedule.scss'

const tmpFormat = config.format.date + ':ss'

interface FormFieldConfig {
  field: string
  label: string
  type: string
  open: boolean
  container: string
  popupClassName: string
  disabledDate: ( current: dayjs.Dayjs ) => boolean
  rules: any[]
  className: string
  onChange: ( type: string, date: dayjs.Dayjs ) => void
  value: dayjs.Dayjs
}

interface PropTypes {
  visible: boolean
  list: string[]
  onSubmit: ( values: any ) => void
  onCancel: () => void
  onShow: ( date: string ) => void
  form: WrappedFormUtils
}

const GenerateSchedule = ( props : PropTypes ) => {
    const { visible, list, onSubmit, onCancel, onShow, form } = props

    const [ generated, setGenerated ] = useState<boolean>( false )
    const [ date, setDate ] = useState<dayjs.Dayjs>( dayjs().startOf( 'week' as OpUnitType ).add( 1, 'week' ))
    const [ delayedVisible, setDelayedVisible ] = useState<boolean>( visible )

    useEffect(() => {
        setGenerated( isGenerated( date ))
    }, [])

    useEffect(() => {
        if ( list ) {
            setGenerated( isGenerated( date ))
        }
    }, [ list ])

    useEffect(() => {
        if ( visible !== delayedVisible ) {
            setTimeout(() => {
                setDelayedVisible( visible )
            }, 0 )
        }
    }, [ visible ])

    const fields = (): FormFieldConfig[] => [
        {
            field: 'week',
            label: '',
            type: 'week',
            open: visible,
            container: '.kfc-generate-schedule .ant-modal-body',
            popupClassName: 'date-picker-week-dropdown',
            disabledDate: ( current ) => current && current < dayjs().endOf( 'week' ),
            rules: [ config.rules.required ],
            className: 'week-field',
            onChange: setWeek,
            value: dayjs().add( 1, 'week' ),
        },
    ]

    const field = ( cfg: FormFieldConfig ) => {
        return (
            <FormField
                {...cfg}
                value={cfg.value}
                key={cfg.field}
                form={form}
                data={{}}
            />
        )
    }

    const preSubmit = () => {
        form.validateFieldsAndScroll(( err, values ) => {
            if ( format.check.form( err, values )) {
                onSubmit( values )
                form.resetFields()
            }
        })
    }

    const setWeek = ( type: string, date: dayjs.Dayjs ) => {
        setGenerated( isGenerated( date.startOf( 'week' )))
        setDate( date.startOf( 'week' ))
    }

    const isGenerated = ( date: dayjs.Dayjs ): boolean => {
        if ( !list ) { return false }

        return list.indexOf( date.startOf( 'week' ).format( tmpFormat )) > -1
    }

    return (
        <Modal
            title="Расписание"
            className="kfc-popup kfc-generate-schedule"
            centered={true}
            open={delayedVisible}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={onCancel}
            footer={
                generated ? (
                    <div className="extra-footer">
                        <p>Расписание на эту неделю уже сформировано</p>
                        <div className="extra-actions">
                            <div>
                                <Link onClick={() => onShow( date.format( tmpFormat ))}>Показать</Link>
                                <Link onClick={preSubmit}>Создать заново</Link>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="buttons-footer">
                        <Button
                            type="primary"
                            className="wide-btn"
                            onClick={preSubmit}
                        >
                            Сформировать
                        </Button>
                    </div>
                )
            }
        >

            <Form
                {...config.ui.wideForm}
                form={form}
            >
                {fields().map( field )}
            </Form>
        </Modal>
    )
}

export default Form.create({ name: 'GenerateSchedule' })( GenerateSchedule )
