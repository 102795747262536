// @ts-ignore
import ReactExport                                                              from 'react-data-export'
import { format }                                                               from 'tools'
import { DataGroupType, PostMetricsSearchResponse, PostMetricsSummaryResponse } from 'services/guestVote/statistic/type'
import { dropTypesFilter, dropTypesKeys }                                       from '../../GuestVoteTable/const'
import { getStatisticsTableColumns }                                            from '../../tabs/StatisticsTab/const'
import config                                                                   from 'config'
import React                                                                    from 'react'
import { PostFeedbacksSearchResponse }                                          from 'services/guestVote/consolidatedStatistics/type'
import { getConsolidatedStatisticsExcelColumns }                                from '../../tabs/ConsolidatedStatisticsTab/const'
import {
    PostMetricsSaleChannelsSearchResponse,
    PostMetricsSaleChannelsSummaryResponse
} from '../../../../services/guestVote/osat/type'
import { normalizeDataForOsatTable, NormalizedOsatTableData } from '../../tabs/helpers/helpers'
import { getOsatExcelColumns }                                from '../../tabs/OsatTab/const'

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const red = '00FF0000'
const green = '00038E1D'
const black = '00000000'

export const prepareConsolidatedStatisticsExcel = ( feedbacksSearch: PostFeedbacksSearchResponse, dataGroup: DataGroupType ) => {
    const columns = getConsolidatedStatisticsExcelColumns( dataGroup )

    const data = feedbacksSearch.content.map(( item ) => {
        const row = [
            { value: item.id === null ? config.ui.nullSymbol : item.id },
            { value: item.createDate === null ? config.ui.nullSymbol : item.createDate },
            { value: item.feedbackSource === null ? config.ui.nullSymbol : item.feedbackSource },
            { value: item.restaurantName === null ? config.ui.nullSymbol : item.restaurantName },
            { value: item[ dropTypesKeys[ dataGroup ] ] === null ? config.ui.nullSymbol : item[ dropTypesKeys[ dataGroup ] ] },
            { value: item.feedbackStatus === null ? config.ui.nullSymbol : item.feedbackStatus },
            { value: item.feedbackExecutor === null ? config.ui.nullSymbol : item.feedbackExecutor },
            { value: item.headOfEmployee === null ? config.ui.nullSymbol : item.headOfEmployee },
            { value: item.feedbackFullText === null ? config.ui.nullSymbol : item.feedbackFullText },
            { value: item.solutionRestaurant === null ? config.ui.nullSymbol : item.solutionRestaurant },
            { value: item.solutionGuest === null ? config.ui.nullSymbol : item.solutionGuest },
            { value: item.feedbackCategory === null ? config.ui.nullSymbol : item.feedbackCategory },
            { value: item.subcategory === null ? config.ui.nullSymbol : item.subcategory },
            { value: item.feedbackType === null ? config.ui.nullSymbol : item.feedbackType },
            { value: item.influenceLevel === null ? config.ui.nullSymbol : item.influenceLevel },
            { value: item.saleChannelName === null ? config.ui.nullSymbol : item.saleChannelName },
            { value: item.deliveryProvider === null ? config.ui.nullSymbol : item.deliveryProvider },
            { value: item.orderUuid === null ? config.ui.nullSymbol : item.orderUuid },
            { value: item.rating === null ? config.ui.nullSymbol : item.rating },
        ]

        if ( dataGroup === 'RESTAURANTS' ) {
            row.splice( 3, 0, { value: item.factsNumber === null ? config.ui.nullSymbol : item.factsNumber })
        }

        return row
    })

    const result = [
        {
            columns: columns,
            data: data,
        }
    ]

    return <ExcelSheet dataSet={result} name='consolidated statistics'/>
}

export const summaryTableData = ( happinessIndexesSummary: PostMetricsSummaryResponse, happinessIndexesSearch: PostMetricsSearchResponse, currentTypesFilter: DataGroupType ) => {
    const newHappinessIndexSummary = format.copy.object( happinessIndexesSummary )
    const newHappinessIndexesSearch = format.copy.object( happinessIndexesSearch ) as PostMetricsSearchResponse

    newHappinessIndexSummary._alwaysOnTop = true
    newHappinessIndexSummary.name = `Все ${dropTypesFilter[ currentTypesFilter ]}`
    newHappinessIndexesSearch.content = [ newHappinessIndexSummary, ...newHappinessIndexesSearch.content ]

    return newHappinessIndexesSearch
}

export const prepareHappinessIndexesExcel = ( happinessIndexesSummary: PostMetricsSummaryResponse, happinessIndexesSearch: PostMetricsSearchResponse, currentTypesFilter: DataGroupType ) => {
    const newHappinessIndex = summaryTableData( happinessIndexesSummary, happinessIndexesSearch, currentTypesFilter )
    const columns = getStatisticsTableColumns( currentTypesFilter ).map(( item ) => ({
        ...item,
        width: { wpx: item.width ?? 200 }
    }))
    if ( currentTypesFilter === 'RESTAURANTS' ) {
        columns.unshift({
            title: 'Код',
            dataIndex: 'id',
            key: 'id',
            width: { wpx: 100 },
            sorter: ( a: { factHappinessIndex: number; }, b: {
                factHappinessIndex: number;
            }) => a.factHappinessIndex - b.factHappinessIndex
        })
    }

    const data = newHappinessIndex.content.map(( item ) => {
        const row: {value?: number | string, style?: Record<string, any>}[] = [
            { value: item.name === null ? config.ui.nullSymbol : item.name },
            { value: item.factOsat === null ? config.ui.nullSymbol : `${item.factOsat}%` },
            { value: item.factOsatLastYear === null ? config.ui.nullSymbol : `${item.factOsatLastYear}%` },
            {
                value: item.deltaFactOsatLastYear === null ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear}%`,
                style: { font: { color: { rgb: ( item.deltaFactOsatLastYear || 0 ) < 0 ? red : ( item.deltaFactOsatLastYear || 0 ) > 0 ? green : black } } }
            },

            { value: item.factFeedbacksRating === null ? config.ui.nullSymbol : item.factFeedbacksRating },
            { value: item.factFeedbacksRatingLastYear === null ? config.ui.nullSymbol : item.factFeedbacksRatingLastYear },
            { value: item.deltaFactFeedbacksRatingLastYear === null ? config.ui.nullSymbol : item.deltaFactFeedbacksRatingLastYear },
            { value: item.factHappinessIndex === null ? config.ui.nullSymbol : item.factHappinessIndex },
            { value: item.factHappinessIndexLastYear === null ? config.ui.nullSymbol : item.factHappinessIndexLastYear },
            {
                value: item.deltaFactHappinessIndexLastYear === null ? config.ui.nullSymbol : item.deltaFactHappinessIndexLastYear,
                style: { font: { color: { rgb: ( item.deltaFactHappinessIndexLastYear || 0 ) < 0 ? red : ( item.deltaFactHappinessIndexLastYear || 0 ) > 0 ? green : black } } }
            },
            { value: item.factFeedbacks === null ? config.ui.nullSymbol : item.factFeedbacks },
            { value: item.factTransactions === null ? config.ui.nullSymbol : item.factTransactions },
            { value: item.factFeedbacksPer10KTransactions === null ? config.ui.nullSymbol : item.factFeedbacksPer10KTransactions },
        ]

        if ( currentTypesFilter === 'RESTAURANTS' ) {
            row.unshift({ value: item.id === null ? config.ui.nullSymbol : item.id })
        }

        return row
    })

    const result = [
        {
            columns: columns,
            data: data,
        }
    ]

    return <ExcelSheet dataSet={result} name='statistics'/>
}

export const summaryOsatTableData = ( metricsSaleChannelsSummary: PostMetricsSaleChannelsSummaryResponse | undefined, metricsSaleChannelsSearch: PostMetricsSaleChannelsSearchResponse, currentDataGroup: DataGroupType ) : NormalizedOsatTableData => {
    const newMetricsSummary = format.copy.object( metricsSaleChannelsSummary )
    const newMetricsSearch = format.copy.object( metricsSaleChannelsSearch ) as PostMetricsSaleChannelsSearchResponse

    if ( newMetricsSearch.content.length === 0 ) {
        return newMetricsSearch
    }

    newMetricsSummary._alwaysOnTop = true
    newMetricsSummary.name = `Все ${dropTypesFilter[ currentDataGroup ]}`
    newMetricsSearch.content = [ newMetricsSummary, ...newMetricsSearch.content ]

    return normalizeDataForOsatTable( newMetricsSearch ) as NormalizedOsatTableData
}

export const prepareOsatExcel = (
    metricsSaleChannelsSummary: PostMetricsSaleChannelsSummaryResponse | undefined,
    metricsSaleChannelsSearch: PostMetricsSaleChannelsSearchResponse,
    currentDataGroup: DataGroupType ) => {
    const data = summaryOsatTableData( metricsSaleChannelsSummary, metricsSaleChannelsSearch, currentDataGroup )
    const columns = getOsatExcelColumns( currentDataGroup )

    const excelData = data.content.map( item => {
        const row: {value?: number | string, style?: Record<string, any>}[] = [
            { value: item.name === null || item.name === undefined ? config.ui.nullSymbol : item.name },
            { value: item.factOsatCcKiosk === null || item.factOsatCcKiosk === undefined ? config.ui.nullSymbol : `${item.factOsatCcKiosk}%` },
            { value: item.factOsatCcKioskLastYear === null || item.factOsatCcKioskLastYear === undefined ? config.ui.nullSymbol : `${item.factOsatCcKioskLastYear}%` },
            {
                value: item.deltaFactOsatCcKioskLastYear === null || item.deltaFactOsatCcKioskLastYear === undefined ? config.ui.nullSymbol : `${item.deltaFactOsatCcKioskLastYear}%`,
                style: { font: { color: { rgb: ( item.deltaFactOsatCcKioskLastYear || 0 ) < 0 ? red : ( item.deltaFactOsatCcKioskLastYear || 0 ) > 0 ? green : black } } }

            },
            { value: item.factOsat1 === null || item.factOsat1 === undefined ? config.ui.nullSymbol : `${item.factOsat1}%` },
            { value: item.factOsatLastYear1 === null || item.factOsatLastYear1 === undefined ? config.ui.nullSymbol : `${item.factOsatLastYear1}%` },
            {
                value: item.deltaFactOsatLastYear1 === null || item.deltaFactOsatLastYear1 === undefined ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear1}%`,
                style: { font: { color: { rgb: ( item.deltaFactOsatLastYear1 || 0 ) < 0 ? red : ( item.deltaFactOsatLastYear1 || 0 ) > 0 ? green : black } } }

            },
            { value: item.factOsat5 === null || item.factOsat5 === undefined ? config.ui.nullSymbol : `${item.factOsat5}%` },
            { value: item.factOsatLastYear5 === null || item.factOsatLastYear5 === undefined ? config.ui.nullSymbol : `${item.factOsatLastYear5}%` },
            {
                value: item.deltaFactOsatLastYear5 === null || item.deltaFactOsatLastYear5 === undefined ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear5}%`,
                style: { font: { color: { rgb: ( item.deltaFactOsatLastYear5 || 0 ) < 0 ? red : ( item.deltaFactOsatLastYear5 || 0 ) > 0 ? green : black } } }

            },
            { value: item.factOsat2 === null || item.factOsat2 === undefined ? config.ui.nullSymbol : `${item.factOsat2}%` },
            { value: item.factOsatLastYear2 === null || item.factOsatLastYear2 === undefined ? config.ui.nullSymbol : `${item.factOsatLastYear2}%` },
            {
                value: item.deltaFactOsatLastYear2 === null || item.deltaFactOsatLastYear2 === undefined ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear2}%`,
                style: { font: { color: { rgb: ( item.deltaFactOsatLastYear2 || 0 ) < 0 ? red : ( item.deltaFactOsatLastYear2 || 0 ) > 0 ? green : black } } }

            },
        ]

        if ( currentDataGroup === 'RESTAURANTS' ) {
            row.unshift({ value: item.id === null ? config.ui.nullSymbol : item.id })
        }

        return row
    })

    const result = [
        {
            columns: columns,
            data: excelData,
        }
    ]

    return <ExcelSheet dataSet={result} name='osat'/>
}
