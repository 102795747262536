/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'restaurantsRating' ),
      appendHelper = format.helper.append( 'restaurantsRating' )

/* FLUSH */

export const flushRestaurantsRating = update(
    'restrating.flush',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {},
    })
)

export const flushTurnoverRating = update(
    'restrating.flush.tu',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            turnoverFact: null,
            turnoverPlan: null,
            allTurnover: null,
            turnover: null,
            turnoverPlanAndFact: null,
        },
    })
)

export const flushTurnover = update(
    'restrating.flush.turnover',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            turnover: null
        },
    })
)

export const flushTurnoverChart = update(
    'restrating.flush.tu.chart',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            turnoverPlan: null,
            turnoverFact: null,
        },
    })
)

export const flushAverageRating = update(
    'restrating.flush.ac',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            allAverage: null,
            average: null,
        },
    })
)

export const flushAverage = update(
    'restrating.flush.average',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            average: null
        },
    })
)

export const flushTimeRating = update(
    'restrating.flush.time',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            allServiceTime: null,
            serviceTime: null,
        },
    })
)

export const flushPerformanceRating = update(
    'restrating.flush.perf',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            performancePlan: null,
            performanceFact: null,
            performance: null,
            performancePlanAndFact: null,
            allPerformance: null,
        },
    })
)

export const flushPerformance = update(
    'restrating.flush.performance',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            performance: null
        },
    })
)

export const flushPerformanceChart = update(
    'restrating.flush.perf.chart',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            performancePlan: null,
            performanceFact: null,
        },
    })
)

/* TURNOVER */

export const fetchTurnoverPlanAndFact = asyncUpdate(
    'restrating.tu.planAndFact',
    ( params ) => api.getTurnoverPlanAndFact( params ),
    ( event, state ) =>
        helper( 'turnoverPlanAndFact', event.data ? {
            plan: format.extract.graph( event.data.data, 'time', 'planTurnover' ),
            fact: format.extract.graph( event.data.data, 'time', 'factTurnover' ),
        } : event.data, state )
)

export const fetchAllTurnover = asyncUpdate(
    'restrating.turnover.all',
    ( params ) => api.getAllTurnover( params ),
    ( event, state ) => helper( 'allTurnover', event?.data, state )
)

export const fetchRatingTurnover = asyncUpdate(
    'restrating.turnover',
    ( params ) => api.getTurnover( params ),
    ( event, state ) => helper( 'turnover', event?.data, state )
)

export const appendRatingTurnover = asyncUpdate(
    'restrating.turnover.append',
    ( params ) => api.getTurnover( params ),
    ( event, state ) => appendHelper( 'turnover', event, state )
)

/* AVERAGE CHECK */

export const fetchAllAverageCheck = asyncUpdate(
    'restrating.average.all',
    ( params ) => api.getAllAverageCheck( params ),
    ( event, state ) => helper( 'allAverage', event.data, state )
)

export const fetchRatingAverageCheck = asyncUpdate(
    'restrating.average',
    ( params ) => api.getAverageCheck( params ),
    ( event, state ) => helper( 'average', event.data, state )
)

export const appendRatingAverageCheck = asyncUpdate(
    'restrating.average.append',
    ( params ) => api.getAverageCheck( params ),
    ( event, state ) => appendHelper( 'average', event, state )
)

/* SERVICE TIME */

export const fetchAllServiceTime = asyncUpdate(
    'restrating.time.all',
    ( params ) => api.getAllServiceTime( params ),
    ( event, state ) => helper( 'allServiceTime', event.data, state )
)

export const fetchServiceTime = asyncUpdate(
    'restrating.time',
    ( params ) => api.getServiceTime( params ),
    ( event, state ) => {
        const data = format.copy.array( event.data )

        data.forEach(( r ) => ( r.rating = r.rating || { content: [], last: true }))

        return helper( 'serviceTime', data, state )
    }
)

export const appendServiceTime = asyncUpdate(
    'restrating.time.append',
    ( params ) => api.getServiceTime( params ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        const loaded = format.copy.array( state.restaurantsRating.serviceTime )

        event.data.forEach(( item ) => {
            const key = item.restaurantType,
                  toUpdate = loaded.findIndex(( search ) => search.restaurantType === key )

            loaded[ toUpdate ].rating = {
                ...item.rating,
                content: [ ...loaded[ toUpdate ].rating.content, ...item.rating.content ],
            }
        })

        return {
            ...state,
            restaurantsRating: {
                ...state.restaurantsRating,
                serviceTime: loaded,
            },
        }
    }
)

/* PERFORMANCE */

export const fetchPerformancePlanAndFact = asyncUpdate(
    'restrating.perf.planAndFact',
    ( params ) => api.getPerformancePlanAndFact( params ),
    ( event, state ) =>
        helper(
            'performancePlanAndFact', event.data ? {
                plan: format.extract.graph( event.data.data, 'time', 'planSpmh' ),
                fact: format.extract.graph( event.data.data, 'time', 'factSpmh' ),
            } : event.data, state )
)

export const fetchRatingPerformance = asyncUpdate(
    'restrating.performance',
    ( body ) => api.searchPerformance( body ),
    ( event, state ) => helper( 'performance', event?.data, state )
)

export const appendRatingPerformance = asyncUpdate(
    'restrating.performance.append',
    ( body ) => api.searchPerformancePagination( body ),
    ( event, state ) => appendHelper( 'performance', event, state )
)

export const fetchSummaryPerformance = asyncUpdate(
    'restrating.performance.all',
    ( body ) => api.getSummaryPerformance( body ),
    ( event, state ) => helper( 'allPerformance', event?.data, state )
)

/* CATEGORIES */

export const flushCategoriesRating = update(
    'restrating.flush.categories',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            categories: null,
        },
    })
)

export const flushCategoryManagers = update(
    'restrating.flush.managers',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            managers: null,
        },
    })
)

export const flushManagerRestaurants = update(
    'restrating.flush.restaurants',
    ( value, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            restaurants: null,
        },
    })
)

export const flushShouldUpdate = update(
    'restrating.flush.should',
    ( n, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            shouldUpdate: false,
        },
    })
)

export const fetchFilters = asyncUpdate(
    'restrating.categories.filters',
    ( body ) => api.getPartners( body ),
    ( event, state ) => helper( 'filters', event.data, state )
)

export const fetchCategoriesRating = asyncUpdate(
    'restrating.categories.list',
    ( params ) => api.getCategoriesRating( params ),
    ( event, state ) => helper( 'categories', event.data, state )
)

export const fetchCategoryManagers = asyncUpdate(
    'restrating.categories.managers',
    ( params ) => api.getCategoryManagers( params ),
    ( event, state ) => helper( 'managers', event.data, state )
)

export const fetchManagerRestaurants = asyncUpdate(
    'restrating.categories.restaurants',
    ( params ) => api.getManagerRestaurants( params ),
    ( event, state ) => helper( 'restaurants', event.data, state )
)

export const updateCategoryRating = asyncUpdate(
    'restrating.categories.update',
    ( body ) => api.updateCategoryRating( body ),
    ( event, state ) => ({
        ...state,
        restaurantsRating: {
            ...state.restaurantsRating,
            shouldUpdate: true,
        },
    })
)

/* OVERALL */

export const flushOverall = update( 'restrating.overall.flush', ( v, state ) => ({
    ...state,
    restaurantsRating: {
        ...state.restaurantsRating,
        overall: null,
    },
}))

export const fetchOverall = asyncUpdate(
    'restrating.overall.fetch',
    ( params ) => api.getOverall( params ),
    ( event, state ) => helper( 'overall', event.data, state )
)

export const updateOverall = asyncUpdate(
    'restrating.overall.update',
    ( body ) => api.updateOverall( body ),
    ( event, state ) => {
        return state
    }
)
