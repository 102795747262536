import { Card, Tabs }                                  from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect }                                     from 'react-redux'
import { allActions, mapStateToProps }                 from './connector'
import {
    ManningChart,
    ManningTable,
    Positions,
    RestaurantChartFilter
} from 'components'
import { format } from 'tools'
import './chart-editor.scss'

const ChartEditor = ( props ) => {
    const [ tab, setTab ] = useState( 'positions' )
    const [ filters, setFilters ] = useState( null )
    const [ loadingTransactions, setLoadingTransactions ] = useState( false )
    const timeoutRef = useRef( null )
    const isError = props.facilityTypesError || props.typeError || props.chartByTypeError
    const positions = isError ? [] : props.positionsChartedByType
    const [ chart, setChart ] = useState([])

    useEffect(() => {
        setChart( isError ? [] : props.chartByType )
    }, [ isError, props.chartByType ])

    useEffect(() => {
        if ( filters !== null ) {
            updateTab()
        }
    }, [ filters, tab ])

    useEffect(() => {
        if ( props.positionsChartedByType && props.positionsChartedByType.length > 0 ) {
            const hasError = props.positionsChartedByType.some( item => item.error )
            if ( hasError ) {
                updateTab()
            }
        }
    }, [ props.positionsChartedByType ])

    const updateTab = () => {
        if ( filters === null ) { return }
        if ( tab === 'positions' ) {
            props.flushPositionsChartedByType( filters )
            props.fetchPositionsChartedByType( filters )
        }
        if ( tab === 'table' || tab === 'chart' ) {
            props.fetchPositionsChartedByType( filters )
            props.flushChartByType()
            props.fetchChartByType( filters )
                .finally(() => setLoadingTransactions( false ))
        }
    }

    const updatePosition = ( record, key, val ) => {
        if ( val ) {
            props.createPositionsChartedByType({
                ...filters,
                positionUuid: record.positionUuid
            })
        } else {
            props.deletePositionChartedByType({ uuidChartPosition: record.uuidChartPosition })
        }
    }

    const addTransaction = () => {
        const max = ( props.chartByType && props.chartByType?.length > 0 ) ?
            Math.max( ...props.chartByType.map(( r ) => r.transactions ))
            :
            0
        setLoadingTransactions( true )
        props.createTransactionChartByType({
            ...filters,
            transactions: max + 1
        }).finally(() => {
            setLoadingTransactions( false )
            const rows = document.querySelectorAll( '.staff-manning-table tbody tr .ant-input-number-input' )
            rows[ rows.length - 1 ]?.focus()
        })
    }

    const removeTransaction = ( record ) => {
        clearTimeout( timeoutRef.current )
        setLoadingTransactions( true )
        props.deleteChartByType({ plannedTransactionNumberUuid: record.plannedTransactionNumberUuid }).finally(() => setLoadingTransactions( false ))
    }

    const changeTransaction = ( record, key, val ) => {
        timeoutRef.current = setTimeout(() => {
            if ( key === 'transactions' ) {

                if ( chart.find(( row ) => row.transactions === val && row.plannedTransactionNumberUuid !== record.plannedTransactionNumberUuid )) {
                    setTimeout(() => document.getElementById( 'focused' ).focus(), 300 )
                    setChart( chart.map( item => item.plannedTransactionNumberUuid === record.plannedTransactionNumberUuid ?
                            ({ ...item, transactions: val, double: !!chart.filter( el => el.transactions === item.transactions ).length }) : { ...item, disabled: true }))
                } else {
                    setChart( chart.map( item => item.plannedTransactionNumberUuid === record.plannedTransactionNumberUuid ?
                            ({ ...item, transactions: val, double: false }) : { ...item, disabled: false }))
                    props.updatePlannedTransaction({
                        ...filters,
                        transactions: val,
                        plannedTransactionNumberUuid: record.plannedTransactionNumberUuid
                    }).finally(() => props.fetchChartByType( filters ))
                }
            } else {
                const found = format.find.byKey( record.positions, 'positionUuid', key )
                if ( found ) {
                //update
                    props.updatePositionChartByType({
                        positionTransactionNumberUuid: found.positionTransactionNumberUuid,
                        plannedTransactionNumberUuid: record.plannedTransactionNumberUuid,
                        positionCount: val,
                        positionUuid: key
                    })
                } else {
                //create
                    props.createPositionChartByType({
                        plannedTransactionNumberUuid: record.plannedTransactionNumberUuid,
                        positionCount: val,
                        positionUuid: key
                    })
                }
            }
        }, 100 )
    }

    const generateChart = useMemo(() => {
        if ( isError ) { return [] }
        if ( !props.chartByType ) { return null }
        return props.chartByType
            .map(( item ) => ({
                x: item.total,
                y: item.tcph,
                label: format.strings.thousand( item.tcph, '' ),
                yOffset: -10,
            }))
            .sort(( a, b ) => b.x - a.x )
    }, [ props.chartByType, isError ])

    const tabs = [
        {
            key: 'positions',
            label: 'Позиции',
            children: <Positions
                data={positions}
                onChange={updatePosition}
                editable
            />
        },
        {
            key: 'table',
            label: 'Таблица',
            children: <ManningTable
                data={chart}
                positions={positions}
                editable
                onAdd={addTransaction}
                loading={loadingTransactions}
                onChange={changeTransaction}
                onRemove={removeTransaction}
            />
        },
        {
            key: 'chart',
            label: 'График',
            children: <ManningChart
                data={generateChart}
                onAdd={() => setTab( 'table' )}
                isError={props.chartByTypeError}
            />
        }
    ]


    return (
        <Card bordered={false} className='chart-editor'>
            <RestaurantChartFilter
                onChangeFilter={setFilters}
            />
            <Tabs
                animated={false}
                onChange={( key ) => setTab( key )}
                activeKey={tab}
                items={tabs}
            ></Tabs>
        </Card>
    )
}

export default connect( mapStateToProps, allActions )( ChartEditor )
