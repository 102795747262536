import React              from 'react'
import dayjs              from 'dayjs'
import { ViewTypeSwitch } from 'components'
import config             from 'config'

interface PropTypes {
  visible: boolean;
  format?: string;
  start: dayjs.Dayjs | null;
  current?: string;
  titleRender?: ( str: dayjs.Dayjs | null, offset: number ) => React.ReactNode;
  onChange: ( day: number ) => void;
  onScroll: () => void
}

interface Result {
  [key: string]: React.ReactNode;
}

const Days = ( props: PropTypes ) => {
    const { visible, format, start, current, titleRender, onChange } = props

    const days = (): Result => {
        const res: Result = {}

        if ( !visible ) {
            return res
        }

        [ 1, 2, 3, 4, 5, 6, 7 ].forEach(( day ) => {
            const key = day.toString()
            const title = prepareTitle( start, day )
            res[ key ] = title
        })

        return res
    }
    const prepareTitle = ( str: dayjs.Dayjs | null, offset: number ): React.ReactNode => {
        if ( titleRender ) {
            return titleRender( str, offset )
        }

        const frmt = format || config.format.onlyDayView
        const startDate = dayjs( str, config.format.dayAPI )
        const dow = dayjs().day( offset ).format( 'ddd' )
        const date = startDate.add( offset - 1, 'days' ).format( frmt )
        const isToday = dayjs( start )
            .startOf( 'day' )
            .add( offset - 1, 'days' )
            .isSame( dayjs().startOf( 'day' ))
        const otext = date + ', ' + dow.toUpperCase()
        const text = isToday ? <b>{otext}</b> : otext

        return text
    }

    const onUpdate = ( day: number ) => setTimeout(() => onChange( day ), 0 )

    if ( !visible ) {
        return null
    }

    return (
        <ViewTypeSwitch
            className="capitalize wide"
            tabs={days()}
            current={current ? current.toString() : '1'}
            update={onUpdate}
        />
    )
}

export default Days
