/* eslint-disable camelcase */
/* VENDOR */
import React, { useEffect, useState } from 'react'
import dayjs                          from 'dayjs'
import { Button }                     from 'antd'
import type { Dayjs }                 from 'dayjs'

/* APPLICATION */
import {
    AddButton,
    ViewTypeDrop,
    ViewTypeSwitch,
    GenerateSchedule,
    DiscardChanges,
    ScheduleTurnover,
} from 'components'
import config from 'config'

import './schedule-controls.scss'

interface PropTypes {
    list: string[]
    user: any
    turnover: number
    current: string
    popup: boolean
    changed: boolean
    compact: boolean
    onCompact: () => void
    onWeek: ( week: string ) => void
    isSummaryReport: boolean
    changeSummaryReport: () => void
    onGenerate: ( data: { date: string }) => void
    getTurnover: ( body: Record<string, any> ) => void
    setTurnover: ( data: any ) => void
    saveTurnover: boolean
    flushTurnover: () => void
    errorTurnover: boolean
    errorSetTurnover: boolean
    addServerError: ( error: any ) => void
}

interface Weeks {
    [key: string]: string
}

const ScheduleControls = ( props: PropTypes ) => {
    const basic = {
        current: 'На текущую неделю',
        next: 'На следующую неделю',
    }

    const { list, user, turnover, current, popup, changed, compact, onCompact, onWeek, isSummaryReport, changeSummaryReport, onGenerate, getTurnover, setTurnover, saveTurnover, flushTurnover, errorTurnover, errorSetTurnover, addServerError } = props

    const [ weeks, setWeeks ] = useState<Weeks>({})
    const [ change, setChange ] = useState<string | null>( null )
    const [ date, setDate ] = useState<string | null>( null )
    const [ visiblePopup, setVisiblePopup ] = useState<boolean>( false )
    const [ generate, setGenerate ] = useState<boolean>( false )
    const [ visibleTurnover, setVisibleTurnover ] = useState<boolean>( false )
    const [ loadTurnover, setLoadTurnover ] = useState<boolean>( false )


    useEffect(() => {
        init()
        onWeek( current )
    }, [])

    useEffect(() => {
        if ( errorTurnover || errorSetTurnover ) {
            setLoadTurnover( false )
        }
    }, [ errorTurnover, errorSetTurnover ])

    useEffect(() => {
        // @ts-ignore
        window._schedule_popup = generate
    }, [ generate ])

    useEffect(() => {
        if ( saveTurnover ) {
            hidePopup()
            preGenerate({ week: date })
        }
    }, [ saveTurnover ])

    useEffect(() => {
        init()
    }, [ list ])

    useEffect(() => {
        if ( popup ) {
            setGenerate( true )
        }
    }, [ popup ])

    const init = () => setWeeks( prepareWeeks())

    const prepareWeeks = (): Weeks => {
        const res: Weeks = {}

        if ( list ) {
            list.filter(( d ) =>
                dayjs( d, config.format.date ).isAfter(
                    dayjs().startOf( 'week' ).add( 1, 'week' )
                )
            )
                .sort(
                    ( a, b ) =>
                        dayjs( a, config.format.date ).valueOf() -
                        dayjs( b, config.format.date ).valueOf()
                )
                .forEach(( start ) => ( res[ start.split( 'T' )[ 0 ] ] = prepareWeek( start )))
        }
        return res
    }

    const prepareWeek = ( str: string ): string => {
        const format = config.format.onlyDayView,
              date = dayjs( str, config.format.date ),
              start = dayjs( date.startOf( 'week' )),
              end = dayjs( date.endOf( 'week' )),
              dates = [ '–', end.format( format ), end.format( 'YYYY' ) ]

        dates.unshift(
            start.isSame( end, 'month' ) ? start.format( 'D' ) : start.format( format )
        )
        return dates.join( ' ' )
    }

    const onChange = ( data: string ) => {
        changed ? showPopup( data ) : onWeek( data )
    }

    const showPopup = ( data: string ) => {
        setChange( data )
        setVisiblePopup( true )
    }

    const hidePopup = () => {

        setChange( null )
        setVisiblePopup( false )
        setGenerate( false )
        setVisibleTurnover( false )
        setDate( null )
        setLoadTurnover( false )

        flushTurnover()
    }

    const callback = ( cb: ( data: string ) => void ) => {
        return ( data: string ) => {
            cb( data )
            hidePopup()
        }
    }

    const preGenerate = ( date: { week: string | null }) => {
        if ( !date.week ) { return }
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ) || '{}' )

        datesInStorage.start = dayjs( date.week ).startOf( 'week' ).format( config.format.dateFull )
        datesInStorage.end = dayjs( date.week ).endOf( 'week' ).format( config.format.dateFull )

        sessionStorage.setItem( 'scheduleData', JSON.stringify( datesInStorage ))

        onGenerate({ date: date.week })
        hidePopup()
    }

    const showTurnover = ( date: string ) => {
        setGenerate( false )
        setVisibleTurnover( true )
        setDate( date )
    }

    const submitTurnover = ( data: number ) => {
        setLoadTurnover( true )
        setTurnover( data )
    }

    const haveNext = () => {
        if ( !list || list.length < 1 ) {
            return false
        }
        return Object.keys( weeks ).length > 0
    }

    return (
        <div className="schedule-controls view-type-switch">
            <ViewTypeSwitch
                className="left"
                tabs={basic}
                current={current}
                update={onChange}
            />

            {haveNext() && (
                <ViewTypeDrop
                    items={weeks}
                    current={current}
                    update={onChange}
                />
            )}

            <div className="generator-container">
                <AddButton text="Сформировать..." action={() => setGenerate( true )}/>
            </div>

            <div className="generator-container">
                <Button
                    type="ghost"
                    className="compact-button"
                    onClick={changeSummaryReport}
                >
                    {isSummaryReport ? 'Вернуться к обычному расписанию' : 'Сводное расписание'}
                </Button>
            </div>

            <Button
                type="primary"
                className="compact-button"
                onClick={onCompact}
            >
                {compact ? 'Полный вид' : 'Компактный вид'}
            </Button>

            <GenerateSchedule
            // @ts-ignore
                list={list}
                visible={generate}
                onSubmit={preGenerate}
                onShow={callback( onChange )}
                onCancel={hidePopup}
                editPlan={showTurnover}
                jobRole={user.jobRole}
            />

            <ScheduleTurnover
            // @ts-ignore
                visible={visibleTurnover}
                loading={loadTurnover}
                // @ts-ignore
                date={date}
                // @ts-ignore
                turnover={turnover}
                error={errorTurnover}
                getTurnover={getTurnover}
                onSubmit={submitTurnover}
                onCancel={hidePopup}
                addServerError={addServerError}
            />

            <DiscardChanges
                title="Вы действительно хотите перейти на другую неделю?"
                ok="Перейти"
                visible={visiblePopup}
                onSubmit={callback(() => change && onWeek( change ))}
                onCancel={hidePopup}
            />
        </div>
    )
}

export default ScheduleControls
