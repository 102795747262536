/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    ViewTypeSwitch,
    PositionsOld,
    TimeSettings,
    RestaurantShifts,
    Breaks,
    Duration,
    ManningOld,
    PprRestaurant,
    Positions,
    Manning,
    ShowData
} from 'components'

import { settingsActions, chartActions, staffActions } from 'services'
import { format }                                      from 'tools'
import config                                          from 'config'

import './settings.scss'

const { Content, Header } = Layout

class Settings extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            view: 'time',
            workHours: config.defs.workHours,
            loading: true,
            sendingPpr: false
        }
    }

    componentDidMount () {
        this.load( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { settings, request } = this.props,
              prev = settings,
              now = nextProps.settings

        if ( now.workHours !== prev.workHours ) {
            this.setWorkHours( now.workHours.days )
        }

        if ( request.restaurantId !== nextProps.request.restaurantId ) {
            this.setState({ workHours: config.defs.workHours, loading: true })
            this.load( nextProps )
        }
        if ( now.positions !== prev.positions ) {
            this.props.fetchManning( this.props.request )
        }
    }

    load = props => {
        props.flushBreaks()
        props.flushDuration()
        props.flushManning()

        props.getRates({
            gender: 'MALE',
            isStudies: false,
            isUnderage: false,
            restaurantId: props.request.restaurantId
        })

        props.fetchWorkHours( props.request )
        props.fetchPositions( props.request )
        props.fetchBreaks( props.request )
        props.fetchDuration( props.request )
        props.fetchManning( props.request )

    }

    setWorkHours = ( wh ) =>
        this.setState({
            ...format.update.dow( wh, this.state, 'workHours' ),
            loading: false,
        })

    setView = ( view ) => this.setState({ view })

    updateDuration = ( body ) =>
        this.props.updateDuration({
            body: body,
            params: this.props.request,
        })

    removeDuration = ( data ) =>
        this.props.removeDuration({
            durationId: data.durationId,
            restaurantId: this.props.request.restaurantId,
        })

    updateBreaks = ( body ) =>
        this.props.updateBreaks({
            body: body,
            params: this.props.request,
        })

    removeBreaks = ( brk ) =>
        this.props.removeBreaks({
            rate: brk.rate,
            breakId: brk.breakId,
            restaurantId: this.props.request.restaurantId,
        })

    updateShift = ( shift, key, val, day ) => {
        const shifts = format.copy.array( day.shifts ),
              index = parseInt( shift.key.split( '_' )[ 1 ])

        shifts[ index ][ key ] = val
        key === 'timeEnd' && val === '00:00' && ( val = '23:45' )

        this.updateWH( day, 'shifts', shifts )
    }

    updateWH = ( record, key, val ) => {
        const nwh = format.copy.array( this.state.workHours ),
              rec = format.copy.object( record )

        key === 'timeEnd' && val === '00:00' && ( val = '23:45' )

        rec[ key ] = val
        rec.original = record

        nwh[ format.find.index( nwh, 'dayOfWeek', rec.dayOfWeek ) ] = rec

        if ( rec.noctidial ) {
            rec.timeStart = '00:00'
            rec.timeEnd = '23:45'
        }

        this.setWorkHours( nwh )
        this.props.saveWorkHours({
            data: rec,
            ...format.generate.params( this.props.request, [ 'restaurantId' ]),
        })
    }

    updatePosition = ( record, key, val ) => {
        const rec = {
            positionGuid: record.positionGuid,
            chart: val,
            original: record,
        }

        this.props.savePosition({
            data: rec,
            ...format.generate.params( this.props.request, [ 'restaurantId' ]),
        })
    }

    removeShift = ( shift, day, index ) => {
        const shifts = format.copy.array( day.shifts )
        shifts.splice( index, 1 )
        this.updateWH( day, 'shifts', shifts )
    }

    addShift = ( day ) => {
        const shifts = format.copy.array( day.shifts )

        switch ( shifts.length ) {
            case 0:
                shifts.push({
                    timeStart: day.timeStart,
                    timeEnd: day.timeEnd,
                    dayOfWeek: day.dayOfWeek,
                })
                break
            default:
                shifts.push({
                    timeStart: shifts[ shifts.length - 1 ].timeEnd,
                    timeEnd: day.timeEnd,
                    dayOfWeek: day.dayOfWeek,
                })
        }

        this.updateWH( day, 'shifts', shifts )
    }

    removeChart = ( record ) =>
        this.props.removeManning({
            turnoverChartId: record.turnoverChartId,
            restaurantId: this.props.request.restaurantId,
        })

    updateChart = ( body ) =>
        this.props.updateManning({
            body,
            params: this.props.request,
        })

    onCreatePpr = body => {
        this.setState({ sendingPpr: true }, () => {
            this.props.createPprRestaurant({
                body,
                restaurantId: this.props.request.restaurantId
            }).finally(() => this.setState({ sendingPpr: false }))
        })

    }

    onUpdatePpr = ( body, pprId ) => {
        this.setState({ sendingPpr: true }, () => {
            this.props.updatePprRestaurant({
                body,
                restaurantId: this.props.request.restaurantId,
                pprId
            }).finally(() => this.setState({ sendingPpr: false }))
        })
    }

    onChangeFilterPositions = ( params ) => {
        this.props.flushPositionsChartedByType()
        this.props.fetchPositionsChartedByType( params )
    }

    onChangeFilterChart = ( params ) => {
        this.props.flushChartByType()
        this.props.flushPositionsChartedByType()
        this.props.fetchChartByType( params )
        this.props.fetchPositionsChartedByType( params )
    }

    content = () => {
        const
            { view, workHours, loading, sendingPpr } = this.state,
            {
                settings, chart, rates, fetchSettingsPprRestaurant, request,
                manning, planning, facilityTypesError, typeError
            } = this.props

        switch ( view ) {
            case 'positions':
                return (
                    <PositionsOld
                        data = { settings.positionsError ? [] : settings.positions }
                        onChange = { this.updatePosition }
                        parent = { this }
                    />
                )
            case 'positionsNew': {
                return (
                    <Positions
                        data = {planning.positionsChartedByTypeError ? [] : planning.positionsChartedByType }
                        onChange = { this.updatePosition }
                        onChangeFilter={this.onChangeFilterPositions}
                        editable={false}
                        parent={this}
                    />
                )
            }
            case 'manning':
                return (
                    <ManningOld
                        parent = { this }
                        manning = { manning }
                        positions = { settings.positions }
                        onRemove = { this.removeChart }
                        onUpdate = { this.updateChart }
                    />
                )
            case 'manningNew': {
                return (
                    <Manning
                        parent = { this }
                        manning = { planning.chartByType }
                        positions = { planning.positionsChartedByType }
                        onChangeFilter={this.onChangeFilterChart}
                        onRemove = { this.removeChart }
                        onUpdate = { this.updateChart }
                        isError={facilityTypesError || typeError}
                    />

                )
            }

            case 'shifts':
                return (
                    <RestaurantShifts
                        data = { loading ? null : workHours }
                        onAdd = { this.addShift }
                        onChange = { this.updateShift }
                        onRemove = { this.removeShift }
                        onChangeView = {this.setView}
                    />
                )
            case 'duration':
                return (
                    <Duration
                        duration = { chart.duration }
                        update = { this.updateDuration }
                        remove = { this.removeDuration }
                    />
                )
            case 'breaks':
                return (
                    <Breaks
                        rates = { rates }
                        breaks = { chart.breaks }
                        update = { this.updateBreaks }
                        remove = { this.removeBreaks }
                    />
                )
            case 'ppr':
                return (
                    <PprRestaurant
                        ppr = { settings.ppr }
                        onCreate = { this.onCreatePpr }
                        onUpdate = { this.onUpdatePpr }

                        loading = { loading }
                        sendingPpr = { sendingPpr }
                        fetchPprRestaurant={ fetchSettingsPprRestaurant }
                        restaurantId={ request.restaurantId }
                    />
                )
            case 'showData':
                return (
                    <ShowData />
                )
            case 'time':
            default:
                return (
                    <TimeSettings
                        data = { loading ? null : workHours }
                        onChange = { this.updateWH }
                    />
                )
        }
    }

    render () {
        const { jobRole } = this.props
        const tabs = {
            _default: 'time',
            time: 'Режим работы',
            positions: 'Позиции',
            manning: 'Чарт',
            positionsNew: 'Позиции (Новый)',
            manningNew: 'Чарт (Новый)',
            shifts: 'Смены ресторана',
            duration: 'Смены сотрудников',
            breaks: 'Перерывы',
            showData: 'Отображение данных'
            //...( config.access.checkRole( 'ppr', jobRole ) && { ppr: 'Данные PPR' })
        }

        return (
            <section className="kfc-settings kfc-tabbed-page scroll-container">
                <Layout>
                    <Header>
                        <AppHeader hideTime />
                    </Header>
                    <Content>
                        <ViewTypeSwitch
                            update={this.setView}
                            tabs={tabs}
                            current={this.state.view}
                        />
                        <InnerContent>
                            <div className="tabbed-content">{this.content()}</div>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

const mapStateToProps = ( state ) => ({
    settings: state.settings,
    chart: state.chart,
    request: state.request,
    manning: state.chart.manning,
    rates: state.staff.rates,
    planning: state.planning,
    jobRole: state.user.info.jobRole,
    facilityTypesError: state.restaurants.facilityTypesError,
    typeError: state.restaurants.typeError
})

const allActions = {
    ...settingsActions,
    ...chartActions,
    ...staffActions
}

export default connect( mapStateToProps, allActions )( Settings )
