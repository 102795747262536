/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Modal }            from 'antd'

/* APPLICATION */
interface PropTypes {
    visible: boolean
    title: string
    ok: string
    onSubmit: ( data: string ) => void
    onCancel: () => void
}

const DiscardChanges = ( props: PropTypes ) => {
    const { visible, title, ok, onSubmit, onCancel } = props

    return (
        <Modal
            className="kfc-popup"
            centered={true}
            open={visible}
            title={title}
            okText={ok}
            okButtonProps={{ className: 'wide-btn' }}
            // @ts-ignore
            onOk={onSubmit}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={onCancel}
        >
    Все изменения потеряются.
        </Modal>
    )
}

export default DiscardChanges
