// @ts-ignore
import ReactExport                            from 'react-data-export/dist'
import { allRowName, averageName, groupName } from 'components/rating/GroupSwitch/const'
import React                                  from 'react'
import { format }                             from 'tools'
import { TableGroupType }                     from '../Rating'

// const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const getFormattedValue = ( value:string, postfix: string, digits: number | null = null ) => {
    if ( value !== null ) {
        return `${digits !== null ? ( +value ).toFixed( digits ) : value}${postfix === '%' ? postfix : ''}`
    }
    return '-'
}
const red = '00FF0000'
const green = '00038E1D'
const black = '00000000'

export const prepareExcelTurnover = ( turnover: any, ratingTableGroup: TableGroupType, currency: string, title: string ) => {
    if ( !turnover?.report?.content || turnover?.report?.content?.length === 0 ) {
        return []
    }

    const result = [ {
        columns: [
            ratingTableGroup === 'RESTAURANTS' && { title: 'Код' },
            { title: `${groupName[ ratingTableGroup ]}`, width: { wpx: 200 } },
            { title: '% выполнения ТО' },
            { title: '% выполнения ТО текущий' },
            { title: 'План ТО ТУ' },
            { title: 'План ТО Директора' },
            { title: 'Разница План ТО' },
            { title: 'ТО Директора текущий' },
            { title: 'Факт ТО' },
            { title: 'Разница Факт и План ТО ТУ' },
            { title: 'Разница Факт и План ТО Директора' },
            { title: 'Разница Факт и План ТО Директора текущий' },
        ].filter( Boolean ),
        data: [
            {
                name: allRowName[ ratingTableGroup ],
                ...turnover.all
            },
            ...turnover.report.content
        ].map(( item => ([
            ratingTableGroup === 'RESTAURANTS' && { value: item.id || '' },
            { value: item.name || '' },
            { value: getFormattedValue( item.percentage ?? 0, '%', 0 ) },
            { value: getFormattedValue( item.currentPercentage ?? 0, '%', 0 ) },
            { value: getFormattedValue( item.planTurnoverAreaCoach, currency, 0 ) },
            { value: getFormattedValue( item.planTurnoverRgm, currency, 0 ) },

            {
                value: getFormattedValue( item.deltaPlanTurnover, currency, 0 ),
                style: { font: { color: { rgb: item.deltaPlanTurnover < 0 ? green : ( item.deltaPlanTurnover > 0 ? red : black ) } } }
            },

            { value: getFormattedValue( item.planTurnoverRgmCurrent, currency, 0 ) },
            { value: getFormattedValue( item.factTurnover, currency, 0 ) },

            {
                value: getFormattedValue( item.deltaTurnoverAreaCoach, currency, 0 ),
                style: { font: { color: { rgb: item.deltaTurnoverAreaCoach < 0 ? red : ( item.deltaTurnoverAreaCoach > 0 ? green : black ) } } }
            },

            {
                value: getFormattedValue( item.deltaTurnoverRgm, currency, 0 ),
                style: { font: { color: { rgb: item.deltaTurnoverRgm < 0 ? red : ( item.deltaTurnoverRgm > 0 ? green : black ) } } }
            },

            {
                value: getFormattedValue( item.deltaTurnoverRgmCurrent, currency, 0 ),
                style: { font: { color: { rgb: item.deltaTurnoverRgmCurrent < 0 ? red : ( item.deltaTurnoverRgmCurrent > 0 ? green : black ) } } }
            },

        ].filter( Boolean ))
        )).filter(( row ) => row.filter( i => i && i?.value !== '0%' && i?.value !== '0' && i?.value !== '' ).length )
    } ]

    return <ExcelSheet dataSet={result} name={title}/>
}

export const prepareExcelAverage = ( average: any, ratingTableGroup: TableGroupType, currency: string, title: string ) => {
    if ( !average?.report?.content || average?.report?.content?.length === 0 || !average?.all ) {
        return []
    }

    const result = [ {
        columns: [
            ratingTableGroup === 'RESTAURANTS' && { title: 'Код' },
            { title: `${groupName[ ratingTableGroup ]}`, width: { wpx: 230 } },
            { title: 'Средний чек, руб' },
            { title: 'Цель, руб' },
            { title: 'Разница, руб' },
        ].filter( Boolean ),
        data: [
            {
                id: '',
                name: averageName[ ratingTableGroup ],
                factAverageCheck: average.all?.factAverageCheck,
                planAverageCheck: average.all?.planAverageCheck,
                deltaAverageCheck: average.all?.deltaAverageCheck,
            },
            ...average.report.content
        ].map(( item => ([
            ratingTableGroup === 'RESTAURANTS' && { value: item.id || '' },
            { value: item.name || '' },
            { value: getFormattedValue( item.factAverageCheck, currency, 0 ) },
            { value: getFormattedValue( item.planAverageCheck, currency, 0 ) },
            {
                value: getFormattedValue( item.deltaAverageCheck, currency, 0 ),
                style: { font: { color: { rgb: item.deltaAverageCheck < 0 ? red : ( item.deltaAverageCheck > 0 ? green : black ) } } }
            },
        ].filter( Boolean ))
        )).filter(( row ) => row.filter( i => i && i?.value !== '-' && i?.value !== '' ).length )
    } ]

    return <ExcelSheet dataSet={result} name={title}/>
}

export const prepareExcelPerformance = ( performance: any, ratingTableGroup: TableGroupType, currency: string, title: string ) => {
    if ( !performance?.report?.content || performance?.report?.content?.length === 0 ) {
        return []
    }

    const result = [ {
        columns: [
            ratingTableGroup === 'RESTAURANTS' && { title: 'Код' },
            { title: `${groupName[ ratingTableGroup ]}`, width: { wpx: 200 } },
            { title: 'План ТО ТУ' },
            { title: 'План ТО Директора' },
            { title: 'Разница План ТО' },
            { title: 'Факт ТО' },
            { title: 'Разница Факт и План ТО ТУ' },
            { title: 'Разница Факт и План ТО Директора' },
            { title: 'План ТРЗ' },
            { title: 'Факт ТРЗ' },
            { title: 'План СЧ' },
            { title: 'Факт СЧ' },
            { title: 'Цель SPMH ТУ' },
            { title: 'План SPMH Директора' },
            { title: 'Факт SPMH' },
            { title: 'План TCPH' },
            { title: 'Факт TCPH' },
            { title: 'Items Per Hour' },
            { title: 'Количество блюд' },
            { title: 'Запланировано часов' },
            { title: 'Отработано часов' },
        ].filter( Boolean ),
        data: [
            {
                name: allRowName[ ratingTableGroup ],
                ...performance.all
            },
            ...performance.report.content
        ].map(( item => ([
            ratingTableGroup === 'RESTAURANTS' && { value: item.id || '' },
            { value: item.name || '' },
            { value: getFormattedValue( item.planTurnoverAreaCoach ?? 0, currency, 0 ) },
            { value: getFormattedValue( item.planTurnoverRgm ?? 0, currency, 0 ) },

            {
                value: getFormattedValue( item.deltaPlanTurnover ?? 0, currency, 0 ),
                style: { font: { color: { rgb: item.deltaPlanTurnover < 0 ? green : ( item.deltaPlanTurnover > 0 ? red : black ) } } }
            },


            { value: getFormattedValue( item.factTurnover ?? 0, currency, 0 ) },

            {
                value: getFormattedValue( item.deltaTurnoverAreaCoach ?? 0, currency, 0 ),
                style: { font: { color: { rgb: item.deltaTurnoverAreaCoach < 0 ? red : ( item.deltaTurnoverAreaCoach > 0 ? green : black ) } } }
            },

            {
                value: getFormattedValue( item.deltaTurnoverRgm ?? 0, currency, 0 ),
                style: { font: { color: { rgb: item.deltaTurnoverRgm < 0 ? red : ( item.deltaTurnoverRgm > 0 ? green : black ) } } }
            },

            { value: getFormattedValue( item.planTransactions ?? 0, currency, 0 ) },
            {
                value: getFormattedValue( item.factTransactions ?? 0, currency, 0 ),
                style: { font: { color: { rgb: item.factTransactions < item.planTransactions ? red : ( item.factTransactions >= item.planTransactions ? green : black ) } } }
            },

            { value: getFormattedValue( item.planAverageCheck ?? 0, currency, 0 ) },
            {
                value: getFormattedValue( item.factAverageCheck ?? 0, currency, 0 ),
                style: { font: { color: { rgb: item.factAverageCheck < item.planAverageCheck ? red : ( item.factAverageCheck >= item.planAverageCheck ? green : black ) } } }
            },

            { value: getFormattedValue( item.goalSpmh ?? 0, currency, 0 ) },
            { value: getFormattedValue( item.planSpmh ?? 0, currency, 0 ) },
            { value: getFormattedValue( item.factSpmh ?? 0, currency, 0 ) },

            { value: parseFloat( item.planTcph ?? 0 ) },
            { value: parseFloat( item.factTcph ?? 0 ) },

            { value: item.factItemsPerHour ?? 0 },
            { value: getFormattedValue( item.allProductsInChecks ?? 0, currency, 0 ) },

            { value: parseFloat( item.planProductiveUnproductiveHours ?? 0 ) },
            { value: parseFloat( item.factProductiveUnproductiveHours ?? 0 ) },
        ].filter( Boolean ))
        )).filter(( row ) => row.filter( i => i && i?.value !== 0 && i?.value !== '0' && i?.value !== '' ).length )
    } ]


    return <ExcelSheet dataSet={result} name={title}/>
}

export const prepareExcelCategories = ( categories: any, title: string ) => {
    if ( !categories?.list || categories?.list?.length === 0 ) {
        return []
    }

    const result = [ {
        columns: [
            { title: 'Категория блюд', width: { wpx: 200 } },
            { title: 'План' },
            { title: 'Факт' },
            { title: 'Разница' },
        ],
        data: categories.list.elements.map(( item: any ) => [
            { value: item.categoryName },
            { value: getFormattedValue( item.planCategoryRating, '%', 2 ) },
            { value: getFormattedValue( item.factCategoryRating, '%', 2 ) },
            {
                value: getFormattedValue( item.deltaCategoryRating, '%', 2 ),
                style: { font: { color: { rgb: item?.deltaCategoryRating < 0 ? red : ( item?.deltaCategoryRating > 0 ? green : black ) } } }
            },
        ])
    } ]

    return <ExcelSheet dataSet={result} name={title}/>
}

export const prepareExcelTime = ( time: any, ratingTableGroup: TableGroupType, title: string ) => {
    if ( !time || !time.all || !time.report || time.report.length === 0 ) {
        return []
    }

    type ColHeaderType = {title: string, style?: Record<string, any>, width?: Record<string, any>}
    type ColDataType = {value: string, style?: Record<string, any>}

    const font = { name: 'Arial', sz: '10', bold: false }
    const emptyCol = { title: '', style: { font } }
    const emptyData = { value: '', style: { font } }

    const results: Record<string, any> = {}

    time.all.forEach(( report: any ) => {
        const colsHeader: ColHeaderType[] = []
        const dataHeader: ColDataType[] = []
        const cols: ColHeaderType[] = []
        const data: ColDataType[][] = []

        colsHeader.push({
            title:
                `${format.strings.capitalize( report.restaurantType )} в среднем ${format.strings.seconds( report.factServiceTime )} / ${format.strings.seconds( report.planServiceTime )} ${( report.hit || 0 )}%`,
            style: {
                font: {
                    ...font,
                    sz: '14'
                }
            }
        })
        dataHeader.push( emptyData )
        ratingTableGroup === 'RESTAURANTS' && cols.push({
            title: 'Код',
            style: { font: { ...font, bold: true } }
        })
        cols.push({
            title: `${groupName[ ratingTableGroup ]}`,
            width: { wpx: 240 },
            style: { font: { ...font, bold: true } }
        })
        cols.push({ title: 'Фактическое время', width: { wpx: 130 }, style: { font: { ...font, bold: true } } })
        cols.push({ title: 'Время, превышающее целевой показатель', width: { wpx: 265 }, style: { font: { ...font, bold: true } } })
        cols.push({ title: '% попадания в целевое значение', width: { wpx: 210 }, style: { font: { ...font, bold: true } } })

        ;[ 1, 2, 3 ].forEach(() => {
            colsHeader.push( emptyCol )
            dataHeader.push( emptyData )
        })

        const content = time.report.find(( item: any ) => report.restaurantType === item.restaurantType )?.rating?.content

        content?.forEach(( item: any ) => {
            data.push([])
            ratingTableGroup === 'RESTAURANTS' && data[ data.length - 1 ].push({ value: item.id || '' })
            data[ data.length - 1 ].push({ value: item.name || '', style: { font } })
            data[ data.length - 1 ].push({ value: format.strings.seconds( item.factServiceTime ), style: { font } })
            data[ data.length - 1 ].push({
                value: item.deltaServiceTime !== null ? format.strings.seconds( item.deltaServiceTime ) : '—',
                style: { font: { ...font, color: { rgb: item.deltaServiceTime < 0 ? green : ( item.deltaServiceTime > 0 ? red : black ) } } }
            })
            data[ data.length - 1 ].push({ value: `${( item.hit ?? 0 )}%`, style: { font } })
        })

        results[ report.restaurantType ] = [
            {
                columns: colsHeader,
                data: [ dataHeader ]
            },
            {
                ySteps: -1,
                columns: cols,
                data: data
            }
        ]
    })

    return time.report.map(( report: any ) => <ExcelSheet
        key={report.restaurantType}
        dataSet={results[ report.restaurantType ]}
        name={`${title}.${format.strings.capitalize( report.restaurantType )}`}/>
    )
}
