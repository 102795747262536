/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import dayjs                from 'dayjs'
import { Card, Alert }      from 'antd'

/* APPLICATION */
import { ReportTable, AddButton, Link, Spinner } from 'components'

import { staffActions } from 'services'
import { format }       from 'tools'
import config           from 'config'

import './absence.scss'

const prepare = ( item ) => {
    !item.name && item.employeeName && ( item.name = item.employeeName )
    return item
},
      reasons = [
          {
              label: 'Больничный лист',
              value: 'Sick leave',
          },
          {
              label: 'Отпуск',
              value: 'On vacation',
          },
          {
              label: 'Не выход',
              value: 'Very very bad',
          },
      ]

class Absence extends Component {
    constructor ( props ) {
        super( props )
        this.state = { data: prepare( props.absence || []), }
    }

    componentDidMount () {
        const { absence, list, request } = this.props

        !absence && this.props.fetchAbsence( request )
        !list && this.props.fetchStaff( request )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { absence, request } = this.props

        absence !== nextProps.absence && this.setData( nextProps.absence )
        format.check.request( request, nextProps.request, [], [ 'inactive' ]) &&
      this.reload( nextProps )
        request.restaurantId !== nextProps.request.restaurantId &&
      this.props.flushStaff()
    }

    reload = ( props ) => {
        props.fetchAbsence( props.request )
        props.fetchStaff( props.request )
    }

    setData = ( data ) => {
        data?.forEach(( employee ) => employee.reason = employee.reason === '' ? 'On vacation' : employee.reason )
        this.setState({ data: data ? data.map( prepare ) : null, })
    }

    employees = () =>
        this.props.list
            ? format.generate.options(
                this.props.list.filter(( e ) => e.salary && e.rateDictionary ),
                'id',
                'name'
            )
            : null

    update = ( record, key, val ) => {
        const rec = format.copy.object( record )

        rec[ key ] = val

        if ( key === 'employeeUuid' && rec.absenceUuid ) {
            this.remove( record )
            delete rec.absenceUuid
        }

        format.dates.def( rec )

        if ( !dayjs( rec.dateEnd ).isAfter( dayjs( rec.dateStart )) || !rec.employeeUuid ) {
            return
        }

        delete rec.name
        delete rec.employeeName
        delete rec.absenceId
        delete rec.dayOfWeek
        delete rec.employeeGuid
        delete rec._api_error
        delete rec.__unsaved
        const employeeUuid = rec.employeeUuid
        delete rec.employeeUuid

        this.props.updateAbsence({
            data: rec,
            params: { employeeUuid },
        })
    }

    remove = ( rec ) =>
        rec.__unsaved ? this.removeUnsaved() : this.removeSaved( rec )

    removeUnsaved = () => {
        const data = format.copy.array( this.state.data )
        data.pop()
        this.setState({ data })
    }

    removeSaved = ( rec ) => this.props.removeAbsence( rec )

    add = ( e ) => {
        const rec = format.copy.object( config.defs.absence ),
              ndata = this.props.absence ? format.copy.array( this.props.absence ) : []

        e && e.preventDefault()

        rec.__unsaved = true

        rec.dateStart = dayjs().format( config.format.dayAPI )
        rec.dateEnd = dayjs().add( 1, 'day' ).format( config.format.dayAPI )

        ndata.push( rec )

        this.setData( ndata )
    }

    cellSearch = () => ( search, opt ) =>
        format.find.strInProp( opt.props, 'children', search )

    render () {
        if ( !this.state.data || this.props.loading ) { return <Spinner /> }

        if ( this.state.data.length < 1 ) {
            return (
                <Card bordered={false}>
                    <div className="staff-absence report-table">
                        <Alert
                            message={
                                <span>
                                    <Link onClick={this.add}>
                    Отметьте отсутствующих сотрудников
                                    </Link>{' '}
                  чтобы система не учитывала их при создании расписания
                                </span>
                            }
                            type="warning"
                        />
                    </div>
                </Card>
            )
        }

        return (
            <Card bordered={false}>
                <div className="staff-absence report-table">
                    <ReportTable
                        data={format.generate.noPager( this.state.data )}
                        columns={config.tables.absence}
                        select={{
                            employees: this.employees(),
                            reasons,
                        }}
                        rowKey="absenceUuid"
                        loading={!this.props.absence}
                        cellActions={{ insearch: this.cellSearch, }}
                        onChange={this.update}
                        onRemove={this.remove}
                    />
                    <div className="tabbed-footer">
                        <AddButton text="Добавить" action={this.add} />
                    </div>
                </div>
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({
    request: state.request,
    list: state.staff.list,
    absence: state.staff.absence,
    loading: state.staff.absence_loading,
}),

      allActions = { ...staffActions, }

export default connect( mapStateToProps, allActions )( Absence )
