/* Styles (!) Important to be at the top */
import 'antd/dist/antd.css'
import 'react-vis/dist/style.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import 'dayjs/locale/ru'
import './index.css'

/* REACT */
import React    from 'react'
import ReactDOM from 'react-dom'

/* REDUX */
import { Provider } from 'react-redux'
import { store }    from 'store'

/* TOOLS */
import { BrowserRouter }  from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
/* Components */

import App from './App'
import pck from '../package.json'

import dayjs             from 'dayjs'
import timezone          from 'dayjs/plugin/timezone'
import utc               from 'dayjs/plugin/utc'
import advancedFormat    from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData        from 'dayjs/plugin/localeData'
import weekday           from 'dayjs/plugin/weekday'
import weekOfYear        from 'dayjs/plugin/weekOfYear'
import weekYear          from 'dayjs/plugin/weekYear'
import updateLocale      from 'dayjs/plugin/updateLocale'
import duration          from 'dayjs/plugin/duration'
import isBetween         from 'dayjs/plugin/isBetween'

dayjs.extend( duration )
dayjs.extend( isBetween )
dayjs.extend( updateLocale )
dayjs.extend( customParseFormat )
dayjs.extend( advancedFormat )
dayjs.extend( weekday )
dayjs.extend( localeData )
dayjs.extend( weekOfYear )
dayjs.extend( weekYear )
dayjs.extend( timezone )
dayjs.extend( utc )

dayjs.locale( 'ru' )
dayjs.updateLocale( 'ru', {
    weekStart: 1,
    weekdaysShort: [ 'ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
    monthsShort: [ 'янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек' ]
})


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App version={pck.version} />
        </BrowserRouter>
    </Provider>,
    document.getElementById( 'root' )
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
