/* VENDOR */
import React from 'react'

/* APPLICATION */
import * as formats from '../formats'

import column                 from './helpers'
import { Tooltip }            from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { groupName }          from 'components/rating/GroupSwitch/const'

/*const list = ( visits, key ) =>
    {
        const
            all = visits.reduce(
                ( list, row ) => {
                    ;( row && list.indexOf( row[key] ) < 0 ) && ( list.push( row[key] ) )
                    return list
                },
                []
            )

        if ( all.length === 0 ) return '—'

        return all.length > 1
            ? `${all[0]} +${all.length-1}`
            : all[0]
    }*/

const rgmpop = () => ( text ) => {
    return text

    /*
        if ( !record ) return text
        if ( !record.months[index] ) return text
        if ( !record.months[index].restaurant ) return text
        if ( !record.months[index].restaurant.visits || record.months[index].restaurant.visits.length < 1 ) return text

        const
            visits = record.months[index].restaurant.visits,
            info = (
                <React.Fragment>
                    <p key="rgm">RGM: { list( visits, 'rgm' ) }</p>
                    <p key="area">ТУ: { list( visits, 'areaCoach' ) }</p>
                </React.Fragment>
            )

        return (
            <Popover
                content = { info }
                overlayClassName = "table-cell-popover"
            >
                { text }
            </Popover>
        )*/
}

export default {
    restaurants: {
        categories: ( cmp ) => [
            column( 'Категория блюд', 'categoryName', {
                render: ( text ) => <span>{text}</span>,
                sorter: 'locale'
            }),
            column( 'План', 'planCategoryRating', {
                editable: true,
                as: 'currency',
                numeric: true,
                sorter: 'bynull',
                disabled: ( rec ) =>
                    cmp.state.focused && cmp.state.focused.categoryId !== rec.categoryId,
            }),
            column( 'Факт', 'factCategoryRating', {
                as: 'currency',
                sorter: 'bynull',
                numeric: true,
            }),
            column( 'Разница', 'deltaCategoryRating', {
                as: 'deltaCurrency',
                sorter: 'bynull',
                cell: [ 'cellclass' ],
                numeric: true,
            }),
        ],
        detailedCategories: ( cmp ) => [
            cmp.props.ratingTableGroup === 'RESTAURANTS' && column( 'Код', 'factsNumber', { sorter: 'bynull', width: 120 }),
            column( `${groupName[ cmp.props.ratingTableGroup ]}`, 'name', {
                render: ( text ) => <span>{text}</span>,
                sorter: 'locale',
                width: 450
            }),
            column( 'План', 'planCategoryRating', {
                editable: cmp.props.ratingTableGroup === 'RESTAURANTS',
                as: 'currency',
                numeric: true,
                sorter: 'bynull',
                disabled: ( rec ) =>
                    cmp.state.focused && cmp.state.focused.categoryId !== rec.categoryId,
            }),
            column( 'Факт', 'factCategoryRating', {
                as: 'currency',
                sorter: 'bynull',
                numeric: true,
            }),
            column( 'Разница', 'deltaCategoryRating', {
                as: 'deltaCurrency',
                sorter: 'bynull',
                cell: [ 'cellclass' ],
                numeric: true,
            }),
        ],
        turnover: ( ratingTableGroup ) => [
            ratingTableGroup === 'RESTAURANTS' && column( '', '_selected', {
                as: 'boolean',
                wait: false,
                width: 56
            }),
            ratingTableGroup === 'RESTAURANTS' && column( 'Код', 'factsNumber', {
                sorter: 'server',
                width: 102,
                fixed: 'left',
            }),
            column( `${groupName[ ratingTableGroup ]}`, 'name', {
                width: 250,
                ...(( ratingTableGroup === 'FRANCHISEE_GROUPS' ) && { width: 250 }),
                ...(( ratingTableGroup === 'RESTAURANTS' ) && { as: 'restaurantWithTime', className: 'fix-restaurants', width: 436 }),
                sorter: 'server',
                fixed: 'left',
            }),
            column(
                <div className="delta-turnover delta-header-turnover-center">
                    <span>% выполнения ТО</span>
                    <Tooltip title='"Факт ТО" деленный на "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'percentage', {
                    as: 'percent',
                    sorter: 'server',
                    colorIndex: 'currentPercentage',
                    settingsTitle: '% выполнения ТО',
                    width: 160
                }),
            column(
                <div className="delta-turnover">
                    <span>% выполнения ТО текущий</span>
                    <Tooltip title='"Факт ТО" деленный на "План ТО Директора текущий". Зеленый ≥ 100, желтый < 100, оранжевый < 95, красный < 90'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'currentPercentage', {
                    as: 'percentBar',
                    sorter: 'server',
                    width: 250,
                    colorIndex: 'currentPercentage',
                    settingsTitle: '% выполнения ТО текущий'
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО ТУ</span>
                    <Tooltip title='"План ТО ТУ" за весь день/неделю/месяц/произвольный период'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverAreaCoach', {
                    numeric: true,
                    as: 'currency',
                    sorter: 'server',
                    settingsTitle: 'План ТО ТУ',
                    width: 160
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО Директора</span>
                    <Tooltip title='"План ТО Директора" за весь день/неделю/месяц/произвольный период'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverRgm', {
                    numeric: true,
                    as: 'currency',
                    sorter: 'server',
                    settingsTitle: 'План ТО Директора',
                    width: 160
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница План ТО</span>
                    <Tooltip title='Разница между “План ТО ТУ“ и “План ТО Директора“'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaPlanTurnover', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    isRedPositive: true,
                    settingsTitle: 'Разница План ТО',
                    width: 160
                }),

            column(
                <div className="delta-turnover">
                    <span>План ТО Директора <br/>текущий</span>
                    <Tooltip title='План ТО Директора, накопленный на текущее время работы ресторана (вплоть до минут)'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'planTurnoverRgmCurrent', {
                    numeric: true,
                    as: 'currency',
                    sorter: 'server',
                    settingsTitle: 'План ТО Директора текущий',
                    width: 160
                }),

            column( 'Факт ТО', 'factTurnover', {
                numeric: true,
                as: 'currency',
                sorter: 'server',
                settingsTitle: 'Факт ТО',
                width: 160
            }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт<br/> и План ТО ТУ</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО ТУ"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverAreaCoach', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    settingsTitle: 'Разница Факт и План ТО ТУ',
                    width: 160
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План<br/> ТО Директора</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverRgm', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    cell: [ 'cellclass' ],
                    settingsTitle: 'Разница Факт и План ТО Директора',
                    width: 180
                }),

            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План ТО<br/> Директора текущий</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора текущий"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>,
                'deltaTurnoverRgmCurrent', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    cell: [ 'cellclass' ],
                    settingsTitle: 'Разница Факт и План ТО Директора текущий',
                    width: 180
                }),
        ],
        average: ( ratingTableGroup ) => [
            ratingTableGroup === 'RESTAURANTS' && column( 'Код', 'factsNumber', { sorter: 'server', }),
            column( `${groupName[ ratingTableGroup ]}`, 'name', { sorter: 'server', }),
            column( 'Средний чек', 'factAverageCheck', {
                numeric: true,
                as: 'currency',
                sorter: 'server',
            }),
            column( 'Цель', 'planAverageCheck', {
                numeric: true,
                as: 'currency',
                sorter: 'server',
            }),
            column( 'Разница', 'deltaAverageCheck', {
                numeric: true,
                as: 'deltaCurrency',
                sorter: 'server',
                cell: [ 'cellclass' ],
            }),
        ],
        time: ( ratingTableGroup ) => [
            ratingTableGroup === 'RESTAURANTS' && column( 'Код', 'factsNumber', {
                sorter: 'bynull',
                width: 100
            }),
            column( `${groupName[ ratingTableGroup ]}`, 'name', {
                sorter: 'locale',
                render: ( text ) => <span title={text}>{text}</span>,
                width: ratingTableGroup === 'RESTAURANTS' ? 150 : 230
            }),
            column( <span>Фактическое <br/> время</span>, 'factServiceTime', {
                format: 'seconds',
                as: 'time',
                sorter: 'bydate',
                cell: [ 'cellclass' ],
                width: 105
            }),
            column( <span>Время,  <br /> превышающее <br /> целевой  <br /> показатель</span>, 'deltaServiceTime', {
                format: 'seconds',
                as: 'time',
                cell: [ 'cellclass' ],
                width: 110
            }),
            column( <span>% попадания <br/> в целевое значение</span>, 'hit', {
                as: 'hitPercentage',
                width: 100
            }),
        ],
        performance: ( ratingTableGroup ) => [
            ratingTableGroup === 'RESTAURANTS' && column( '', '_selected', {
                as: 'boolean',
                wait: false,
                width: 56
            }),
            ratingTableGroup === 'RESTAURANTS' && column( 'Код', 'factsNumber', {
                sorter: 'server',
                width: 102,
                fixed: 'left',
            }),
            column( `${groupName[ ratingTableGroup ]}`, 'name', {
                width: 250,
                ...(( ratingTableGroup === 'FRANCHISEE_GROUPS' ) && { width: 250 }),
                ...(( ratingTableGroup === 'RESTAURANTS' ) && { as: 'restaurantWithTime', className: 'fix-restaurants', width: 436 }),
                sorter: 'server',
                fixed: 'left',

            }),
            column( 'План ТО ТУ', 'planTurnoverAreaCoach', {
                as: 'currency',
                numeric: true,
                sorter: 'server',
                width: 130,
                settingsTitle: 'План ТО ТУ',
            }),
            column( 'План ТО Директора', 'planTurnoverRgm', {
                format: 'thousand',
                numeric: true,
                as: 'currency',
                sorter: 'server',
                width: 145,
                settingsTitle: 'План ТО Директора'
            }),
            column(
                <div className="delta-turnover">
                    <span>Разница План ТО</span>
                    <Tooltip title='Разница между “План ТО ТУ“ и “План ТО Директора“'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaPlanTurnover', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    width: 145,
                    settingsTitle: 'Разница План ТО',
                    isRedPositive: true,
                }),
            column( 'Факт ТО', 'factTurnover', {
                format: 'thousand',
                numeric: true,
                as: 'currency',
                sorter: 'server',
                width: 130,
                settingsTitle: 'Факт ТО'
            }),
            column(
                <div className="delta-turnover">
                    <span>Разница Факт<br/> и План ТО ТУ</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО ТУ"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaTurnoverAreaCoach', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Разница Факт и План ТО ТУ'
                }),
            column(
                <div className="delta-turnover">
                    <span>Разница Факт и План<br/> ТО Директора</span>
                    <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'deltaTurnoverRgm', {
                    numeric: true,
                    as: 'deltaCurrency',
                    sorter: 'server',
                    width: 170,
                    settingsTitle: 'Разница Факт и План ТО Директора',
                }),
            column(
                <div className="delta-turnover">
                    <span>План ТРЗ</span>
                    <Tooltip title='Транзакции План'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'planTransactions', {
                    as: 'number',
                    numeric: true,
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'План ТРЗ'
                }),
            column(
                <div className="delta-turnover">
                    <span>Факт ТРЗ</span>
                    <Tooltip title='Транзакции Факт'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'factTransactions', {
                    as: 'deltaNumbers',
                    numeric: true,
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Факт ТРЗ',
                    objForDelta:
                    { fact: 'factTransactions', plan: 'planTransactions' },
                }),
            column( 'План СЧ', 'planAverageCheck', {
                numeric: true,
                as: 'currency',
                sorter: 'server',
                width: 130,
                settingsTitle: 'План СЧ'
            }),
            column( 'Факт СЧ', 'factAverageCheck', {
                numeric: true,
                as: 'deltaCurrency',
                sorter: 'server',
                width: 130,
                settingsTitle: 'Факт СЧ',
                objForDelta:
                    { fact: 'factAverageCheck', plan: 'planAverageCheck' },
            }),
            column(
                <div className="delta-turnover">
                    <span>Цель SPMH ТУ</span>
                    <Tooltip title='Целевой SPMH на месяц, добавляемый ТУ в разделе "Планирование"'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'goalSpmh', {
                    numeric: true,
                    as: 'currency',
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Цель SPMH ТУ'
                }),
            column(
                <div className="delta-turnover">
                    <span>План SPMH<br/> Директора</span>
                </div>, 'planSpmh', {
                    as: 'number',
                    numeric: true,
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'План SPMH Директора'
                }),
            column( 'Факт SPMH', 'factSpmh', {
                as: 'number',
                numeric: true,
                sorter: 'server',
                width: 130,
                settingsTitle: 'Факт SPMH'
            }),
            column( 'План TCPH', 'planTcph', {
                format: 'float',
                numeric: true,
                sorter: 'server',
                width: 130,
                settingsTitle: 'План TCPH'
            }),
            column( 'Факт TCPH', 'factTcph', {
                format: 'float',
                numeric: true,
                sorter: 'server',
                width: 130,
                settingsTitle: 'Факт TCPH'
            }),
            column(
                <div className="delta-turnover">
                    <span>Items Per Hour</span>
                    <Tooltip title='Количество блюд в чеках, деленное на количество отработанных часов'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'factItemsPerHour', {
                    numeric: true,
                    format: 'float',
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Items Per Hour'
                }),
            column(
                <div className="delta-turnover">
                    <span>Количество блюд</span>
                    <Tooltip title='Количество блюд (в том числе блюда из комбо)'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                , 'allProductsInChecks', {
                    numeric: true,
                    format: 'float',
                    sorter: 'server',
                    width: 150,
                    settingsTitle: 'Количество блюд'
                }),
            column(
                <div className="delta-turnover">
                    <span>Запланировано<br/> часов</span>
                </div>
                , 'planProductiveUnproductiveHours', {
                    format: 'float',
                    numeric: true,
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Запланировано часов'
                }),
            column(
                <div className="delta-turnover">
                    <span>Отработано<br/> часов</span>
                </div>
                , 'factProductiveUnproductiveHours', {
                    format: 'float',
                    numeric: true,
                    sorter: 'server',
                    width: 130,
                    settingsTitle: 'Отработано часов'
                }),
        ],

        visits: [
            column( 'Дата', 'date', {
                as: 'date',
                dateFormat: formats.onlyDayView,
            }),
            column( 'Оценка', 'result', { as: 'currency', }),
            column( 'ТУ', 'areaCoach' ),
            column( 'RGM', 'rgm' ),
            column( 'МС', 'shiftSupervisor' ),
        ],

        overall: [
            column( 'Quarter Placeholder', '', {
                children: [
                    column( 'Ресторан', 'restaurantName', { sorter: 'locale', }),
                ],
            }),
            column( 'Итог', 'total', {
                as: 'currency',
                rowSpan: 2,
                sorter: 'bynull',
                className: 'collapsed',
            }),
        ],

        month: ( index ) => [
            column( 'Оценка RGM', `month-${index}-total`, {
                as: 'currency',
                render: rgmpop( index ),
            }),
            column( 'Ресторан', `month-${index}-restaurant`, { as: 'currency', }),
            column( 'People', `month-${index}-people`, {
                as: 'currency',
                render: rgmpop( index ),
            }),
            column( 'Products', `month-${index}-product`, {
                as: 'currency',
                render: rgmpop( index ),
            }),
            column( 'Promo', `month-${index}-promo`, {
                as: 'currency',
                render: rgmpop( index ),
            }),
            column( 'Place', `month-${index}-place`, {
                as: 'currency',
                render: rgmpop( index ),
            }),
            column( 'Рутина', `month-${index}-routine`, {
                as: 'currency',
                max: 100,
                editable: true,
            }),
            column( 'AP BSC', `month-${index}-apbsc`, {
                as: 'currency',
                max: 100,
                editable: true,
            }),
            column( 'BSC', `month-${index}-bsc`, {
                editable: true,
                max: 5,
                step: 0.1,
                rawFormat: 'noZeroFloat',
                fixedDecimals: 1,
                as: 'number',
            }),
        ],
    },
}
