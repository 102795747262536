import { TableGroupType }                   from '../../Rating'
import { useEffect, useState }              from 'react'
import { RatingDataQueries, RatingFilters } from './useLoadRatingData'

export type RatingSorter = {tab?: string, field?: string, order?: 'ascend' | 'descend'}

const STORAGE_KEY = 'ratingTableGroup'

export const useRatingSorter = ( queries: RatingDataQueries, filters: RatingFilters, currentTab: string ): [RatingSorter, ( ratingTableGroup: RatingSorter ) => void] => {
    const [ ratingSorter, setRatingSorter ] = useState<RatingSorter>({})

    useEffect(() => {
        const { tab, field, order } = ratingSorter
        if ( !tab || !field ) { return }

        const request = {
            ...queries.request,
            franchiseeGroupCodes: filters.partner,
            cities: filters.cities,
            regionCoachCodes: filters.regionCoaches,
            marketCoachCodes: filters.marketCoaches,
            areaCoachCodes: filters.areaCoaches,
            factsNumbers: filters.factsNumbers,
            facilityTypeIds: filters.facilityTypes,
            dataGroup: sessionStorage.getItem( STORAGE_KEY ) as TableGroupType || 'RESTAURANTS',
            sort: `${field},${order && order === 'ascend' ? 'asc' : 'desc'}`
        } as Record<string, any>

        if ( !order ) {
            delete request.sort
        }

        if ( request.restaurantIds?.length ) {
            request.factsNumbers = request.restaurantIds
            delete request.restaurantIds
        }

        delete request.employeeId
        delete request.zoneId

        if ( currentTab === 'turnover' ) {
            queries.fetchRatingTurnover( request )
            queries.flushTurnover()
        }

        if ( currentTab === 'average' ) {
            queries.fetchRatingAverageCheck( request )
            queries.flushAverage()
        }

        if ( currentTab === 'performance' ) {
            queries.fetchRatingPerformance( request )
            queries.flushPerformance()
        }
    }, [ ratingSorter ])

    return [ ratingSorter, setRatingSorter ]
}
