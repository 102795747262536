/* VENDOR */
import React, { useState, useEffect, useRef } from 'react'
import { Tooltip }                            from 'antd'
import { ExclamationOutlined }                from '@ant-design/icons'
import dayjs                                  from 'dayjs'

/* APPLICATION */
import { Days } from 'components'
import config   from 'config'


import './schedule-days.scss'

 interface PropTypes {
    show: boolean
    data: Data
    day: string
    onDay: ( day: string ) => void
  }

interface Data {
    dateStart: string
    dateEnd: string
    days: any[]
  }

const ScheduleDays = ( props: PropTypes ) => {
    const { show, data, day, onDay } = props

    const [ alarm, setAlarm ] = useState<boolean>( false )

    const ttTimeout = useRef<NodeJS.Timeout | null>( null )
    const ttUpdateTime = useRef<number | null>( null )
    const mcAlarm = useRef<any>( null )

    useEffect(() => {
        initForceUpdate()
        return () => { offForceUpdate() }
    }, [])

    useEffect(() => {
        const timeout = setTimeout( tooltipUpdate, 1000 )
        return () => clearTimeout( timeout )
    })

    const initForceUpdate = () => {
        const scroll = document.querySelector( '.inner-content' )

        if ( !scroll ) {
            setTimeout( initForceUpdate, 100 )
            return
        }
        scroll.addEventListener( 'scroll', tooltipUpdate )
        window.addEventListener( 'resize', tooltipUpdate )

        setTimeout(() => tooltipOnOff(), 1000 )
    }


    const tooltipOnOff = () => {
        if ( ttTimeout.current ) {
            clearTimeout( ttTimeout.current )
        }
        setAlarm( false )
        ttTimeout.current = setTimeout(() => setAlarm( true ), 1000 )
    }

    const tooltipUpdate = () => {
        const delay = 200
        const currentTime = new Date().valueOf()

        if ( ttUpdateTime.current && ttUpdateTime.current > currentTime - delay ) {
            return
        }

        setTimeout(() => mcAlarm.current && mcAlarm.current.forceUpdate(), delay )
        ttUpdateTime.current = currentTime
    }

    const offForceUpdate = () => {
        document
            .querySelector( '.inner-content' )
            ?.removeEventListener( 'scroll', tooltipUpdate )
        window.removeEventListener( 'resize', tooltipUpdate )
    }

    const title = ( str: dayjs.Dayjs | null, offset: number ) => {
        const day = data.days.find(( d ) => d.dayOfWeek === offset )
        const start = dayjs( str, config.format.dayAPI )
        const dow = dayjs().day( offset ).format( 'ddd' )
        const date = start.add( offset - 1, 'days' ).format( config.format.onlyDayView )
        const isToday = dayjs( data.dateStart )
            .startOf( 'day' )
            .add(( day?.dayOfWeek ?? 0 ) - 1, 'days' )
            .isSame( dayjs().startOf( 'day' ))
        const isSelected = parseInt( day ) === day?.dayOfWeek
        const otext = date + ', ' + dow.toUpperCase()
        const text = isToday ? <b>{otext}</b> : otext

        if ( !day ) { return text }
        return empty( day ) ? (
            <Tooltip
                ref={mcAlarm}
                open={alarm && !isSelected}
                placement="bottom"
                title={<ExclamationOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                overlayClassName="mc-warning"
            >
                <b className="force-mc" title="На завтра не назначен главный по смене">
                    {text}
                </b>
            </Tooltip>
        ) : (
            text
        )
    }

    const empty = ( day: any ): boolean => {
        if ( data.dateStart !== dayjs().startOf( 'week' ).format( config.format.dayAPI )) {
            return false
        }

        const tomorrow = parseInt( dayjs().add( 1, 'day' ).format( 'd' ))
        const dow = day?.dayOfWeek === 7 ? 0 : day?.dayOfWeek

        if ( tomorrow === dow && tomorrow < 7 ) {

            const position = day?.positions?.filter(
                ( p: any ) => p.positionGuid === config.mcGuid
            )[ 0 ]

            if ( !position ) { return true }

            const supervisors = position.shifts.filter(( s: any ) => s.shiftSupervisor )

            return supervisors.length === 0
        }

        return false
    }

    const onChange = ( day: number ) => {
        tooltipOnOff()
        setTimeout(() => onDay( String( day )), 0 )
    }

    return (
        <Days
            visible={show && !!data}
            start={data ? dayjs( data.dateStart ) : null}
            current={day}
            titleRender={title}
            onChange={onChange}
            onScroll={tooltipOnOff}
        />
    )
}

export default ScheduleDays
