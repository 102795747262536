import { format }                                                               from 'tools'
import {
    PostMetricsSaleChannelsPayload,
    PostMetricsSaleChannelsSearchBody, PostMetricsSaleChannelsSummaryPayload,
} from './type'

const service = 'feedback'

export const postMetricsSaleChannelsSummary = ( body: PostMetricsSaleChannelsSummaryPayload ) =>
    format.generate.post( service, 'metrics/sale-channels/summary', body )

export const postMetricsSaleChannelsSearch = ({ body, params }: {body: PostMetricsSaleChannelsSearchBody, params?: { page?: number }}) =>
    format.generate.post( service, 'metrics/sale-channels/search', body, '', '', '', '', { params })

export const postMetricsSaleChannels = ( body: PostMetricsSaleChannelsPayload ) =>
    format.generate.post( service, 'metrics/sale-channels', body )
