import * as api                                                                     from './api'
import { storeAsyncUpdate, storeUpdate }                                            from 'store'
import {
    PostMetricsSaleChannelsPayload, PostMetricsSaleChannelsResponse,
    PostMetricsSaleChannelsSearchBody, PostMetricsSaleChannelsSearchResponse,
    PostMetricsSaleChannelsSummaryPayload, PostMetricsSaleChannelsSummaryResponse
} from './type'

export const postMetricsSaleChannelsSummary = storeAsyncUpdate<PostMetricsSaleChannelsSummaryPayload, PostMetricsSaleChannelsSummaryResponse>(
    'guestVote.metricsSaleChannelsSummary',
    ( body ) => api.postMetricsSaleChannelsSummary( body ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    metricsSaleChannelsSummary: event.data
                }
            }
        }
        return state
    }
)

export const flushMetricsSaleChannelsSearch = storeUpdate(
    'guestVote.flush.metricsSaleChannelsSearch',
    ( _, state ) => {
        return {
            ...state,
            guestVote: {
                ...state.guestVote,
                metricsSaleChannelsSearch: {
                    ...state.guestVote.metricsSaleChannelsSearch,
                    content: []
                }
            }
        }
    }
)


export const postMetricsSaleChannelsSearch = storeAsyncUpdate<PostMetricsSaleChannelsSearchBody, PostMetricsSaleChannelsSearchResponse>(
    'guestVote.metricsSaleChannelsSearch',
    ( body ) => api.postMetricsSaleChannelsSearch({ body }),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    metricsSaleChannelsSearch: event.data
                }
            }
        }
        return state
    }
)

export const appendMetricsSaleChannelsSearch = storeAsyncUpdate<{
    body: PostMetricsSaleChannelsSearchBody,
    params: { page: number }
}, PostMetricsSaleChannelsSearchResponse>(
    'guestVote.metricsSaleChannelsSearchAppend',
    ( payload ) => api.postMetricsSaleChannelsSearch( payload ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    metricsSaleChannelsSearch: {
                        ...state.guestVote.metricsSaleChannelsSearch,
                        content: [
                            ...state.guestVote.metricsSaleChannelsSearch.content,
                            ...event.data.content
                        ],
                    }
                }
            }
        }
        return state
    }
)

export const postMetricsSaleChannels = storeAsyncUpdate<Omit<PostMetricsSaleChannelsPayload, 'dataGroup'> & {
    dataGroup: 'MONTH' | 'YEAR'
}, PostMetricsSaleChannelsResponse>(
    'guestVote.metricsSaleChannels',
    ( body ) => api.postMetricsSaleChannels( body ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    metricsSaleChannels: event.data
                }
            }
        }
        return state
    }
)
