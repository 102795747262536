/* VENDOR */
import React, { useState, useEffect, useRef } from 'react'

import { Button, Table, Tooltip }   from 'antd'
import Icon, { InfoCircleOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Icons } from 'components'
import config    from 'config'

import './schedule-summary-report.scss'
import dayjs       from 'dayjs'
import * as XLSX   from 'xlsx'
import column      from '../../../config/tables/helpers.js'
import { format }  from 'tools'
import { Request } from 'pages/Schedule/Schedule'

interface PropTypes {
    data: Data
    date: dayjs.Dayjs
    currentWeek: {
      dateStart: string
      dateEnd: string
    }
    request: {
      restaurantId: string
    }
    fetchScheduleSummary: ( date: dayjs.Dayjs ) => void
    fetchAllSchedules: ( params: Request ) => void
    deleteSchedulesEmployeesHided: ( params: string ) => Promise<void>
    postSchedulesEmployeesHided: ( params: Record<string, string | null> ) => Promise<void>
  }

interface Data {
      positionStations?: Positions
      hidedEmployees?: Array<HidedEmployees>
      planTurnoverRgm?: string
      planAverageCheck?: string,
      planSpmh?: string,
      planTcph?: string,
      days?: Days
}

interface DataRecord {
    transfer?: any;
    absence?: any;
    dateEndWork?: any;
    days?: any;
    employeeUuid?: any;
    positionUuid?: string | null
}
interface Transfer {
    originalFactsNumber: string
    transferredRestName: string
    originalRestName: string
    dateStart: string
    dateEnd: string
    status: string
}

interface Absence {
    reason: string
    dateStart: string
    dateEnd: string
}

interface Employees {
    employeeUuid: string
    employeeName: string
    positionName: string
    planSummaryPositionDuration?: string
    planSummaryDuration?: string
    transfer?: Array<Transfer>
    absence?: Array<Absence>
    dateEndWork?: string
    days: Days
  }

  interface HidedEmployees {
    employeeUuid: string
    employeeName: string
    positionName: string
    positionUuid: string
    hidedEmployeeUuid: string
  }

type Days = Record<string, any>
// {
//     date: string
//     shifts?: Array<{
//       isNightShift: boolean
//       shiftStart: string
//       shiftEnd: string
//     }>
//   }
type Positions = Array<{
    positionStationName: string
    employees: Array<Employees>
 }>

const ScheduleSummaryReport = ( props: PropTypes ) => {
    const {
        data: propData,
        date,
        currentWeek,
        request,
        fetchScheduleSummary,
        fetchAllSchedules,
        deleteSchedulesEmployeesHided,
        postSchedulesEmployeesHided
    } = props

    const [ data, setData ] = useState( propData || {})

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        load()
    }, [ propData?.hidedEmployees ])

    useEffect(() => {
        load()
    }, [ date.startOf( 'week' ).format( config.format.dayAPI ) ])

    useEffect(() => {
        if ( propData ) {
            setData( propData )
        }
    }, [ propData ])

    const load = () => {
        fetchScheduleSummary( date )
    }

    const setHiddedEmployees = ( record: DataRecord ) => {
        postSchedulesEmployeesHided({
            dateStart: currentWeek.dateStart,
            dateEnd: currentWeek.dateEnd,
            employeeUuid: record.employeeUuid,
            factsNumber: request.restaurantId,
            positionUuid: record.positionUuid ? record.positionUuid : null,
        }).then(() => {
            load()
        })
    }

    const replaceDot = ( value: any ) => value && String( value ).replace( '.', ',' )

    const columns = () => {
        const weekDays = Array.from({ length: 7 }, ( _, i ) => dayjs( date ).startOf( 'week' ).add( i, 'days' ))

        return [
            {
                title: 'Станции',
                dataIndex: 'positionStationName',
                key: 'key',
                fixed: 'left',
                width: 110,
                render: ( positionStation: { value: any; rowSpan: any }) => {
                    const { value, rowSpan } = positionStation
                    return {
                        children: value,
                        props: { rowSpan: rowSpan },
                    }
                }
            },
            {
                title: 'Сотрудники',
                dataIndex: 'employeeName',
                width: 240,
                key: 'key',
                fixed: 'left',
                render: ( text: string | undefined, record: DataRecord ) => {
                    const transferIcon = record.transfer ? (
                        request.restaurantId == record.transfer[ 0 ].originalFactsNumber ?
                                <Icon component={Icons.Transfer.def} onPointerEnterCapture onPointerLeaveCapture/> :
                                <Icon component={Icons.Temporary.def} onPointerEnterCapture onPointerLeaveCapture/>
                    ) : null

                    const transferTooltipText = record.transfer ? (
                        request.restaurantId == record.transfer[ 0 ].originalFactsNumber ?
                            `Переведен в ресторан ${record.transfer[ 0 ].transferredRestName} с
                            ${dayjs( record.transfer[ 0 ].dateStart ).format( 'DD.MM.YYYY' )} по
                            ${dayjs( record.transfer[ 0 ].dateEnd ).format( 'DD.MM.YYYY' )}` :
                            `Переведен из ресторана ${record.transfer[ 0 ].originalRestName} с
                            ${dayjs( record.transfer[ 0 ].dateStart ).format( 'DD.MM.YYYY' )} по
                             ${dayjs( record.transfer[ 0 ].dateEnd ).format( 'DD.MM.YYYY' )}`
                    ) : ''

                    let absenceReason
                    if ( record.absence ) {
                        switch ( record.absence?.[ 0 ]?.reason ) {
                            case 'Very very bad':
                                absenceReason = 'Не выход'
                                break
                            case 'On vacation':
                                absenceReason = 'Отпуск'
                                break
                            case 'Sick leave':
                                absenceReason = 'Больничный'
                                break
                            default:
                                absenceReason = ' '
                        }
                    }

                    const absenceText = record.absence ? `${absenceReason}. с ${dayjs( record.absence?.[ 0 ]?.dateStart ).format( 'DD.MM.YYYY' )} по ${dayjs( record.absence?.[ 0 ]?.dateEnd ).format( 'DD.MM.YYYY' )}` : ''

                    const endWorkText = record.dateEndWork ? `Уволен с ${dayjs( record.dateEndWork ).format( 'DD.MM.YYYY' )}` : ''

                    let tooltipText = transferTooltipText
                    if ( absenceText && endWorkText ) {
                        tooltipText = `${absenceText}. ${endWorkText}`
                    } else if ( absenceText ) {
                        tooltipText = absenceText
                    } else if ( endWorkText ) {
                        tooltipText = endWorkText
                    }

                    return (
                        <span className='summary-report-transfer-table-cell'>
                            <span className="transfer-icon">
                                {transferIcon ? (
                                    <Tooltip title={tooltipText}>{transferIcon}</Tooltip>
                                ) : ( <Tooltip title={tooltipText}> {text} </Tooltip> )}
                                {transferIcon ? text : null}
                            </span>
                            {!Object.keys( record.days ).length && (
                                <span className='cross-black-button'>
                                    <span
                                    // @ts-ignore
                                        type='link'
                                        className='remove-row-control'
                                        onClick={() => setHiddedEmployees( record )}
                                    >
                                        <Icon component={Icons.Remove.active} onPointerEnterCapture onPointerLeaveCapture/>
                                    </span>
                                </span>
                            )}
                        </span>
                    )
                },
            },
            ...weekDays.map(( day, index ) => ({
                title: `${day.format( 'DD.MM.YYYY' )} | ${day.format( 'dd' ).toUpperCase()}`,
                children: [
                    {
                        title: 'С ночи',
                        children: [
                            {
                                title: 'Начало',
                                dataIndex: `nightShiftStart_${index}`,
                                key: 'key',

                                render: ( text: string | undefined, record: DataRecord ) => {
                                    const shiftDateKey = day.format( 'YYYY-MM-DD' )
                                    const shift = record.days[ shiftDateKey ]?.nightShifts[ 0 ]
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format( 'YYYY-MM-DD' )
                                    let statusText = ''

                                    const transfer = record.transfer?.find(( t: Transfer ) =>
                                        currentDate >= dayjs( t.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( t.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const absence = record.absence?.find(( a: Absence ) =>
                                        currentDate >= dayjs( a.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( a.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const isFired = record.dateEndWork && currentDate > dayjs( record.dateEndWork ).format( 'YYYY-MM-DD' )

                                    if ( transfer ) {
                                        if ( !allShifts ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.originalFactsNumber ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.transferredRestFactsNumber ) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if ( shift ) {
                                                return dayjs( shift.shiftStart ).format( 'HH:mm' )
                                            }
                                            return ''
                                        }
                                    } else if ( isFired ) {
                                        statusText = 'Уволен'
                                    } else if ( absence ) {
                                        switch ( absence.reason ) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = ''
                                        }
                                    }

                                    if ( statusText ) {
                                        return statusText
                                    } else {
                                        return shift ? dayjs( shift.shiftStart ).format( 'HH:mm' ) : ''
                                    }
                                },
                                width: 110,
                            },
                            {
                                title: 'Конец',
                                dataIndex: `nightShiftEnd_${index}`,
                                key: 'key',

                                render: ( text: string | undefined, record: DataRecord ) => {
                                    const shiftDateKey = day.format( 'YYYY-MM-DD' )
                                    const shift = record.days[ shiftDateKey ]?.nightShifts[ 0 ]
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format( 'YYYY-MM-DD' )
                                    let statusText = ''

                                    const transfer = record.transfer?.find(( t: Transfer ) =>
                                        currentDate >= dayjs( t.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( t.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const absence = record.absence?.find(( a: Absence ) =>
                                        currentDate >= dayjs( a.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( a.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const isFired = record.dateEndWork && currentDate > dayjs( record.dateEndWork ).format( 'YYYY-MM-DD' )

                                    if ( transfer ) {
                                        if ( !allShifts ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.originalFactsNumber ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.transferredRestFactsNumber ) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if ( shift ) {
                                                return dayjs( shift.shiftEnd ).format( 'HH:mm' )
                                            }
                                            return ''
                                        }
                                    } else if ( isFired ) {
                                        statusText = 'Уволен'
                                    } else if ( absence ) {
                                        switch ( absence.reason ) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }

                                    if ( statusText ) {
                                        return statusText
                                    } else {
                                        return shift ? dayjs( shift.shiftEnd ).format( 'HH:mm' ) : ''
                                    }
                                },
                                width: 110,
                            },
                        ],
                    },
                    {
                        title: 'Смена',
                        children: [
                            {
                                title: 'Начало',
                                dataIndex: `dayShiftStart_${index}`,
                                key: 'key',

                                render: ( text: string | undefined, record: DataRecord ) => {
                                    const shiftDateKey = day.format( 'YYYY-MM-DD' )
                                    const shifts = record.days[ shiftDateKey ]?.dayShifts
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format( 'YYYY-MM-DD' )
                                    let statusText = ''

                                    const transfer = record.transfer?.find(( t: Transfer ) =>
                                        currentDate >= dayjs( t.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( t.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const absence = record.absence?.find(( a: Absence ) =>
                                        currentDate >= dayjs( a.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( a.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const isFired = record.dateEndWork && currentDate > dayjs( record.dateEndWork ).format( 'YYYY-MM-DD' )

                                    if ( transfer ) {
                                        if ( !allShifts ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.originalFactsNumber ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.transferredRestFactsNumber ) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if ( shifts ) {
                                                return dayjs( shifts[ 0 ].shiftStart ).format( 'HH:mm' )
                                            }
                                            return ''
                                        }
                                    } else if ( isFired ) {
                                        statusText = 'Уволен'
                                    } else if ( absence ) {
                                        switch ( absence.reason ) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }

                                    if ( statusText ) {
                                        return statusText
                                    } else {
                                        return shifts && shifts.length > 0 ? dayjs( shifts[ 0 ].shiftStart ).format( 'HH:mm' ) : ''
                                    }
                                },
                                width: 110,
                            },
                            {
                                title: 'Конец',
                                dataIndex: `dayShiftEnd_${index}`,
                                key: 'key',

                                render: ( text: string | undefined, record: DataRecord ) => {
                                    const shiftDateKey = day.format( 'YYYY-MM-DD' )
                                    const shifts = record.days[ shiftDateKey ]?.dayShifts
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format( 'YYYY-MM-DD' )
                                    let statusText

                                    const transfer = record.transfer?.find(( t: Transfer ) =>
                                        currentDate >= dayjs( t.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( t.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const absence = record.absence?.find(( a: Absence ) =>
                                        currentDate >= dayjs( a.dateStart ).format( 'YYYY-MM-DD' ) && currentDate <= dayjs( a.dateEnd ).format( 'YYYY-MM-DD' ))

                                    const isFired = record.dateEndWork && currentDate > dayjs( record.dateEndWork ).format( 'YYYY-MM-DD' )

                                    if ( transfer ) {
                                        if ( !allShifts ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.originalFactsNumber ) {
                                            statusText = transfer.status
                                        } else if ( request.restaurantId == transfer.transferredRestFactsNumber ) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if ( shifts ) {
                                                return dayjs( shifts[ shifts.length - 1 ].shiftEnd ).format( 'HH:mm' )
                                            }
                                            return ''
                                        } else {
                                            statusText = transfer.status
                                        }
                                    } else if ( isFired ) {
                                        statusText = 'Уволен'
                                    } else if ( absence ) {
                                        switch ( absence.reason ) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }

                                    if ( statusText ) {
                                        return statusText
                                    } else {
                                        return shifts && shifts.length > 0 ? dayjs( shifts[ shifts.length - 1 ].shiftEnd ).format( 'HH:mm' ) : ''
                                    }
                                },
                                width: 110,
                            },
                        ],
                    },
                    {
                        title: 'Позиция',
                        dataIndex: 'positionName',
                        key: 'key',
                        width: 110,
                    },
                ],
            })),
            {
                title: <div className="summary-report-tooltips">
                    <span>Часы по позициям</span>
                    <Tooltip
                        title='Запланированные в расписании часы сотрудника по позиции за неделю'>
                        <InfoCircleOutlined onPointerEnterCapture onPointerLeaveCapture/>
                    </Tooltip>
                </div>,
                dataIndex: 'planSummaryPositionDuration',
                key: 'key',
                width: 160,
                render: ( number: number ) => replaceDot( number )
            },
            {
                title: <div className="summary-report-tooltips">
                    <span>Общие часы</span>
                    <Tooltip
                        title='Запланированные в расписании часы сотрудника по всем позициям за неделю'>
                        <InfoCircleOutlined onPointerEnterCapture onPointerLeaveCapture/>
                    </Tooltip>
                </div>,
                dataIndex: 'planSummaryDuration',
                key: 'key',
                width: 120,
                render: ( number: number ) => replaceDot( number )
            },
            {
                title: 'Ознакомлен',
                dataIndex: 'dateEndWorks',
                key: 'key',
                width: 110
            },
        ]
    }

    const transformData = ( data: Positions | undefined ) => {

        const transformedData: any[] = []
        const positionRowSpanMap: Record<string, number> = {}

        data?.forEach(( position ) => {

            positionRowSpanMap[ position.positionStationName ] =
                ( positionRowSpanMap[ position.positionStationName ] || 0 ) + position.employees.length
        })

        data?.forEach(( position ) => {
            const positionName = position.positionStationName

            let rowSpan = positionRowSpanMap[ positionName ]

            position?.employees?.forEach(( employee ) => {

                const originalEmployee = data?.find( p => p.positionStationName === positionName )?.employees?.find( e => e.employeeUuid === employee.employeeUuid && e.positionName === employee.positionName )
                const employeeData: Employees = {
                    ...employee,
                    planSummaryPositionDuration: originalEmployee?.planSummaryPositionDuration,
                    planSummaryDuration: originalEmployee?.planSummaryDuration,
                    transfer: originalEmployee?.transfer,
                    absence: originalEmployee?.absence,
                    dateEndWork: originalEmployee?.dateEndWork,
                    days: {},
                }
                const startDateIndex = dayjs( employee.absence?.[ 0 ]?.dateStart ).day()
                const endDateIndex = dayjs( employee.absence?.[ 0 ]?.dateEnd ).day()
                const endDateWorkIndex = employee.dateEndWork ? dayjs( employee.dateEndWork ).day() : null

                employee.days?.forEach(( day: { date: string; shifts: any[] }) => {
                    const dateKey = dayjs( day.date ).format( 'YYYY-MM-DD' )
                    const previousDateKey = dayjs( day.date ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' )

                    employeeData.days[ previousDateKey ] = employeeData.days[ previousDateKey ] ? employeeData.days[ previousDateKey ] : {
                        dayShifts: [],
                        nightShifts: []
                    }

                    employeeData.days[ dateKey ] = { dayShifts: [], nightShifts: [] }

                    day.shifts?.forEach(( shift ) => {
                        if ( shift.isNightShift ) {
                            employeeData.days[ dateKey ].nightShifts.push( shift )
                        } else {
                            employeeData.days[ dateKey ].dayShifts.push( shift )
                        }
                    })
                })

                for ( let dayIndex = 0; dayIndex < 7; dayIndex++ ) {
                    let dayInfo: Record<string, string> = {}

                    employee.transfer?.forEach(( transfer ) => {
                        const transferStartDate = dayjs( transfer.dateStart )
                        const transferEndDate = dayjs( transfer.dateEnd )
                        const currentDate = dayjs( date ).startOf( 'week' ).add( dayIndex, 'days' )

                        if ( currentDate.isBetween( transferStartDate, transferEndDate, 'day', '[]' )) {
                            dayInfo.status = transfer.status
                        }
                    })
                    if ( employee.transfer && dayIndex >= startDateIndex && dayIndex <= endDateIndex ) {
                        dayInfo = { status: employee.transfer[ 0 ].status }
                    }

                    if ( employee.absence && dayIndex >= startDateIndex && dayIndex <= endDateIndex ) {
                        let reason
                        switch ( employee.absence[ 0 ].reason ) {
                            case 'Very very bad':
                                reason = 'Не выход'
                                break
                            case 'On vacation':
                                reason = 'Отпуск'
                                break
                            case 'Sick leave':
                                reason = 'Больничный'
                                break
                            default:
                                reason = employee.absence[ 0 ].reason
                        }
                        dayInfo = { ...dayInfo, reason }
                    }
                    if ( endDateWorkIndex !== null && dayIndex > endDateWorkIndex ) {
                        dayInfo = { ...dayInfo, status: 'Уволен' }
                    }

                    if ( employee.days && dayIndex < employee.days.length && employee.days[ dayIndex ] && ( dayInfo.status || dayInfo.reason )) {
                        employee.days[ dayIndex ] = dayInfo
                    }
                }

                transformedData.push({
                    key: `${employee.positionName}_${employee.employeeUuid}`,
                    positionStationName: {
                        value: positionName,
                        rowSpan: rowSpan,
                    },
                    ...employeeData,
                })

                rowSpan = 0
            })
        })
        return transformedData
    }

    const downloadExcel = ( transformedData: any[]) => {
        const workbook = XLSX.utils.book_new()

        if ( !transformedData.length ) {
            return
        }
        const worksheetData = transformedData.reduce(( acc, item ) => {
            const baseRow = {
                'Станция': item.positionStationName.value,
                'Сотрудник': item.employeeName,
                'Позиция': item.positionName,
                'Общие часы': item.planSummaryDuration
            }

            Object.keys( item.days ).forEach( date => {

                const dayShifts = item.days[ date ].dayShifts.map(( shift: { shiftStart: any; shiftEnd: any }) => `${shift.shiftStart} - ${shift.shiftEnd}` ).join( ', ' )
                const nightShifts = item.days[ date ].nightShifts.map(( shift: { shiftStart: any; shiftEnd: any }) => `${shift.shiftStart} - ${shift.shiftEnd}` ).join( ', ' )

                acc.push({
                    ...baseRow,
                    'Дата': date,
                    'Дневные Смены': dayShifts,
                    'Ночные Смены': nightShifts
                })
            })

            if ( Object.keys( item.days ).length === 0 ) {
                acc.push( baseRow )
            }

            return acc
        }, [])

        const worksheet = XLSX.utils.json_to_sheet( worksheetData, {
            header: [ 'Станция', 'Сотрудник', 'Позиция', 'Общие часы', 'Дата', 'Дневные Смены', 'Ночные Смены' ],
            skipHeader: true
        })
        XLSX.utils.book_append_sheet( workbook, worksheet, 'Расписание' )

        XLSX.writeFile( workbook, 'Расписание_сотрудников.xlsx' )
    }

    const spmhTcph = ( data: Data ) => {
        // @ts-ignore
        const today = new Date( date )
        let dayOfWeek = today.getDay()
        dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek - 1

        const firstDayOfWeek = new Date( today )
        firstDayOfWeek.setDate( today.getDate() - dayOfWeek )

        let daysOfWeek = Array.from({ length: 7 }).map(( _, index ) => {
            const date = new Date( firstDayOfWeek )
            date.setDate( date.getDate() + index )
            return date
        })

        const columns = [
            {
                title: '',
                dataIndex: 'values',
                key: 'values',
            },
            ...daysOfWeek.map( day => {
                const dayKey = dayjs( day ).format( 'YYYY-MM-DD' )
                return {
                    title: dayjs( day ).format( 'DD.MM.YYYY | dd' ).toUpperCase(),
                    dataIndex: dayjs( day ).format( 'YYYY-MM-DD' ),
                    key: dayjs( day ).format( 'YYYY-MM-DD' ),
                    render: ( text: string | undefined, record: Record<string, string | null | undefined> ) => replaceDot( record[ dayKey ])
                }
            }),
            {
                title: 'Итого',
                dataIndex: 'summary',
                key: 'summary',
                render: ( number: number ) => replaceDot( number )
            },
        ]

        daysOfWeek = Array.from({ length: 8 }).map(( _, index ) => {
            const date = new Date( firstDayOfWeek )
            date.setDate( date.getDate() + index )
            return date
        })

        const dataSource = [
            {
                'values': 'SPMH',
                ...daysOfWeek.reduce(( acc: Record<string, string>, day ) => {
                    const dayKey = day.toISOString().split( 'T' )[ 0 ]
                    const dayData = data?.days?.find(( d: { date: string | undefined }) => dayjs( d.date ).format( 'YYYY-MM-DD' ) === dayKey )

                    acc[ dayKey ] = dayData ? dayData.planSpmh : null
                    return acc
                }, {}),
                'summary': data ? data.planSpmh : null,
            },
            {
                'values': 'TCPH',
                ...daysOfWeek.reduce(( acc: Record<string, string>, day ) => {
                    const dayKey = dayjs( day ).format( 'YYYY-MM-DD' )

                    const dayData = data?.days?.find(( d: { date: string | undefined }) => dayjs( d.date ).format( 'YYYY-MM-DD' ) === dayKey )

                    acc[ dayKey ] = dayData ? dayData.planTcph : null
                    return acc
                }, {}),
                'summary': data ? data.planTcph : null,
            },
        ]

        return { columns, dataSource }
    }

    const turnoverAverage = () => {
        return [
            {
                planTurnoverRgm: propData?.planTurnoverRgm ?? ' ',
                planAverageCheck: propData?.planAverageCheck ?? ' ',
            },
        ]
    }

    const onSelect = ( record: HidedEmployees ) => {
        deleteSchedulesEmployeesHided( record?.hidedEmployeeUuid ).then(() => {
            load()
        })
    }

    const hiddenEmployeesColumns = () => {
        return [
            column( 'Имя', 'employeeName', {
                as: 'cell',
                editable: true,
                width: 140,
            }),
            column( 'Позиция', 'positionName', {
                as: 'cell',
                width: 60,
            }),
            column( '', '', {
                as: 'cell',
                width: 20,

                render: ( record: HidedEmployees ) => {
                    return (
                        <button className="check-icon" onClick={() => onSelect( record )}>
                            <Icon component={Icons.Undo.active} onPointerEnterCapture onPointerLeaveCapture/>
                        </button>
                    )
                }
            }),
        ]
    }

    return (
        <div className="summary-report ant-tabs-tab">
            <h2>Сводное расписание
                <div className={'custom-tab-icon'}>
                    <Button
                        type='link'
                        className='button-download'
                        disabled={!propData}
                        onClick={() => window.print()}
                    >
                        <Icon component={Icons.Print.active} onPointerEnterCapture onPointerLeaveCapture/>
                    </Button>
                </div>
            </h2>
            <div className='summary-report-reverse-table'>
                <Table
                    columns={config.tables.schedule.summaryReport.turnoverAverage}
                    dataSource={turnoverAverage()}
                    pagination={false}
                    loading={!Object.keys( data ).length}
                    bordered
                />
                <Table
                    columns={spmhTcph( data ).columns}
                    dataSource={spmhTcph( data ).dataSource}
                    pagination={false}
                    loading={!Object.keys( data ).length}
                    bordered
                />
            </div>

            <div className='summary-report-middle print-report-sreport'>
                <Table
                    // @ts-ignore
                    columns={columns()}
                    dataSource={transformData( propData?.positionStations )}
                    bordered
                    pagination={false}
                    loading={!Object.keys( data ).length}
                    scroll={{ x: 3660 }}
                    rowKey='key'
                />
            </div>
            <div className='summary-report-bottom'>
                <h2>Скрытые сотрудники</h2>
                <Table
                    columns={hiddenEmployeesColumns()}
                    dataSource={propData?.hidedEmployees}
                    pagination={false}
                    loading={!Object.keys( data ).length}
                />
            </div>
        </div>
    )
}

export default ScheduleSummaryReport
