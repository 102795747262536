/* VENDOR */
import React, { Component }                                      from 'react'
import PropTypes                                                 from 'prop-types'
import { Modal, Input, Divider, List, Checkbox, Col, Row, Spin } from 'antd'
import { Scrollbars }                                            from 'react-custom-scrollbars'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'
import config      from 'config'

import './add-dish-modal.scss'

const { Search } = Input,
      itemCls = 'cat-list-dishes-item'

class AddDishModal extends Component {
    static propTypes = {
        data: PropTypes.object,
        selected: PropTypes.array,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        onSearch: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            dishes: [],
            selection: format.copy.array( props.selected ),
            search: '',
            fetching: false,
            onlySelected: false,
        }
    }

    componentWillUnmount () {
        this._watcher && clearInterval( this._watcher )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props

        if ( nextProps.visible ) {
            this.setState({ dishes: this.filter( nextProps.data ), })
            this._watcher = setInterval( this.watch, 100 )
        } else {
            this.setState({
                selection: format.copy.array( nextProps.selected ),
                search: '',
            })
            this._watcher && clearInterval( this._watcher )
        }

        if ( nextProps.data !== data ) {
            this.setState({
                dishes: this.filter( nextProps.data ),
                fetching: false,
            })
            this._watcher = setInterval( this.watch, 100 )
        }
    }

    watch = () => {
        const { fetching, search, onlySelected } = this.state,
              { data, visible } = this.props,
              container = document.querySelector( '.add-dish-list > div > div' )

        if ( !container || !visible || onlySelected || fetching || data.last ) { return }

        if (
            container.scrollHeight - container.clientHeight - container.scrollTop <
      200
        ) {
            this.props.onMore( search )
            this.setState({ fetching: true, })
        }
    }

    search = ( e ) => {
        const str = e.target.value

        if ( str.length >= 2 ) {
            this.props.onSearch( str )
            this.scrolls && this.scrolls.scrollToTop()
        } else if ( this.state.search.length > 1 ) {
            this.props.onSearch( '' )
        }

        this.setState({ search: str })
    }

    filter = ( data ) => ( data ? data.content : [])

    toggle = ( item ) => {
        return () => {
            const { selection } = this.state,
                  found = this.getAdded( item )

            found
                ? selection.splice( selection.indexOf( found ), 1 )
                : selection.push( item )

            this.setState({ selection })
        }
    }

    getAdded = ( item ) =>
        this.state.selection.find(( d ) => d && d.productUid === item.productUid )
    getSaved = ( item ) =>
        this.props.selected.find(( d ) => d && d.productUid === item.productUid )

    item = ( item ) => {
        const cls = [ itemCls ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Row>
                    <Col
                        span={18}
                        className={item.alternateProductName ? '' : 'no-alt'}
                    >
                        <Checkbox
                            checked={!!this.getAdded( item )}
                            disabled={!!this.getSaved( item )}
                            onChange={this.toggle( item )}
                        >
                            <span className="item-title">{item.name}</span>
                            {item.combo && <span className="combo-tag">КОМБО</span>}
                            <small>{item.alternateProductName}</small>
                        </Checkbox>
                    </Col>
                    <Col span={4}>{item.rkeeperCode}</Col>
                </Row>
            </List.Item>
        )
    }

    submit = () => this.props.onSubmit( this.state.selection.filter( item => !this.props.selected.some( d => d.productUid === item.productUid )))

    length = () => this.state.selection.length - this.props.selected.length

    addText = () => {
        const length = this.length()

        if ( length === 0 ) { return 'Нечего добавить' }

        return (
            'Добавить ' +
      format.strings.clearCount( length, [ 'блюдо', 'блюда', 'блюд' ])
        )
    }

    toggleOnlySelected = () =>
        this.setState({ onlySelected: !this.state.onlySelected, })

    render () {
        const { onlySelected, search, dishes, selection, fetching } = this.state,
              { data } = this.props

        return (
            <Modal
                className="kfc-popup kfc-add-dish"
                centered={true}
                open={this.props.visible}
                title="Добавление блюда"
                okText={this.addText()}
                onOk={this.submit}
                okButtonProps={{ disabled: this.length() < 1 }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.props.onCancel}
            >
                <div className="filter-container">
                    <Search
                        placeholder="Начните вводить название или код"
                        value={search}
                        disabled={onlySelected}
                        onChange={this.search}
                    />
                </div>

                <Divider />

                {data ? (
                    <div className="add-dish-list">
                        <Scrollbars
                            {...config.ui.scrolls}
                            ref={( node ) => ( this.scrolls = node )}
                        >
                            <List
                                size="small"
                                dataSource={
                                    onlySelected
                                        ? selection.filter(( item ) => !this.getSaved( item ))
                                        : dishes
                                }
                                renderItem={this.item}
                                locale={{ emptyText: 'Ничего не найдено' }}
                            />
                        </Scrollbars>
                        <div className="list-overspin">
                            <Checkbox
                                checked={onlySelected}
                                onClick={this.toggleOnlySelected}
                            >
                Только выбранные
                            </Checkbox>
                            {fetching && <Spin size="small" />}
                        </div>
                    </div>
                ) : (
                    <Spinner />
                )}
            </Modal>
        )
    }
}

export default AddDishModal
