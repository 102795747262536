import React, { useEffect, useState } from 'react'
import cn                             from 'classnames'
import { GuestVoteFilters }           from 'components'
import GuestVoteTable                 from '../../GuestVoteTable/GuestVoteTable'
import styles                         from './osat-tab.module.scss'
import { connect }                    from 'react-redux'
import { FormatedDateFilters }        from 'pages/GuestVote/types'
import { DataGroupType }              from 'services/guestVote/statistic/type'
import {
    allActions, ConsolidatedStatisticsStoreProps,
    mapStateToProps
} from './connector'
import config                             from 'config'
import { useGetCurrentDataGroup }         from '../helpers/hooks'
import OsatGauges                         from '../../OsatGauges/OsatGauges'
import dayjs                              from 'dayjs'
import { PickerType }                     from '../../GuestVoteControls/types'
import { OsatColumn }                     from '../../charts/OsatColumn/OsatColumn'
import Spinner                            from '../../../layout/Spinner/Spinner'
import { summaryOsatTableData }           from '../../GuestVoteTabs/helpers/prepareExcel'
import { flushMetricsSaleChannelsSearch } from '../../../../services/guestVote/osat'

type ConsolidatedStatisticsTabProps = {
    className?: string;
    dateFilters: FormatedDateFilters;
    disabledDropTypes?: DataGroupType[];
    picker: PickerType;
};

const OsatTab = ( props: ConsolidatedStatisticsTabProps & ConsolidatedStatisticsStoreProps ) => {
    const {
        className,
        currentFilters,
        dateFilters,
        disabledDropTypes,
        postMetricsSaleChannelsSummary,
        postMetricsSaleChannelsSearch,
        postMetricsSaleChannels,
        appendMetricsSaleChannelsSearch,
        picker,
        metricsSaleChannelsSummary,
        metricsSaleChannelsSummaryLoading,
        metricsSaleChannels,
        metricsSaleChannelsLoading,
        metricsSaleChannelsSearch,
        metricsSaleChannelsSearchLoading,
        metricsSaleChannelsSearchAppendLoading,
    } = props

    const [ isColumnVisible, setIsColumnVisible ] = useState<boolean>( false )
    const currentDataGroup = useGetCurrentDataGroup( disabledDropTypes )

    useEffect(() => {
        const dateStart = dayjs( dateFilters.dateStart )
        const dateEnd = dayjs( dateFilters.dateEnd )

        if ( picker === 'month' && dateEnd.diff( dateStart, 'month', true ) >= 1 ) {
            setIsColumnVisible( true )
            postMetricsSaleChannels({
                ...currentFilters,
                ...dateFilters,
                dataGroup: 'MONTH'
            })
        } else if ( picker === 'year' && dateEnd.diff( dateStart, 'year', true ) >= 1 ) {
            setIsColumnVisible( true )
            postMetricsSaleChannels({
                ...currentFilters,
                ...dateFilters,
                dataGroup: 'YEAR'
            })
        } else {
            setIsColumnVisible( false )
        }

        postMetricsSaleChannelsSummary({
            ...currentFilters,
            ...dateFilters
        })
        postMetricsSaleChannelsSearch({
            ...currentFilters,
            ...dateFilters,
            dataGroup: currentDataGroup
        })
    }, [ currentFilters, dateFilters ])

    const isTableLoading = Boolean( metricsSaleChannelsSummaryLoading ) || Boolean( metricsSaleChannelsSearchLoading )

    return (
        <div className={cn( className, styles.osat_tab )}>
            <GuestVoteFilters dateFilters={dateFilters} />

            {metricsSaleChannelsSummaryLoading
                ? <Spinner className={styles.spinner} />
                : <OsatGauges data={metricsSaleChannelsSummary} />
            }

            {metricsSaleChannelsLoading
                ? <Spinner className={styles.spinner} />
                : isColumnVisible && <OsatColumn data={metricsSaleChannels} dateFilters={dateFilters} />
            }

            <GuestVoteTable
                isFirstLineBold
                disabledDropTypes={disabledDropTypes}
                appendDataQuery={appendMetricsSaleChannelsSearch}
                flushContent={flushMetricsSaleChannelsSearch}
                rowKey={currentDataGroup === 'CITIES' ? 'name' : 'id'}
                currentFilters={currentFilters}
                dateFilters={dateFilters}
                postDataQuery={postMetricsSaleChannelsSearch}
                data={summaryOsatTableData( metricsSaleChannelsSummary, metricsSaleChannelsSearch, currentDataGroup )}
                columnsRender={config.tables.guestVote.getOsatTable}
                loading={isTableLoading}
                buttonLoading={metricsSaleChannelsSearchAppendLoading}
            />
        </div>
    )
}

export default connect( mapStateToProps, allActions )( OsatTab )
