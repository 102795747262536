import { dropTypesColumns, dropTypesKeys } from '../../GuestVoteTable/const'
import { DataGroupType }                   from 'services/guestVote/statistic/type'

export const getOsatExcelColumns = ( currentTypesFilter: DataGroupType ) => {
    const columns = [
        {
            title: `${dropTypesColumns[ currentTypesFilter ]}`,
            dataIndex: `${dropTypesKeys[ currentTypesFilter ]}`,
            key: `${dropTypesKeys[ currentTypesFilter ]}`,
            width: { wpx: 300 }
        },
        {
            title: 'C&C + Киоск',
            dataIndex: 'factOsatCcKiosk',
            key: 'factOsatCcKiosk',
            width: { wpx: 150 },
        },
        {
            title: 'C&C + Киоск прошлый год',
            dataIndex: 'factOsatCcKioskLastYear',
            key: 'factOsatCcKioskLastYear',
            width: { wpx: 150 }
        },
        {
            title: 'C&C + Киоск разница',
            dataIndex: 'deltaFactOsatCcKioskLastYear',
            key: 'deltaFactOsatCcKioskLastYear',
            width: { wpx: 150 }
        },
        {
            title: 'C&C',
            dataIndex: 'factOsat1',
            key: 'factOsat1',
            width: { wpx: 150 }
        },
        {
            title: 'C&C прошлый год',
            dataIndex: 'factOsatLastYear1',
            key: 'factOsatLastYear1',
            width: { wpx: 150 }
        },
        {
            title: 'C&C разница',
            dataIndex: 'deltaFactOsatLastYear1',
            key: 'deltaFactOsatLastYear1',
            width: { wpx: 150 }
        },
        {
            title: 'Киоск',
            dataIndex: 'factOsat5',
            key: 'factOsat5',
            width: { wpx: 150 }
        },
        {
            title: 'Киоск прошлый год',
            dataIndex: 'factOsatLastYear5',
            key: 'factOsatLastYear5',
            width: { wpx: 150 }
        },
        {
            title: 'Киоск разница',
            dataIndex: 'deltaFactOsatLastYear5',
            key: 'deltaFactOsatLastYear5',
            width: { wpx: 150 }
        },
        {
            title: 'Доставка',
            dataIndex: 'factOsat2',
            key: 'factOsat2',
            width: { wpx: 150 }
        },
        {
            title: 'Доставка прошлый год',
            dataIndex: 'factOsatLastYear2',
            key: 'factOsatLastYear2',
            width: { wpx: 150 }
        },
        {
            title: 'Доставка разница',
            dataIndex: 'deltaFactOsatLastYear2',
            key: 'deltaFactOsatLastYear2',
            width: { wpx: 150 }
        },
    ]

    if ( currentTypesFilter === 'RESTAURANTS' ) {
        columns.unshift({
            title: 'Код',
            dataIndex: 'id',
            key: 'id',
            width: { wpx: 100 }
        })
    }

    return columns
}
