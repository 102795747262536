import {  RatingFilters } from './hooks/useLoadRatingData'
import { TableGroupType } from '../Rating'

export const addFiltersToRequest = ( req: Record<string, any>, filters: RatingFilters ) => {
    if ( filters.partner.length ) {
        req.franchiseeGroupCodes = filters.partner
    }
    if ( filters.cities.length ) {
        req.cities = filters.cities
    }
    if ( filters.regionCoaches.length ) {
        req.regionCoachCodes = filters.regionCoaches
    }
    if ( filters.marketCoaches.length ) {
        req.marketCoachCodes = filters.marketCoaches
    }
    if ( filters.areaCoaches.length ) {
        req.areaCoachCodes = filters.areaCoaches
    }
    if ( filters.factsNumbers.length ) {
        req.factsNumbers = filters.factsNumbers
    }
    if ( filters.facilityTypes.length ) {
        req.facilityTypeIds = filters.facilityTypes
    }
}

export const getRequest = (
    filters: RatingFilters & {selected: number[],
    ratingTableGroup: TableGroupType},
    request: Record<string, any> ): Record<string, any> => ({
    ...request,
    restaurantId: null,
    restaurantIds:
        filters.selected?.length > 0 && filters.ratingTableGroup === 'RESTAURANTS'
            ? filters.selected.join( ',' )
            : null,
})
