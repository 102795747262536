import { Column, ColumnConfig }            from '@ant-design/plots'
import React                               from 'react'
import { FormatedDateFilters }             from 'pages/GuestVote/types'
import { PostMetricsSaleChannelsResponse } from 'services/guestVote/osat/type'
import dayjs                               from 'dayjs'
import styles                              from './osat-column.module.scss'

type DataItem = {
    name: string;
    date: string;
    count: number;
};

type OsatColumnProps = {
    data?: PostMetricsSaleChannelsResponse;
    columnWidth?: number;
    dateFilters: FormatedDateFilters;
} & Omit<Partial<ColumnConfig>, 'data'>

export const OsatColumn = React.memo(({ data, dateFilters, ...props }: OsatColumnProps ) => {
    if ( !data ) { return null }
    if ( data.length > 12 ) {
        let start = dayjs( dateFilters.dateStart )
        data.forEach(( item ) => {
            item.name = start.format( 'MMMM YYYY' )
            start = start.add( 1, 'month' )
        })
    }

    const transformedData = data.reduce<DataItem[]>(( acc, item ) => {
        const data = item?.saleChannels?.reduce<DataItem[]>(( dataAcc, saleChannel ) => {
            if ( saleChannel.factOsat ) {
                dataAcc.push({
                    date: item.name,
                    name: saleChannel.name,
                    count: saleChannel.factOsat
                })
            }
            return dataAcc
        }, [])
        return [ ...acc, ...( data ? data : []) ]
    }, [])

    if ( transformedData.length === 0 ) { return null }

    return (
        <div className={styles.column}>
            <div style={{ width: `${data.length * 250}px`, minWidth: 150 }}>
                <Column
                    isGroup
                    autoFit
                    maxColumnWidth={45}
                    minColumnWidth={45}
                    xField="date"
                    data={transformedData}
                    yField="count"
                    seriesField="name"
                    meta={{ count: { formatter: ( value: number ) => value + '%' }, }}
                    color={[ '#3aa8bf', '#165f99', '#AFA8E6', '#9c7fc8', '#6C8FCD', '#99C3D8', '#D3ABD2', '#C28DD0', '#6E61B4', '#1476A3', '#AA4A8A', '#7A2476', '#8F0062', '#003675', '#003F51' ]}
                    label={{
                        position: 'middle',
                        style: { fill: '#ffffff' },
                        layout: [
                            { type: 'interval-adjust-position' },
                            { type: 'interval-hide-overlap' }
                        ]
                    }}
                    {...props}
                />
            </div>
        </div>
    )
}
)
