const serviceTimeKeys = {
    fusion: 'fusion',
    hybrid: 'hybrid',
    auto: 'auto'
}

type TimeItemType = { all?: any, report?: any } | null
type TimeType = { fusion: TimeItemType, hybrid: TimeItemType, auto: TimeItemType }

export const formatTimeData = ( time: any ): TimeType => {
    const fusion: TimeItemType = {},
          hybrid: TimeItemType = {},
          auto: TimeItemType = {},
          af = time.all && time.all.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.fusion
          ),
          ah = time.all && time.all.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.hybrid
          ),
          aa = time.all && time.all.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.auto
          ),
          rf = time.report && time.report.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.fusion
          ),
          rh = time.report && time.report.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.hybrid
          ),
          ra = time.report && time.report.find(
              ( r: any ) => r.restaurantType.toLowerCase() === serviceTimeKeys.auto
          )

    fusion.all = af
    fusion.report = rf && rf.rating

    hybrid.all = ah
    hybrid.report = rh && rh.rating

    auto.all = aa
    auto.report = ra && ra.rating

    return { fusion, auto, hybrid }
}
