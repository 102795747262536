import { format }                  from 'tools'
import { allRowName, averageName } from 'components/rating/GroupSwitch/const'
import { TableGroupType }          from '../Rating'

export const showExcelTooltipTurnover = ( turnover: any, ratingTableGroup: TableGroupType ) => {
    if ( !turnover?.report?.content || turnover?.report?.content?.length === 0 ) {
        return true
    }

    return format.check.allContainsNullOrZero([
        {
            name: `${allRowName[ ratingTableGroup ]}`,
            ...turnover.all
        },
        ...turnover.report.content
    ], [ 'factTurnover' ])
}

export const showExcelTooltipAverage = ( average: any, ratingTableGroup: TableGroupType ) => {
    if ( !average?.report?.content || average?.report?.content?.length === 0 || !average?.all ) {
        return true
    }

    return format.check.allContainsNullOrZero([
        {
            name: `${averageName[ ratingTableGroup ]}`,
            ...average.all
        },
        ...average.report.content
    ], [ 'factAverageCheck', 'planAverageCheck' ])
}

export const showExcelTooltipPerformance = ( performance: any, ratingTableGroup: TableGroupType ) => {
    if ( !performance?.report?.content || performance?.report?.content?.length === 0 ) {
        return true
    }

    const fields = [ 'factSpmh', 'planSpmh', 'factTcph', 'planTcph', 'factValue', 'planValue', 'factTransactions', 'factProductiveHours', 'planProductiveHours' ]

    return format.check.allContainsNullOrZero([
        {
            name: `${allRowName[ ratingTableGroup ]}`,
            ...performance.all
        },
        ...performance.report.content
    ], fields )
}

export const showExcelTooltipCategories = ( categories: any ) => {
    if ( !categories?.list || categories?.list?.length === 0 ) {
        return true
    }

    return format.check.allContainsNullOrZero([
        {
            categoryName: 'Все категории блюд',
            ...categories.list.summary,
        },
        categories.list.elements ], [ 'factCategoryRating', 'planCategoryRating', 'deltaCategoryRating' ])
}

export const showExcelTooltipTime = ( time: any ) => {
    if ( !time || !time.all || !time.report || time.report.length === 0 ) {
        return true
    }

    return format.check.allContainsNullOrZero( time.all, [ 'deltaServiceTime', 'factServiceTime', 'hit' ]) &&
        [
            ...time.report.map(
                ( report: any ) => format.check.allContainsNullOrZero( report.rating.content, [ 'deltaTurnover', 'factServiceTime', 'hit' ])
            )
        ]?.every( check => check )
}
