/* VENDOR */
import React, { useState, useEffect } from 'react'
import { connect }                    from 'react-redux'
import { Modal, Progress }            from 'antd'
import { IState }                     from 'store/type'

/* APPLICATION */
import { format } from 'tools'
import './generate-progress.scss'

interface PropTypes {
  active: boolean
  received: boolean
  error: any
  state: IState
  onReady: () => void
  onDone: () => void
}

interface Step {
  stop: number
  speed: number
  message: string
}

let timer: number | null = null
const steps: Step[] = [
    {
        stop: 10,
        speed: 0.5,
        message: 'Ищем настройки для рабочих дней ресторана на выбранной неделе',
    },
    {
        stop: 20,
        speed: 1,
        message: 'Выбираем лучшую продолжительность смены',
    },
    {
        stop: 30,
        speed: 2,
        message: 'Извлекаем плановые значения товарооборота',
    },
    {
        stop: 40,
        speed: 1,
        message: 'Извлекаем параметры manning-чарта',
    },
    {
        stop: 50,
        speed: 0.3,
        message: 'Определяем требуемые позиции',
    },
    {
        stop: 65,
        speed: 0.2,
        message: 'Ищем подходящих сотрудников на требуемые позиции',
    },
    {
        stop: 80,
        speed: 0.5,
        message: 'Распределяем сотрудников по позициям',
    },
    {
        stop: 90,
        speed: 2,
        message: 'Применяем параметры фиксированных смен',
    },
    {
        stop: 99.9,
        speed: 0.2,
        message: 'Сохраняем результаты',
    },
    {
        stop: 100,
        speed: 1,
        message: 'УРА! Получилось!',
    },
]

const GenerateProgress = ( props: PropTypes ) => {
    const { active, received, error, state, onReady, onDone } = props

    const [ step, setStep ] = useState<number>( 0 )
    const [ percent, setPercent ] = useState<number>( 0 )
    const [ ready, setReady ] = useState<boolean>( false )

    useEffect(() => {
        update( active )
        return () => stop()
    })

    const update = ( status: boolean ) => ( status ? start() : stop())

    const current = (): Step => {
        const res = steps[ step ]

        if ( res.stop < 100 ) {
            return res
        }

        if ( !received && !error ) {
            res.message = 'Сохраняем результаты'
        } else if ( error ) {
            res.message = format.update.getErrorMessage( error, state )
        }

        return res
    }

    const getStep = ( percent: number ): number => {
        for ( let i = 0; i < steps.length; i++ ) {
            if ( percent < steps[ i ].stop ) {
                return i
            }
        }
        return steps.length - 1
    }

    const changeSteps = () => {
        const info = current()
        const newPercent = Math.min( 100, percent + info.speed )
        const newStep = getStep( newPercent )

        setStep( newStep )
        setPercent( newPercent )

        if ( newStep === 8 && !ready ) {
            setReady( true )
            onReady()
        }

        newPercent >= 100 && stop()
    }

    const start = () => {
        timer = window.setInterval( changeSteps, Math.round( Math.random() * 30 ) + 30 )
    }

    const stop = () => {
        if ( timer !== null ) {
            window.clearInterval( timer )
            timer = null
        }
    }

    const message = () => (
        <span className="progress-message">
            {current().message}
            <aside>{step + 1 + ' / ' + steps.length}</aside>
        </span>
    )

    const handleDone = () => {
        setTimeout(() => {
            setStep( 0 )
            setPercent( 0 )
            setReady( false )
        }, 1000 )
        onDone()
    }

    const cls = [ 'kfc-popup kfc-generate-progress' ]
    !!error && cls.push( 'error' )

    return (
        <Modal
            title="Формирование расписания"
            className={cls.join( ' ' )}
            closable={false}
            centered={true}
            open={active}
            okText={error ? 'Закрыть' : 'Посмотреть'}
            okButtonProps={{
                className: 'wide-btn',
                style: { display: ( percent < 100 || !received ) && !error ? 'none' : 'inherit' },
            }}
            onOk={handleDone}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Progress percent={percent} strokeColor="#8FC2FF" showInfo={false} />
            {message()}
        </Modal>
    )
}

const mapStateToProps = ( state: IState ) => ({ state })

export default connect( mapStateToProps )( GenerateProgress )
