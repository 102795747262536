// @ts-ignore
import ReactExport             from 'react-data-export/dist'
import { Button, Card, Tabs }  from 'antd'
import React                   from 'react'
import dayjs                   from 'dayjs'
import Icon                    from '@ant-design/icons'
import {
    showExcelTooltipAverage, showExcelTooltipCategories,
    showExcelTooltipPerformance, showExcelTooltipTime,
    showExcelTooltipTurnover
} from 'pages/Rating/helpers/showExcelTooltip'
import { format }          from 'tools'
import { connect }         from 'react-redux'
import {
    prepareExcelAverage, prepareExcelCategories,
    prepareExcelPerformance, prepareExcelTime,
    prepareExcelTurnover
} from 'pages/Rating/helpers/prepareExcel'
import {
    AverageCheckRating, CategoriesRating,
    CustomTab, Icons,
    InnerContent, PerformanceRating,
    ReportExcelTooltip, TimeRating, TurnoverRating
} from 'components'
import {
    RatingTabsStoreProps,
    allActions,
    mapStateToProps
} from './connector'
import { RatingFilters }               from 'pages/Rating/helpers/hooks/useLoadRatingData'
import { formatTimeData }              from './helpers/utils'
import { FilterNames, TableGroupType } from 'pages/Rating/Rating'
import { RatingSorter }                from '../../../pages/Rating/helpers/hooks/useRatingSorter'

const ExcelFile = ReactExport.ExcelFile

const tabs = [ {
    key: 'turnover',
    title: 'Товарооборот'
}, {
    key: 'average',
    title: 'Средний чек'
}, {
    key: 'performance',
    title: 'Производительность'
}, {
    key: 'time',
    title: 'Время обслуживания'
}, {
    key: 'categories',
    title: 'Категории блюд'
} ]

type RatingTabsProps = {
    ratingTableGroup: TableGroupType
    changeRatingTableGroup: ( ratingTableGroup: TableGroupType ) => void,
    setView: ( view: string ) => void,
    view: string,
    loadTrigger: () => void,
    ratingFilters: RatingFilters,
    onSelect: ( selected: number[]) => void,
    onFocus: () => void,
    appendQuery: ( service: 'average' | 'turnover' | 'performance' | 'serviceTime' ) => ( params: Record<string, any> ) => void,
    setFilter: ( filter: {name: FilterNames, value: number[]}) => void,
    flushFilters: () => void,
    showFlushFiltersButton: boolean,
    onCategory: ( raw: Record<string, any> ) => void,
    onManger: ( raw: Record<string, any> ) => void,
    setSorter: ( sorter: RatingSorter ) => void
}

const RatingTabs = ( props: RatingTabsProps & RatingTabsStoreProps ) => {
    const prepareExcel = ( tab: { key: string, title: string }) => {
        const { request, turnover, categories, average, performance, time, ratingTableGroup } = props
        const currency = format.extract.currency( request.unitOfMeasure )

        switch ( tab.key ) {
            case 'turnover': {
                return prepareExcelTurnover( turnover, ratingTableGroup, currency, tab.title )
            }
            case 'average': {
                return prepareExcelAverage( average, ratingTableGroup, currency, tab.title )
            }
            case 'performance': {
                return prepareExcelPerformance( performance, ratingTableGroup, currency, tab.title )
            }
            case 'categories': {
                return prepareExcelCategories( categories, tab.title )
            }
            case 'time': {
                return prepareExcelTime( time, ratingTableGroup, tab.title )
            }
        }

        return []
    }

    const showExcelTooltip = ( tab: { key: string, title: string }) => {
        const { turnover, categories, average, performance, time } = props
        const { ratingTableGroup } = props

        switch ( tab.key ) {
            case 'turnover': {
                return showExcelTooltipTurnover( turnover, ratingTableGroup )
            }
            case 'average': {
                return showExcelTooltipAverage( average, ratingTableGroup )
            }
            case 'performance': {
                return showExcelTooltipPerformance( performance, ratingTableGroup )
            }
            case 'categories': {
                return showExcelTooltipCategories( categories )
            }
            case 'time': {
                return showExcelTooltipTime( time )
            }
        }
    }

    const handleSort = ( tab: string ) => {
        return ( field: string, order?: 'ascend' | 'descend' ) => {
            props.setSorter({ tab, field, order })
        }
    }

    const getTab = ( key: string ) => {
        const {
            partner,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            factsNumbers,
            facilityTypes,
            manager,
            category,
            selected
        } = props.ratingFilters
        const { request, turnover, categories, filters, user, average, performance, time, setFilter, flushFilters, showFlushFiltersButton } = props
        const currency = format.extract.currency( request.unitOfMeasure )
        const formatedTime = formatTimeData( time )

        const commonProps = {
            city: cities,
            partner: partner,
            regionCoach: regionCoaches,
            marketCoach: marketCoaches,
            factsNumbers: factsNumbers,
            facilityType: facilityTypes,
            areaCoach: areaCoaches,
            partners: format.generate.options(
                filters?.franchiseeGroups,
                'code',
                'name'
            ),
            facilityTypes: format.generate.options(
                filters?.facilityTypes,
                'id',
                'name'
            ),
            cities: format.generate.optionsStr(
                filters?.cities
            ),
            regionCoaches: format.generate.options(
                filters?.regionCoaches,
                'code',
                'name'
            ),
            marketCoaches: format.generate.options(
                filters?.marketCoaches,
                'code',
                'name'
            ),
            areaCoaches: format.generate.options(
                filters?.areaCoaches,
                'code',
                'name'
            )
        }

        switch ( key ) {
            case 'turnover':
                return <TurnoverRating
                    {...commonProps}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    currency={currency}
                    data={turnover}
                    request={request}
                    selected={selected}
                    ratingTableGroup={props.ratingTableGroup}
                    changeRatingTableGroup={props.changeRatingTableGroup}
                    onControls={props.setRequest}
                    onSelect={props.onSelect}
                    onMore={props.appendQuery( 'turnover' )}
                    onSort={handleSort( key )}
                    onPartner={( value: number[]) => setFilter({ name: 'partner', value })}
                    onFilter={setFilter}
                    flushFilters={flushFilters}
                    showFlushFiltersButton={showFlushFiltersButton}
                    onFocus={props.onFocus}
                />
            case 'average':
                return <AverageCheckRating
                    {...commonProps}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    ratingTableGroup={props.ratingTableGroup}
                    changeRatingTableGroup={props.changeRatingTableGroup}
                    data={average}
                    onControls={props.setRequest}
                    onSelect={props.onSelect}
                    onMore={props.appendQuery( 'average' )}
                    onSort={handleSort( key )}
                    onPartner={( value: number[]) => setFilter({ name: 'partner', value })}
                    onFilter={setFilter}
                    flushFilters={flushFilters}
                    showFlushFiltersButton={showFlushFiltersButton}
                    onFocus={props.onFocus}
                />
            case 'performance':
                return <PerformanceRating
                    {...commonProps}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    currency={currency}
                    request={request}
                    selected={selected}
                    data={performance}
                    ratingTableGroup={props.ratingTableGroup}
                    changeRatingTableGroup={props.changeRatingTableGroup}
                    onControls={props.setRequest}
                    onSelect={props.onSelect}
                    onSort={handleSort( key )}
                    onPartner={( value: number[]) => setFilter({ name: 'partner', value })}
                    onFilter={setFilter}
                    flushFilters={flushFilters}
                    showFlushFiltersButton={showFlushFiltersButton}
                    onMore={props.appendQuery( 'performance' )}
                    onFocus={props.onFocus}
                />
            case 'time':
                return <TimeRating
                    {...commonProps}
                    restaurants={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    data={formatedTime}
                    fusion={formatedTime.fusion}
                    hybrid={formatedTime.hybrid}
                    auto={formatedTime.auto}
                    ratingTableGroup={props.ratingTableGroup}
                    changeRatingTableGroup={props.changeRatingTableGroup}
                    onControls={props.setRequest}
                    onSelect={props.onSelect}
                    onPartner={( value: number[]) => setFilter({ name: 'partner', value })}
                    onFilter={setFilter}
                    flushFilters={flushFilters}
                    showFlushFiltersButton={showFlushFiltersButton}
                    onMore={props.appendQuery( 'serviceTime' )}
                    onFocus={props.onFocus}
                />
            case 'categories':
                return <CategoriesRating
                    {...commonProps}
                    rests={format.generate.options(
                        filters?.restaurants,
                        'factsNumber',
                        'restaurantName'
                    )}
                    category={category}
                    manager={manager}
                    user={user}
                    restaurants={categories.restaurants}
                    categories={categories.list}
                    managers={categories.managers}
                    ratingTableGroup={props.ratingTableGroup}
                    changeRatingTableGroup={props.changeRatingTableGroup}
                    onSelect={props.onSelect}
                    onCategory={props.onCategory}
                    onManager={props.onManger}
                    onUpdate={props.updateCategoryRating}
                    onPartner={( value: number[]) => setFilter({ name: 'partner', value })}
                    onFilter={setFilter}
                    flushFilters={flushFilters}
                    showFlushFiltersButton={showFlushFiltersButton}
                    onControls={props.setRequest}
                    onFocus={props.onFocus}

                />
            case 'rating':
            default:
                // return <OverallRating
                //     data={overall}
                //     ratingTableGroup={props.ratingTableGroup}
                //     changeRatingTableGroup={props.changeRatingTableGroup}
                //     fetchData={this.loadOverall}
                //     update={this.updateOverall}
                // />
        }
    }

    const getTabItems = () => {
        return tabs.map( tab => {
            const disabledExcel = showExcelTooltip( tab )
            return {
                key: tab.key,
                children: getTab( tab.key ),
                label: <CustomTab
                    key={tab.key}
                    title={tab.title}
                    icon={
                        tab.key === 'rating' ? false :
                            disabledExcel ?
                                    <ReportExcelTooltip />
                                :
                                    <ExcelFile
                                        filename={`${tab.title}_${dayjs().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                        element={
                                            <Button
                                                type="link"
                                                className="button-download"
                                                disabled={props.view !== tab.key}
                                            >
                                                {/*@ts-ignore*/}
                                                <Icon component={Icons.Download.def} />
                                            </Button>
                                        }
                                    >
                                        {prepareExcel( tab )}
                                    </ExcelFile>
                    }
                />
            }
        })
    }


    return (
        <InnerContent fixed={props.view === 'categories'}>
            <Card bordered={false}>
                <Tabs
                    destroyInactiveTabPane
                    className="extended-tabs"
                    animated={false}
                    onChange={props.setView}
                    activeKey={props.view}
                    items={getTabItems()}
                >
                </Tabs>
            </Card>
        </InnerContent>
    )
}

export default connect( mapStateToProps, allActions )( RatingTabs )
