import { IState } from 'store'

export const mapStateToProps = ( state: IState ) => ({
    dataGroup: state.guestVote.dataGroup,

    happinessIndexesSearch: state.guestVote.happinessIndexesSearch,
    happinessIndexesSearchIsLoading: state.guestVote.happinessIndexesSearch_loading,

    happinessIndexesSummary: state.guestVote.happinessIndexesSummary,
    happinessIndexesSummaryIsLoading: state.guestVote.happinessIndexesSummary_loading,

    feedbacksSearch: state.guestVote.feedbacksSearch,
    feedbacksSearchIsLoading: state.guestVote.feedbacksSearch_loading,

    metricsSaleChannelsSearch: state.guestVote.metricsSaleChannelsSearch,
    metricsSaleChannelsSearchLoading: state.guestVote.metricsSaleChannels_loading,

    metricsSaleChannelsSummary: state.guestVote.metricsSaleChannelsSummary,
    metricsSaleChannelsSummaryLoading: state.guestVote.metricsSaleChannelsSummary_loading
})


export type GustVoteTabsStoreProps = ReturnType<typeof mapStateToProps>

