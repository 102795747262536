//* VENDOR */
import React, { useState, useEffect, useRef } from 'react'
import { Form }                               from '@ant-design/compatible'
import type { FormComponentProps }            from '@ant-design/compatible/lib/form'
import { Modal, Button }                      from 'antd'
import dayjs, { Dayjs }                       from 'dayjs'


/* APPLICATION */
import { FormField, Spinner } from 'components'
import config                 from 'config'
import { format }             from 'tools'

import './schedule-turnover.scss'

/* TYPES */

interface PropTypes extends FormComponentProps {
  date: string
  turnover: TurnoverItem[]
  visible: boolean
  loading: boolean
  error: boolean
  getTurnover: ( body: { dateStart: string }) => void
  onSubmit: ( data: { date: string; turnover: number }[]) => void
  onCancel: () => void
  addServerError: ( error: { code: number; text: string }) => void
}

interface TurnoverItem {
  workStart: string
  turnover: number
  turnoverRgm?: number
  deltaRgm?: number
}

interface FormFieldConfig {
  field: string
  type: string
  value?: Dayjs
  label?: string
  addOn?: string
}

interface TurnoverDiff {
  [key: string]: TurnoverDay
}

interface TurnoverDay {
  date: string
  diff: number
  mainTurnover: number
  rgmTurnover: number
}

const ScheduleTurnover = ( props: PropTypes ) => {
    const { form, date, turnover, visible, loading, error, getTurnover, onSubmit, onCancel, addServerError } = props

    const [ turnoverDiff, setTurnoverDiff ] = useState<TurnoverDiff>({})
    const [ useDate, setUseDate ] = useState<string | null>( null )

    const prevLoadingRef = useRef<boolean | undefined>()

    const load = ( date: string | null ): void => {
        if ( !date ) { return }
        const body = { dateStart: date }
        getTurnover( body )
    }

    const fields = (): FormFieldConfig[] => {
        const fields: FormFieldConfig[] = [
            {
                field: 'date',
                type: 'hidden',
                value: dayjs( date ).startOf( 'week' ),
            },
        ]

        let day: Dayjs

        if ( date ) {
            for ( let i = 0; i < 7; i++ ) {
                day = dayjs( date ).add( i, 'days' )

                fields.push({
                    field: `day-${i}-row`,
                    label: day.format( 'D MMMM, ' ) + day.format( 'ddd' ).toUpperCase(),
                    type: 'number',
                    addOn: '₽',
                })
            }
        }

        fields.push({
            field: 'day-total',
            label: 'Общий',
            type: 'number',
            addOn: '₽',
        })

        return fields
    }

    const getValue = ( key: string ): TurnoverItem | null => {
        if ( !turnover ) { return null }

        if ( key === 'day-total' ) {
            let total = 0
            let totalDeltaRgm = 0
            let totalRgm = 0

            for ( const key in turnoverDiff ) {
                total += turnoverDiff[ key ].mainTurnover
                totalDeltaRgm += turnoverDiff[ key ].diff
                totalRgm += turnoverDiff[ key ].rgmTurnover
            }

            return {
                workStart: '',
                turnover: total,
                turnoverRgm: totalRgm,
                deltaRgm: totalDeltaRgm,
            }
        }

        const found = turnover.find(
            ( d: TurnoverItem ) =>
                dayjs( d.workStart ).weekday() ===
        parseInt( key.replace( 'day-', '' )) + 1
        )

        return found || null
    }

    const changeTurnover = ( field: string, value: number ) => {
        setTurnoverDiff( prev => ({
            ...prev,
            [ field ]: {
                ...prev[ field ],
                diff: value - prev[ field ].mainTurnover,
                rgmTurnover: value,
            }
        }))
    }

    const field = ( cfg: FormFieldConfig ) => {
        const item = getValue( cfg.field )
        if ( !item ) { return null }

        if ( cfg.field === 'date' ) { return headerField( cfg.field ) }

        if ( !item.turnover && item.turnover !== 0 ) { return null }

        const delta =
      cfg.field === 'day-total'
          ? item.deltaRgm || 0
          : turnoverDiff[ cfg.field ]?.diff || 0

        return (
            <div key={cfg.field} className="item-turnover">
                <div className="date-turnover">{cfg.label}</div>
                <div className="main-turnover">
                    <FormField
                        {...cfg}
                        field={cfg.field + 'disabled'}
                        label={''}
                        value={turnoverDiff[ cfg.field ]?.mainTurnover || item.turnover}
                        form={form}
                        disabled
                    />
                </div>
                <div className="forecast-turnover">
                    <FormField
                        {...cfg}
                        rules={[]}
                        label={''}
                        value={
                            item.turnoverRgm !== 0 || cfg.field === 'day-total'
                                ? item.turnoverRgm || 0
                                : item.turnover
                        }
                        form={form}
                        disabled={
                            ( cfg.field === 'day-total' ? true : false ) || item.turnover === 0
                        }
                        onChange={changeTurnover}
                    />
                </div>
                <div className="difference-turnover">
                    {format.strings.thousand( delta > 0 ? '+' + delta : delta )}
                </div>
            </div>
        )
    }

    const headerField = ( key: string ) => {
        return (
            <div key={key} className="item-turnover">
                <div className="date-turnover"></div>
                <div className="main-turnover">
                    <p>План значение,</p>
                    <p>Основное ТО</p>
                </div>
                <div className="forecast-turnover">
                    <p>План значение,</p>
                    <p>Прогноз директора</p>
                </div>
                <div className="difference-turnover">Разница</div>
            </div>
        )
    }

    useEffect(() => {
        const oldDate = date ? dayjs( date ).startOf( 'week' ).format( config.format.dayAPI ) : null

        if ( useDate !== oldDate ) {
            setUseDate( oldDate )
            load( oldDate )
        }

        if ( Object.keys( turnoverDiff ).length === 0 && turnover ) {
            const newObj: TurnoverDiff = {}
            turnover.forEach(( item: TurnoverItem, i: number ) => {
                newObj[ `day-${i}-row` ] = {
                    date: dayjs( item.workStart ).format( config.format.dayAPI ),
                    diff: item.turnoverRgm === 0 ? 0 : item.deltaRgm || 0,
                    mainTurnover: item.turnover,
                    rgmTurnover: item.turnoverRgm || item.turnover,
                }
            })
            setTurnoverDiff( newObj )
        }

        if ( !loading && prevLoadingRef.current ) {
            form.resetFields()
            setTurnoverDiff({})
        }

        prevLoadingRef.current = loading
    }, [ date, turnover, loading, form, turnoverDiff, useDate ])

    const preSubmit = () => {
        form.validateFieldsAndScroll(( err: any ) => {
            if ( !err ) {
                const newArr: Array<{ date: string; turnover: number }> = []
                for ( const key in turnoverDiff ) {
                    if (
                        turnoverDiff[ key ].rgmTurnover < 24 &&
            turnoverDiff[ key ].mainTurnover === 0
                    ) { continue }
                    newArr.push({
                        date: turnoverDiff[ key ].date,
                        turnover:
              turnoverDiff[ key ].rgmTurnover || turnoverDiff[ key ].mainTurnover,
                    })
                }
                if ( newArr.length === 0 ) {
                    addServerError({
                        code: 404006,
                        text: 'Невозможно сформировать расписание без планового ТО',
                    })
                    preCancel()
                } else {
                    onSubmit( newArr )
                }
            }
        })
    }

    const preCancel = () => {
        onCancel()
        form.resetFields()
        setTurnoverDiff({})
    }

    const layout = {
        ...config.ui.wideForm,
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    return (
        <Modal
            title="Товарооборот на неделю"
            className="kfc-popup wide kfc-schedule-turnover"
            centered={true}
            open={visible}
            onOk={preSubmit}
            onCancel={preCancel}
            footer={[
                <div className="footer" key="buttons">
                    {turnover && (
                        <div className="footer-buttons">
                            {error && !loading && (
                                <p>Превышено время ожидания. Попробуйте снова.</p>
                            )}
                            <div className="buttons">
                                {loading && (
                                    <div className="buttons-error">
                                        <Spinner />
                                    </div>
                                )}
                                <Button
                                    key="submit"
                                    type="primary"
                                    disabled={loading}
                                    onClick={preSubmit}
                                >
                  Сохранить и продолжить
                                </Button>
                                <Button key="back" onClick={preCancel}>
                  Отмена
                                </Button>
                            </div>
                        </div>
                    )}
                </div>,
            ]}
        >
            <div className="info-text">
                <p>расписание будет составлено с учётом прогноза директора,</p>
                <p>
          но целевой план заданный ТУ останется во всех показателях и отчётах.
                </p>
            </div>
            {turnover ? (
                <Form {...layout}>
                    <div className="table-turnover">
                        {fields().map( field )}
                    </div>
                </Form>
            ) : error ? (
                <div className="turnover-error">
                    <p>Превышено время ожидания. Попробуйте снова.</p>
                    <Button onClick={() => load( useDate )}>
            Обновить
                    </Button>
                </div>
            ) : (
                <Spinner />
            )}
        </Modal>
    )
}

export default Form.create({ name: 'ScheduleTurnover' })( ScheduleTurnover )
