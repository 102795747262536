import React, { useEffect, useState }                           from 'react'
import cn                                                       from 'classnames'
import { GuestVoteFilters, Spinner }                            from 'components'
import { GuestVoteColumn }                                      from '../../charts'
import HappinessGauges                                          from '../../HappinessGauges/HappinessGauges'
import GuestVoteTable                                           from '../../GuestVoteTable/GuestVoteTable'
import styles                                                   from './statistics-tab.module.scss'
import { connect }                                              from 'react-redux'
import { FormatedDateFilters }                                  from 'pages/GuestVote/types'
import { DataGroupType, PostMetricsSearchResponse }             from 'services/guestVote/statistic/type'
import { format }                                               from 'tools'
import { dropTypesFilter }                                      from '../../GuestVoteTable/const'
import { StatisticsTabStoreProps, allActions, mapStateToProps } from './connector'
import { PickerType }                                           from '../../GuestVoteControls/types'
import config                                                   from 'config'
import GuestVoteMetricsTypeSwitcher                             from '../../GuestVoteMetricsTypeSwitcher/GuestVoteMetricsTypeSwitcher'
import { useGetCurrentDataGroup }                               from '../helpers/hooks'
import dayjs                                                    from 'dayjs'

type StatisticsTabProps = {
    className?: string;
    dateFilters: FormatedDateFilters
    picker: PickerType
    disabledDropTypes?: DataGroupType[]
}

const StatisticsTab = ( props: StatisticsTabProps & StatisticsTabStoreProps ) => {
    const {
        className,
        currentFilters,
        postMetricsSummary,
        happinessIndexesSummary,
        happinessIndexesSummaryIsLoading,
        dateFilters,
        picker,
        postMetricsSearch,
        appendHappinessIndexesSearch,
        happinessIndexesSearchAppendIsLoading,
        happinessIndexesSearch,
        happinessIndexesSearchIsLoading,
        postMetricsByHour,
        postMetrics,
        happinessIndexes,
        happinessIndexesIsLoading,
        metricsType,
        disabledDropTypes,
        flushMetricsSearch
    } = props
    const [ isColumnVisible, setIsColumnVisible ] = useState<boolean>( false )

    const currentDataGroup = useGetCurrentDataGroup( disabledDropTypes )

    // Эффект, отвечающий за запрос данных для спидометров и таблицы
    useEffect(() => {
        postMetricsSummary({
            ...currentFilters,
            ...dateFilters
        })
        postMetricsSearch({
            ...currentFilters,
            ...dateFilters,
            dataGroup: currentDataGroup,
        })
    }, [ currentFilters, dateFilters ])

    // Эффект, отвечающий за видимость графика и запрос данных для него
    useEffect(() => {
        const dateStart = dayjs( dateFilters.dateStart )
        const dateEnd = dayjs( dateFilters.dateEnd )
        postMetricsByHour({
            ...currentFilters,
            ...dateFilters,
            searchMetric: metricsType
        })

        if ( picker === 'month' && dateEnd.diff( dateStart, 'month', true ) >= 1 ) {
            setIsColumnVisible( true )
            // @ts-ignore
            if ( metricsType !== 'OSAT' || metricsType !== 'RATING' ) {
                postMetrics({
                    ...currentFilters,
                    ...dateFilters,
                    dataGroup: 'MONTH',
                    searchMetric: metricsType
                })
            }
        } else if ( picker === 'year' && dateEnd.diff( dateStart, 'year', true ) >= 1 ) {
            setIsColumnVisible( true )
            // @ts-ignore
            if ( metricsType !== 'OSAT' || metricsType !== 'RATING' ) {
                postMetrics({
                    ...currentFilters,
                    ...dateFilters,
                    dataGroup: 'YEAR',
                    searchMetric: metricsType
                })
            }
        } else {
            setIsColumnVisible( false )
        }
    }, [ dateFilters, currentFilters, metricsType ])

    const summaryTableData = () => {
        const newMetricsSummary = format.copy.object( happinessIndexesSummary )
        const newMetricsSearch = format.copy.object( happinessIndexesSearch ) as PostMetricsSearchResponse

        newMetricsSummary._alwaysOnTop = true
        newMetricsSummary.name = `Все ${dropTypesFilter[ currentDataGroup ]}`
        newMetricsSearch.content = [ newMetricsSummary, ...newMetricsSearch.content ]

        return newMetricsSearch
    }
    const loading = Boolean( happinessIndexesSummaryIsLoading ) || Boolean( happinessIndexesSearchIsLoading )

    return (
        <div
            className={cn( className, styles.statistics_tab )}
        >
            <GuestVoteFilters dateFilters={dateFilters}/>
            <GuestVoteMetricsTypeSwitcher/>
            <HappinessGauges/>
            {happinessIndexesIsLoading ? <Spinner/> : isColumnVisible &&
          <GuestVoteColumn dateFilters={dateFilters} data={happinessIndexes} metricsType={metricsType}/>}
            <GuestVoteTable
                isFirstLineBold={true}
                flushContent={flushMetricsSearch}
                disabledDropTypes={disabledDropTypes}
                appendDataQuery={appendHappinessIndexesSearch}
                rowKey={currentDataGroup === 'CITIES' ? 'name' : 'id'}
                currentFilters={currentFilters}
                dateFilters={dateFilters}
                postDataQuery={postMetricsSearch}
                data={summaryTableData()}
                columnsRender={config.tables.guestVote.getStatisticsTable}
                loading={loading}
                buttonLoading={happinessIndexesSearchAppendIsLoading}
            />
        </div>
    )
}

export default connect( mapStateToProps, allActions )( StatisticsTab )
