import { PostMetricsSaleChannelsSearchResponse } from 'services/guestVote/osat/type'

export const normalizeDataForOsatTable = ({ content, ...data }: PostMetricsSaleChannelsSearchResponse ) => {
    const SALE_CHANNELS_ID_ARRAY_FOR_TABLE = [ 1, 5, 2 ]
    const result: typeof data & {content: ( typeof content[number] & Record<string, any> )[]} = { ...data, content: [] }

    result.content = content.map( row => {
        const newRow: typeof row & Record<string, any> = { ...row, }
        newRow.saleChannels?.forEach( saleChannel => {
            if ( SALE_CHANNELS_ID_ARRAY_FOR_TABLE.includes( saleChannel.id )) {
                newRow[ `factOsat${saleChannel.id}` ] = saleChannel.factOsat
                newRow[ `factOsatLastYear${saleChannel.id}` ] = saleChannel.factOsatLastYear
                newRow[ `deltaFactOsatLastYear${saleChannel.id}` ] = saleChannel.deltaFactOsatLastYear
            }
        })
        delete newRow.saleChannels
        return newRow
    })

    return result
}

export type NormalizedOsatTableData = ReturnType<typeof normalizeDataForOsatTable> & {content: Record<string, any>[]}

