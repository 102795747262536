/* eslint-disable camelcase */
import { format } from 'tools'

const getFormat = ( type, oldrender ) => {
    return oldrender
        ? ( text ) => oldrender( format.strings[ type ]( text ))
        : ( text ) => format.strings[ type ]( text )
},

      getSorter = ( type, key ) => {
          switch ( type ) {
              case 'bydate':
                  return ( a, b, order ) => format.sort.byDate( a, b, key, order )

              case 'bymoment':
                  return ( a, b, order ) => format.sort.byMoment( a, b, key, order )

              case 'bynull':
                  return ( a, b, order ) => format.sort.byNull( a, b, key, order )

              case 'locale':
                  return ( a, b, order ) => format.sort.locale( a, b, key, order )

              case 'server':
                  return ( a, b, order ) => format.sort.server( a, b, order )

              default:
                  return ( a, b, order ) => format.sort.def( a, b, order )
          }
      },

      getFunc = ( name, key ) => {
          switch ( name ) {
              case 'cellclass':
                  return ( record, props ) => format.cells.className( record, key, props )
              case 'numeric':
                  return ( record, props ) => format.cells.addClass( props, 'numeric' )
              default:
                  return () => {}
          }
      },

      getCell = ( funcs, key ) => {
          const handlers = funcs.map(( f ) => getFunc( f, key ))
          return ( record ) => handlers.reduce(( props, f ) => f( record, props ), {})
      },

      applyAtts = ( args, res ) => {
          const atts = [
              'width',
              'render',
              'endpoint',
              'itemKey',
              'editable',
              'wait',
              'items',
              'infoText',
              'disabled',
              'disabledDate',
              'focusOnCreate',
              'parser',
              'fixed',
              'fixedDecimals',
              'starPos',
              'allowFull',
              'allowClear',
              'step',
              'small',
              'addOn',
              'max',
              'min',
              'colSpan',
              'rowSpan',
              'title',
              'full',
              'useValue',
              'action',
              'checkToday',
              'dateFormat',
              'rawFormat',
              'fixed',
              'popup',
              'search',
              'hideCurrency',
              'currency',
              'check',
              'prepare',
              'popover',
              'colorIndex',
              'hide',
              'nullValue',
              'icon',
              'children',
              'placement',
          ]

          let key, i

          for ( i = 0; i < atts.length; i++ ) {
              key = atts[ i ]
              args[ key ] !== undefined && ( res[ key ] = args[ key ])
          }
      },

      applyNumeric = ( args, res ) => {
          if ( args.numeric ) {
              res.className += ' numeric'
              args.cell = args.cell || []
              args.cell.push( 'numeric' )
          }
      },

      applySorter = ( args, res, key ) => {
          if ( args.sorter ) {
              res.sorter =
      typeof args.sorter === 'string'
          ? getSorter( args.sorter, key )
          : args.sorter
          }
      },

      apply = ( res, args ) => {
          if ( !args ) { return res }

          const key = res.dataIndex

          args.settingsTitle && ( res.settingsTitle = args.settingsTitle )
          args.nullable && ( res.nullable = args.nullable )
          args.as && ( res.render_as = args.as )
          args.action && !args.as && ( res.render_as = 'action' )
          res.className = args.className ? args.className : ''
          args.isRedPositive && ( res.isRedPositive = args.isRedPositive )
          args.objForDelta && ( res.objForDelta = args.objForDelta )

          applyAtts( args, res )
          applyNumeric( args, res )
          applySorter( args, res, key )
          args.cell && ( res.onCell = getCell( args.cell, key ))
          args.format && ( res.render = getFormat( args.format, args.render ))

          return res
      }

export default function column ( title, key, args ) {
    const res = {
        title: title,
        dataIndex: key,
    }

    return apply( res, args )
}
