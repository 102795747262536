import React, { ReactNode }   from 'react'
import { Gauge, GaugeConfig } from '@ant-design/charts'
import styles                 from './guest-vote-gauge.module.scss'

type GuestVoteGaugeProps = { title: ReactNode, multiplier?: number, postfix?: string } & GaugeConfig;

const GuestVoteGauge = ({ title, postfix, multiplier = 100, ...props }: GuestVoteGaugeProps ) => {
    return (
        <div className={styles.gauge}>
            <span className={styles.gauge_title}>
                {title}
            </span>
            <Gauge
                width={152}
                height={144}
                autoFit
                range={{
                    color: [ '#3aa8bf', '#00000036' ],
                    width: 9,
                }}
                indicator={{
                    pointer: { style: { stroke: '#7c7c7c', lineWidth: 3 } },
                    pin: {
                        style: {
                            r: 2,
                            stroke: '#7c7c7c',
                            width: 4,
                        },
                    },
                }}
                axis={{
                    label: { style: { fill: 'transparent' } },
                    tickLine: {
                        style: {
                            stroke: '#7c7c7c',
                            lineWidth: 1,
                        },
                        // @ts-ignore
                        tickCount: 1,
                        tickInterval: 1,
                        // @ts-ignore
                        ticklineColor: ( tick ) => tick % 20 === 0 ? '#7c7c7c' : 'transparent',
                    },
                    subTickLine: { count: 0, },
                }}
                statistic={{
                    content: {
                        formatter: ( event ) => {
                            let value = ( event?.percent * multiplier ).toFixed( 2 ).replace( /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1' )
                            postfix && ( value += ` ${postfix}` )
                            return value.replace( '.', ',' ) || '0'
                        },
                        style: {
                            color: '#151515',
                            fontSize: '22px',
                            marginTop: '10px',
                        },
                    },
                }}
                {...props}
            />
        </div> )
}

export default GuestVoteGauge
