/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Report }         from 'components'
import { format }         from 'tools'
import config             from 'config'
import { Button, Select } from 'antd'
import GroupSwitch        from '../GroupSwitch/GroupSwitch'
import { averageName }    from '../GroupSwitch/const'

class AverageCheckRating extends Component {
    static propTypes = {
        data: PropTypes.object,
        onAppend: PropTypes.func,

        partner: PropTypes.any,
        partners: PropTypes.array,

        onSelect: PropTypes.func,
        onPartner: PropTypes.func,
        onControls: PropTypes.func,
        onSort: PropTypes.func
    }

    constructor ( props ) {
        super( props )
        this.state = { data: {}, }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount () {
        const { data } = this.props
        data && this.setData( data )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props

        if ( nextProps.data.all !== data.all || nextProps.data.report !== data.report ) {
            this.setData( nextProps.data )
        }
    }

    setData = ( raw ) => {
        if ( !raw.report || !raw.all ) {
            this.setState({ data: null, })
            return
        }

        const data = format.copy.object( raw.report ),
              content = format.copy.array( data.content ),
              allRow = format.copy.object( raw.all )

        allRow.name = averageName[ this.props.ratingTableGroup ]
        allRow._alwaysOnTop = true

        content.unshift( allRow )

        data.content = content.filter(( row ) => Object.keys( row ).filter( i => row[ i ]).length )

        this.setState({ data })
    }

    onChange = ( record, key, value ) => {
        const data = format.copy.object( this.state.data ),
              nr = format.copy.object( record ),
              index = format.find.index( data.content, 'name', record.name )

        nr[ key ] = value
        data.content[ index ] = nr

        this.setState({ data })
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, [ 'diff' ], columns )

    options = ( items ) =>
        items.map(( item ) => (
            <Select.Option value={item.value} key={item.value}>
                {item.label}
            </Select.Option>
        ))

    render () {
        const {
            partner,
            facilityType,
            city,
            regionCoach,
            marketCoach,
            factsNumbers,
            partners,
            facilityTypes,
            areaCoach,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            restaurants,
            ratingTableGroup,
            changeRatingTableGroup,
            onPartner,
            onFilter,
            onFocus,
            flushFilters,
            showFlushFiltersButton
        } = this.props
        const { data } = this.state

        return (
            <div className="rating-inner">
                <div className="rating-performance__filters">
                    <Select
                        placeholder="Партнеры"
                        mode='multiple'
                        maxTagCount={1}
                        value={partner}
                        onChange={onPartner}
                        onFocus={onFocus}
                        style={{ minWidth: 220 }}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!partners.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( partners || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Типы ресторанов"
                        mode='multiple'
                        maxTagCount={1}
                        style={{ minWidth: 220 }}
                        value={ facilityType }
                        onFocus={ onFocus }
                        onChange={( value ) => onFilter({ value, name: 'facilityTypes' })}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!facilityTypes.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( facilityTypes || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Города"
                        mode='multiple'
                        maxTagCount={1}
                        value={city}
                        style={{ minWidth: 220 }}
                        onChange={( value ) => onFilter({ value, name: 'cities' })}
                        onFocus={onFocus}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!cities.length}
                        allowClear
                    >
                        {this.options([
                            ...( cities || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Region coaches"
                        mode='multiple'
                        maxTagCount={1}
                        style={{ minWidth: 220 }}
                        value={regionCoach}
                        onFocus={onFocus}
                        onChange={( value ) => onFilter({ value, name: 'regionCoaches' })}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!regionCoaches.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( regionCoaches || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Market coaches"
                        mode='multiple'
                        maxTagCount={1}
                        style={{ minWidth: 220 }}
                        value={marketCoach}
                        onChange={( value ) => onFilter({ value, name: 'marketCoaches' })}
                        onFocus={onFocus}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!marketCoaches.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( marketCoaches || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Area coaches"
                        mode='multiple'
                        maxTagCount={1}
                        style={{ minWidth: 220 }}
                        value={areaCoach}
                        onChange={( value ) => onFilter({ value, name: 'areaCoaches' })}
                        onFocus={onFocus}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!areaCoaches.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( areaCoaches || []),
                        ])}
                    </Select>
                    <Select
                        placeholder="Рестораны"
                        maxTagCount={1}
                        mode='multiple'
                        style={{ minWidth: 220 }}
                        value={factsNumbers}
                        onChange={( value ) => onFilter({ value, name: 'factsNumbers' })}
                        onFocus={onFocus}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        disabled={!restaurants.length}
                        filterOption={( input, option ) =>
                            option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                        }
                        allowClear
                    >
                        {this.options([
                            ...( restaurants || []),
                        ])}
                    </Select>
                </div>

                {showFlushFiltersButton &&
                    <Button
                        type='primary'
                        onClick={() => flushFilters()}
                        className="flush-filters-button"
                    >
                    Сбросить фильтры
                    </Button>
                }

                <GroupSwitch value={ratingTableGroup} onChange={changeRatingTableGroup}/>
                <Report
                    unit="currency"
                    rowKey="name"
                    data={data}
                    columns={config.tables.restaurants.average( ratingTableGroup ).filter( Boolean )}
                    onSort={( sorter ) => this.props.onSort( sorter.field, sorter.order )}
                    prepare={this.prepare}
                    onMore={this.props.onMore}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default AverageCheckRating
