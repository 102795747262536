import React                  from 'react'
import cn                     from 'classnames'
import cls                    from './osat-gauges.module.scss'
import { GuestVoteGauge }     from '../charts'
import { Tooltip }            from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

type OsatGaugesProps = {
    className?: string;
    data?: {
        factOsat?: number,
        saleChannels?: {
            id: number;
            name: string;
            factOsat?: number;
        }[]
    }
}

const OsatGauges = ( props: OsatGaugesProps ) => {
    const {
        className,
        data
    } = props

    return (
        <div
            className={cn( className, cls.osat_gauges )}
        >
            {data?.factOsat !== null && data?.factOsat !== undefined && data?.saleChannels?.length !== 1 &&
                <GuestVoteGauge
                    title={
                        <div className={cls.title_container}>
                            <span>OSAT</span>
                            <Tooltip title="Отношение кол-ва оценок “5” к общему количеству оценок (источник приложение
                            Rostic’s) по успешным заказам (для оценки “5”). OSAT берется в расчет от среднего чека,
                            установленного на данный момент">
                                <InfoCircleOutlined
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined} />
                            </Tooltip>
                        </div>}
                    postfix="%"
                    percent={data.factOsat / 100} />
            }
            {data?.saleChannels?.map(({ name, factOsat }) =>
                factOsat !== null && factOsat !== undefined && <GuestVoteGauge key={name} postfix="%" percent={factOsat / 100} title={name} />
            )}
        </div>
    )
}

export default OsatGauges
