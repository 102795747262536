/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* VENDOR */
import React, { useState, useEffect, useRef } from 'react'
import { connect }                            from 'react-redux'
import dayjs                                  from 'dayjs'

import { Alert as AAlert, Card, Layout } from 'antd'

/* APPLICATION */
import {
    Alert,
    AppHeader,
    GenerateProgress,
    InnerContent,
    Link,
    ScheduleControls,
    ScheduleDays,
    Spinner,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import logic         from './logic'
import ScheduleTable from './components/ScheduleTable/ScheduleTable'
import ScheduleChart from './components/ScheduleChart/ScheduleChart'

import { allActions, mapStateToProps } from './connector'
import './schedule.scss'
import ScheduleSummaryReport
    from '../../components/schedule/ScheduleSummaryReport/ScheduleSummaryReport'

const noChanges = [ null, false, false, false, false, false, false, false ]

interface PropTypes {
    schedule: Record<string, unknown> | null
    schedules: any[]
    hours: Record<string, any>
    duration: number
    request: Request
    sideDays: any[]
    positions: any[]
    list: any[]
    restaurants: any[]
    saved: boolean
    saveCount: number
    saveTurnover: boolean
    fetchStaff: ( params: Request ) => void
    fetchSchedules: ( params: Request ) => void
    fetchWorkHours: ( params: Request ) => void
    fetchDuration: ( params: Request ) => void
    flushWorkHours: () => void
    flushSchedules: () => void
    fetchPositionsChartedByType: ( params: Record<string, string | number | boolean | Record<string, string> > ) => void
    setDaysToSave: ( count: number ) => void
    saveScheduleDay: ( params: { body: any, params: Record<string, string> }) => void
    setSaved: ( saved: boolean ) => void
    fetchReportsSchedulesSummary: ( params: Record<string, string | number> ) => void
    getSchedule: ( params: Record<string, string | number> ) => void
    getSideDays: ( params: Record<string, string | number | dayjs.Dayjs> ) => void
    flushTurnoverError: () => void
    fetchWeekTurnover: ( params: Record<string, string | number> ) => void
    setTurnover: ( params: { body: any, factsNumber: string }) => void
    generateSchedule: ( params: { params: Record<string, string>, body: any }) => void
    flushGenerateError: () => void
    deleteSchedulesEmployeesHided: ( params: string ) => Promise<void>
    postSchedulesEmployeesHided: ( params: Record<string, string | null> ) => Promise<void>
    addServerError: ( error: string ) => void
    genError: boolean
    user: {
        info: Record<string, string | number | boolean>
    };
    weekTurnover: any
    flushTurnover: () => void
    errorTurnover: boolean
    errorSetTurnover: boolean
    dataSummaryReport: any
    dataSummaryReportSunToSun: any
}

export interface Request {
    restaurantId: string
    factsNumber: string
    currentPredef: string
    dateStart: string | null
    dateEnd: string | null
    dataGroup: string
    employeeId: string | null
    growing: boolean
    inactive: boolean
    serviceType: string
    unitOfMeasure: string
    zoneId: string
}

const Schedule = ( props: PropTypes ) => {

    const {
        schedule,
        schedules,
        hours,
        duration,
        request,
        sideDays,
        positions,
        list,
        restaurants,
        saved,
        saveCount,
        saveTurnover,
        fetchStaff,
        fetchSchedules,
        fetchWorkHours,
        fetchDuration,
        flushWorkHours,
        flushSchedules,
        fetchPositionsChartedByType,
        setDaysToSave,
        saveScheduleDay,
        setSaved,
        fetchReportsSchedulesSummary,
        getSchedule,
        getSideDays,
        flushTurnoverError,
        fetchWeekTurnover,
        setTurnover,
        generateSchedule,
        flushGenerateError,
        addServerError,
        genError,
        user,
        weekTurnover,
        flushTurnover,
        errorTurnover,
        errorSetTurnover,
        dataSummaryReport,
        dataSummaryReportSunToSun
    } = props

    const [ loading, setLoading ] = useState<boolean>( !schedule )
    const [ working, setWorking ] = useState<boolean>( false )
    const [ saving, setSaving ] = useState<boolean>( false )
    const [ changed, setChanged ] = useState<boolean[]>( format.copy.array( noChanges ))
    const [ changeTimer, setChangeTimer ] = useState<boolean | null>( null )

    const [ ignoreSetDay, setIgnoreSetDay ] = useState<boolean>( false )
    const [ forcePopup, setForcePopup ] = useState<boolean>( false )
    const [ canShow, setCanShow ] = useState<boolean>( false )
    const [ ignoreTomorrowMCState, setIgnoreTomorrowMCState ] = useState<boolean>( false )
    const [ changeDay, setChangeDay ] = useState<boolean>( false )
    const [ compactView, setCompactView ] = useState<boolean>( false )
    const [ focusOnUpdate, setFocusOnUpdate ] = useState<number | null>( null )

    const [ error, setError ] = useState<boolean | string>( false )
    const [ errorDate, setErrorDate ] = useState<string>( '' )
    const [ errorMessage, setErrorMessage ] = useState<string | null>( null )

    const [ progress, setProgress ] = useState<boolean>( false )
    const [ received, setReceived ] = useState<boolean | undefined>( false )

    const [ scheduleState, setScheduleState ] = useState<string>( 'current' )
    const [ day, setDay ] = useState<string>( '1' )
    const [ from, setFrom ] = useState<dayjs.Dayjs | null>( null )
    const [ to, setTo ] = useState<dayjs.Dayjs | null>( null )
    const [ max, setMax ] = useState<any[] | undefined | null>( null )
    const [ date, setDate ] = useState<dayjs.Dayjs>( dayjs().startOf( 'week' ))

    const [ dataSummaryReportState, setDataSummaryReportState ] = useState<any>( null )
    const [ dataSummaryReportSunToSunState, setDataSummaryReportSunToSunState ] = useState<any>( null )
    const [ data, setData ] = useState<any>( null )
    const [ chartPositions, setChartPositions ] = useState<any[]>([])
    const [ position, setPosition ] = useState<any>( null )
    const [ genData, setGenData ] = useState<any>( null )
    const [ filteredEmps, setFilteredEmps ] = useState<any[]>([])

    const [ open, setOpen ] = useState<Record<string, boolean>>({})
    const [ highlightColumns, setHighlightColumns ] = useState<any>( null )

    const [ afterSave, setAfterSave ] = useState<any>( null )
    const [ isSelected, setIsSelected ] = useState<boolean>( true )
    const [ isSummaryReport, setIsSummaryReport ] = useState<boolean>( false )

    useEffect(() => {
        init()
        document.addEventListener( 'keyup', keyboardHandlers )
        document.addEventListener( 'keydown', preventTab )

        return () => {
            document.removeEventListener( 'keyup', keyboardHandlers )
            document.removeEventListener( 'keydown', preventTab )
        }
    }, [])

    useEffect(() => {
        if ( restaurants ) {
            getPositions()
        }
    }, [ restaurants ])

    useEffect(() => {
        if ( schedules ) {
            setSchedule()
        }
    }, [ schedules ])

    useEffect(() => {
        setAllData( true )
    }, [ schedule ])

    useEffect(() => {
        setAllData( false, true )
    }, [ duration ])

    useEffect(() => {
        setCurrentDay( day )
    }, [ hours ])

    useEffect(() => {
        init()
    }, [ request.restaurantId ])

    useEffect(() => {
        if ( positions ) {
            setChartPositions( positions )
        }
    }, [ positions ])

    useEffect(() => {
        if ( list ) {
            changeFilteredEmps()
        }
    }, [ list ])

    useEffect(() => {
        if ( saveTurnover ) {
            load( date )
        }
    }, [ saveTurnover ])

    useEffect(() => {
        if ( saveCount < 1 && saved && !!afterSave ) {
            doAfterSave()
        }
    }, [ saveCount, saved, afterSave ])

    useEffect(() => {
        if ( data && hours && sideDays ) {
            logic.ui.getHighlights( data, day, hours, sideDays )
            setMax( logic.datetime.maxHours( schedule, day, hours, sideDays ))
            setHighlightColumns( logic.ui.getHighlights( data, day, hours, sideDays ))
        }
    }, [ data, hours, sideDays, day ])

    useEffect(() => {
        if ( !working && focusOnUpdate ) {
            setTimeout(() => setKeyboardFocus( focusOnUpdate ), 100 )
            setFocusOnUpdate( null )
        }
    }, [ working ])

    /* GLOBAL METHODS */

    const init = (): void => {
        fetchAll()
        schedules && setSchedule()
        schedule && duration && setAllData()
        positions && setChartPositions( positions )
        list && changeFilteredEmps()
    }

    const load = ( date: dayjs.Dayjs, dismissLoading?: boolean ): void => {
        const datesInStorage = JSON.parse( String( sessionStorage.getItem( 'scheduleData' )))

        if ( datesInStorage ) {
            setDate( dayjs( datesInStorage.start ).startOf( 'week' ))
            fetchWeek( dayjs( datesInStorage.start ).startOf( 'week' ))
        } else {
            sessionStorage.setItem( 'scheduleData', JSON.stringify({
                start: dayjs( date ).startOf( 'week' ).format( config.format.dateFull ),
                end: dayjs( date ).endOf( 'week' ).format( config.format.dateFull ),
            }))
            fetchWeek( date )
        }
        flushData( dismissLoading )
    }

    const generate = ( data: Record<string, any> ): void => {
        setProgress( true )
        setReceived( false )
        setCanShow( false )
        setGenData( logic.generate.prepare( data ))
    }

    const save = (): void => {
        setIgnoreSetDay( true )
        setSaving( true )
        setDaysToSave( changed.filter( s => s ).length )

        changed.map(( status, dow ) =>
            status && saveDay( dow )
        )
        setAfterSave(() => () => load( date, true ))
    }

    const doAfterSave = (): void => {
        afterSave()
        setAfterSave( null )
        setWorking( false )
        setSaved( false )
    }

    const reset = (): void => {
        setWorking( true )
        setTimeout( setAllData, 0 )
    }

    const saveDay = ( dow: number ): void => {
        const body = format.copy.object(
            logic.shifts.excludeUnassigned( logic.datetime.find( data.days, dow ))
        )

        saveScheduleDay({
            body,
            params: { factsNumber: request.restaurantId },
        })
    }

    const preventTab = ( e: KeyboardEvent ): void => {
        // @ts-ignore
        if ( loading || working || saving || progress || window._schedule_popup ) {
            return
        }

        if ( e.key === 'Tab' ) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    const getKeyboardFocus = (): { all: Element[]; current: Element | null; infocus: number } => {
        const all = Array.from(
            document.querySelectorAll(
                '.ant-table-fixed-left tr:not(.row-hidden) .ant-select, .ant-table-fixed-left tr:not(.row-hidden) .chart-time, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn'
            )
        ).filter( node => !node.querySelector( 'input:disabled' ))

        const current = document.querySelector(
            '.ant-table-fixed-left tr:not(.row-hidden) .chart-time-focused, .ant-table-fixed-left tr:not(.row-hidden) .ant-select-focused, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn-focused'
        )

        const infocus = all.findIndex( node =>
            node.classList.contains( 'chart-time-focused' ) ||
            node.classList.contains( 'ant-select-focused' ) ||
            node.classList.contains( 'assign-btn-focused' )
        )

        return { all, current, infocus }
    }

    const setKeyboardFocus = ( index: number ): void => {
        const { all, infocus } = getKeyboardFocus()
        const target = all[ index ]
            ? all[ index ].classList.contains( 'assign-btn' )
                ? all[ index ]
                : all[ index ].querySelector(
                    '.ant-time-picker-input, .ant-select-search__field'
                )
            : null

        if ( all[ infocus ]) {
            all[ infocus ].classList.remove( 'chart-time-focused' )
            all[ infocus ].classList.remove( 'assign-btn-focused' )
            all[ infocus ].classList.remove( 'ant-select-focused' )
        }

        document.dispatchEvent( new Event( 'mousedown' ))
        // @ts-ignore
        document.activeElement?.blur()

        if ( target ) {
            scrollToFocused( all[ index ])

            if ( target.classList.contains( 'assign-btn' )) {
                target.classList.add( 'assign-btn-focused' )
                all[ infocus ].classList.remove( 'ant-select-focused' )
            } else {
                if ( all[ index ].classList.contains( 'ant-select' )) {
                    all[ index ].classList.add( 'ant-select-focused' )
                } else if ( all[ index ].classList.contains( 'chart-time' )) {
                    all[ index ].classList.add( 'chart-time-focused' )
                } else {
                    console.log( target )
                }
                // @ts-ignore
                nextFocusAction(() => target.click())
            }
        }
    }

    const findParent = ( node: Node, type: string ): HTMLElement => {
        let parent = node.parentNode
        // @ts-ignore
        while ( parent?.tagName !== type ) {
            parent = parent?.parentNode || null
        }
        // @ts-ignore
        return parent
    }

    const scrollToFocused = ( target: Element ): void => {
        const content: HTMLElement | null = document.querySelector( '.inner-content' )

        const table = document
            .querySelector( '.staff-schedule-table' )
            ?.getBoundingClientRect() || { bottom: content?.getBoundingClientRect().top }
        const parent = findParent( target, 'TD' )
        const top = parent.getBoundingClientRect().top
        const topOffset = table.bottom
        const visible = topOffset && top >= topOffset && top < window.screen.availHeight - 160

        if ( !visible ) {
            content?.scrollTo({ top: parent.offsetTop - content?.offsetTop })
        }
    }

    const nextFocusAction = ( cb: () => void ): void => {
        // this._nextFocus.current = setTimeout( cb, 300 )
    }

    const keyboardHandlers = ( e: KeyboardEvent ): void => {
        // @ts-ignore
        if ( loading || working || saving || progress || window._schedule_popup ) {
            return
        }

        preventTab( e )
        switch ( e.key ) {
            case 'Tab':
                e.preventDefault()
                // this._nextFocus.current && clearTimeout( this._nextFocus.current )

                const { all, current, infocus } = getKeyboardFocus()

                if ( !current ) {
                    const target: HTMLElement | null = document.querySelector(
                        '.ant-table-fixed-left tr:not(.row-hidden) .ant-select-search__field, .ant-table-fixed-left tr:not(.row-hidden) .assign-btn'
                    )

                    if ( target ) {
                        scrollToFocused( target )

                        if ( target.classList.contains( 'assign-btn' )) {
                            target.classList.add( 'assign-btn-focused' )
                        } else {
                            if ( target.classList.contains( 'ant-select-search__field' )) {
                                // @ts-ignore
                                target.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.classList.add(
                                    'ant-select-focused'
                                )
                            }

                            nextFocusAction(() => target.click())
                        }
                    }
                } else {
                    const cur = infocus
                    const next = e.shiftKey
                        ? cur < 0 || cur - 1 < 0
                            ? all.length - 1
                            : cur - 1
                        : cur < 0 || cur + 1 >= all.length
                            ? 0
                            : cur + 1

                    setKeyboardFocus( next )
                }
                break

            case 'Enter':
                e.preventDefault()

                const input: HTMLElement | null  = document.querySelector(
                    '.ant-select-focused .ant-select-search__field'
                )
                const focused: HTMLElement | null = document.querySelector(
                    '.ant-table-fixed-left tr:not(.row-hidden) .chart-time-focused, .ant-table-fixed-left tr:not(.row-hidden) .ant-select-focused'
                )
                const ddcontainer = focused
                    ? document.getElementById(
                        // @ts-ignore
                        focused
                            .querySelector( '.ant-select-selection' )
                            ?.getAttribute( 'aria-controls' )
                    )
                    : null
                const dd = ddcontainer &&
                // @ts-ignore
                    !ddcontainer.parentNode?.classList.contains( 'ant-select-dropdown-hidden' ) &&
                // @ts-ignore
                    !ddcontainer.parentNode?.classList.contains( 'slide-up-enter' )
                const assign: HTMLElement | null = document.querySelector( '.assign-btn-focused' )

                if ( assign ) {
                    assign.click()
                } else if ( input && dd ) {
                    input.blur()
                } else {
                    const el: HTMLElement | null | undefined = focused?.querySelector(
                        '.ant-time-picker-input, .ant-select-search__field'
                    )
                    el?.click()
                }
                break

            default:
                // Do nothing
        }
    }

    const fetchScheduleSummary = ( date: dayjs.Dayjs ): void => {
        const start = dayjs( date, config.format.date ).startOf( 'week' )
        const end = dayjs( start ).endOf( 'week' )

        fetchReportsSchedulesSummary({
            factsNumber: request.restaurantId,
            dateStart: start.format( config.format.dayAPI ),
            dateEnd: end.format( config.format.dayAPI ),
        })
    }

    /* FETCH METHODS */

    const fetchWeek = ( date: dayjs.Dayjs ): void => {
        const start = dayjs( date, config.format.date ).startOf( 'week' )
        setTimeout(() => {
            getSchedule({
                factsNumber: request.restaurantId ?? request.factsNumber,
                dateStart: start.format( config.format.dayAPI ),
            })
        }, 900 )

        getSideDays({
            factsNumber: request.restaurantId,
            dateStart: dayjs( start ).startOf( 'week' ).subtract( 1, 'day' ),
            dateEnd: dayjs( start ).endOf( 'week' ).add( 1, 'day' ),
        })
    }

    const fetchAll = (): void => {
        flushWorkHours()
        flushSchedules()
        getPositions()

        fetchStaff({ ...request, factsNumber: request.restaurantId })
        fetchSchedules( request )
        fetchWorkHours( request )
        fetchDuration( request )
    }

    const changeSelected = ( isSelectedChangingState: boolean ): void => {
        setIsSelected( isSelectedChangingState )
        getPositions()
    }

    const getPositions = (): void => {
        if ( !restaurants ) {
            return
        }

        const restaurant = restaurants.find( rest => rest.factsNumber === Number( request.restaurantId ))

        if ( !restaurant ) {
            return
        }

        fetchPositionsChartedByType({
            facilityType: { id: restaurant.facilityType.id },
            isExpressWindow: restaurant.isExpressWindow,
            restaurantType: { id: restaurant.restaurantType.id },
            factsNumber: request.restaurantId,
            isSelected: false,
        })
    }

    const getTurnover = ( body: Record<string, any> ): void => {
        flushTurnoverError()
        body.factsNumber = request.restaurantId
        fetchWeekTurnover( body )
    }

    const changeTurnover = ( body: Record<string, any> ): void => {
        flushTurnoverError()
        const factsNumber = request.restaurantId
        const data = {
            body: body,
            factsNumber: factsNumber
        }
        setTurnover( data )
    }

    /* PROGRESS METHODS */

    const progressReady = (): void => {
        generateSchedule({
            params: {
                dateStart: genData.date.format( config.format.dayAPI ),
                factsNumber: request.restaurantId,
            },
            body: { turnover: genData.turnover },
        })
    }

    const progressDone = (): void => {
        load( date )
        flushGenerateError()
        setProgress( false )
        setGenData( null )
    }

    /* APPLY DATA SECTION */

    const flushData = ( dismissLoading?: boolean ): void => {
        setData( dismissLoading ? data : null )
        setPosition( null )
        setLoading( !dismissLoading )
        setChanged( format.copy.array( noChanges ))
    }

    const setSchedule = (): void => {
        if ( !schedule ) {
            scheduleState ? load( date ) : setScheduleState( 'current' )
            return
        }
        setCanShow( true )
    }

    const setAllData = ( needSetDay?: boolean, notSetDate?: boolean ): void => {
        if ( !duration || !schedule ) {
            return
        }

        const preparedSchedule: {dateStart?:string} = logic.schedule.prepare( schedule, duration )
        const { day: d, date, from: newFrom, to: newTo } = logic.datetime.schedule(
            preparedSchedule,
            needSetDay && !ignoreSetDay,
            day
        )
        setData( preparedSchedule )
        setDay( d )
        setDataSummaryReportState( dataSummaryReport )
        setDataSummaryReportSunToSunState( dataSummaryReportSunToSun )
        setLoading( false )
        setChanged( format.copy.array( noChanges ))

        setScheduleState( logic.datetime.getTab( preparedSchedule.dateStart ))
        setFrom( needSetDay ? newFrom : from )
        setTo( needSetDay ? newTo : to )
        setSaving( false )
        setReceived( needSetDay )
        setChangeDay( needSetDay ? false : changeDay )

        setMax( logic.datetime.maxHours( preparedSchedule, d, hours, sideDays ))
        setHighlightColumns( logic.ui.getHighlights( preparedSchedule, d, hours, sideDays ))

        if ( !notSetDate ) {
            setDate( date )
        }
    }

    const setCurrentDay = ( day: string ): void => {
        const wh = logic.datetime.workHours()
        if ( !wh ) {
            return
        }

        setChangeDay( true )
        setDay( day )
        setFrom( wh.from )
        setTo( wh.to )
        setDate( logic.datetime.offsetDate( schedule?.dateStart, day ))
        setPosition( null )
        setIgnoreSetDay( false )

        checkErrors()
    }

    const setWeek = ( schedule: string ): void => {
        const date = logic.schedule.date( schedule )
        // @ts-ignore
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        if ( schedule === scheduleState ) {
            return
        }
        datesInStorage.lastPage = 'schedule'
        datesInStorage.start = dayjs( date ).startOf( 'week' ).format( config.format.dateFull )
        datesInStorage.end = dayjs( date ).endOf( 'week' ).format( config.format.dateFull )

        sessionStorage.setItem( 'scheduleData', JSON.stringify( datesInStorage ))

        load( date )

        setScheduleState( schedule )
        setIgnoreSetDay( dayjs().startOf( 'week' ).format( config.format.dayAPI ) === schedule )
        setDay( '1' )
    }

    const changePosition = ( id: string ): void => {
        const d = logic.datetime.find( data.days, day )

        const original = d.positions.find(( p: any ) => p.positionGuid === id )
        if ( !original ) {
            return
        }
        const position = format.copy.object( original )

        position.hours = original?.hours?.map(( h: any ) => ({ paidHours: h }))

        setPosition( position )
    }

    const changeFilteredEmps = (): void => {
        const emps = list.filter( e => {
            if ( e.transfer ) {
                if ( e.transfer.originalFactsNumber.toString() === request.restaurantId ) {
                    return dayjs( e.transfer.dateTo ).isBefore( date )
                }
                if ( dayjs( e.transfer.dateTo ).isBefore( date )) {
                    return false
                }
            }
            return !!( e.salary && e.rateDictionary )
        })
        setFilteredEmps([ ...emps ])
    }

    const showPopup = (): void => {
        setForcePopup( true )

        setTimeout(() => {
            setForcePopup( false )
        }, 500 )
    }

    const toggle = ( key: string, handler?: () => void ): () => void => {
        return () => {
            setOpen({
                ...open,
                [ key ]: !open[ key ]
            })
            handler && handler()
        }
    }

    const add = ( shift: Record<string, any> ): void => {
        const { res, pos } = extract( shift, 'position' )
        const dow = parseInt( day )
        const sh = shift.shiftStart.hour()
        const sm = shift.shiftStart.minute()
        const eh = shift.shiftEnd.hour()
        const em = shift.shiftEnd.minute()
        const workHours = hours.days[ dow - 1 ]

        shift._temp_id = format.generate.guid()
        shift.shiftStart = dayjs( date ).set( 'hour', sh ).set( 'minute', sm )
        shift.shiftEnd = shift.shiftStart.hour() > shift.shiftEnd.hour()
            ? dayjs( date ).add( 1, 'days' ).set( 'hour', eh ).set( 'minute', em )
            : dayjs( date ).set( 'hour', eh ).set( 'minute', em )

        logic.shifts.add( list, pos, shift )

        if ( shift.shiftStart.hour() > shift.shiftEnd.hour() &&
            shift.shiftEnd.hour() > 0 &&
            dow < 7 ) {
            const { pos: nextPos } = extract( shift, 'position', null, dow + 1 )
            const dshift = format.copy.object( shift )
            dshift._double = true
            dshift._temp_id = shift._temp_id
            logic.shifts.add( list, nextPos, dshift )
            changeDays([ dow + 1 ])
        }
        setWorking( true )

        setTimeout(() => {
            logic.calculate.schedule( res, true, dow, workHours, positions )
            setTimeout(() => {
                changeOneDay()
                setData( res )
                position && changePosition( position.positionGuid )
            }, 0 )
        }, 0 )
    }

    const assign = ( data: Record<string, any>, target: Record<string, any> ): void => {
        const { infocus } = getKeyboardFocus()
        change( target, {
            employeeName: data.employeeId,
            isUnderage: data.isUnderage,
            rateDictionary: data.rateDictionary
        })
        setFocusOnUpdate( infocus )
    }

    const remove = ( shift: Record<string, any> ): void => {
        const { res, pos, day: d } = extract( shift, 'positionGuid' )
        const index = pos.shifts.indexOf(

            pos.shifts.find(( s: Record<string, any> ) =>
                s.guid && shift.guid
                    ? s.guid === shift.guid
                    : shift._temp_id
                        ? s._temp_id === shift._temp_id
                        : s.workScheduleId === shift.workScheduleId
            )
        )
        const dow = parseInt( day )
        const workHours = hours.days[ dow - 1 ]

        if ( index > -1 ) {
            pos.shifts.splice( index, 1 )
        }
        logic.twins.remove( shift, date, res )
        setWorking( true )

        setTimeout(() => {
            logic.calculate.schedule(
                res,
                !!shift.employeeId,
                dow,
                workHours,
                positions
            )

            setTimeout(() => {
                setData( res )
                setError( false )
                setErrorDate( '' )

                if ( shift.employeeId ) {
                    changeOneDay()
                }
                if ( !dayjs( shift.shiftStart ).isSame( date, 'day' )) {
                    changeDays([ d?.dayOfWeek - 1 ])
                }
                checkErrors()
            }, 0 )
        }, 0 )
    }

    const changeOneDay = (): void => {
        setChanged( changed.map(( val, i ) =>
            i === parseInt( day ) ? true : val
        ))
    }

    const changeDays = ( list: number[]): void => {
        setChanged( changed.map(( val, i ) =>
            list.includes( i ) ? true : val
        ))
    }

    const clearError = ( err: string | boolean ): void => {
        if ( !err || error === err ) {
            setError( '' )
            setErrorMessage( null )
        }
    }

    const throwError = ( err: string, message?: string ): void => {
        if ( err === 'underage_error' ||
            err === 'mc_error' ||
            err === 'outstaff_error' ) {
            setError( err )
            setErrorMessage( message ?? errorMessage )
            return
        }

        if ( error === 'tomorrow_mc_error' ) {
            if ( ignoreTomorrowMCState ) {
                setErrorMessage( message ?? errorMessage )
            } else {
                setError( err )
                setErrorMessage( message ?? errorMessage )
            }
        }
    }

    const change = ( original: Record<string, any>, config: Record<string, any> ): void => {
        setWorking( true )

        setTimeout(() => {
            const { res, day: d, pos, shift } = extract(
                original,
                'positionGuid',
                original.guid
            )
            const dow = parseInt( day )
            const changed = [ dow ]
            const workHours = hours.days[ dow - 1 ]

            Object.keys( config ).forEach( key => {
                const val = config[ key ]

                switch ( key ) {
                    case 'position':
                        logic.positions.update( positions, d, pos, shift, val, date, res )
                        break

                    case 'employeeName':
                        shift!._warning = false
                        logic.employee.set( list, shift, val, date, res )
                        break

                    case 'shiftEnd':
                    case 'shiftStart':
                        logic.shifts.setTime( shift, key, val, date, res )
                        if ( dow > 1 ) { changed.push( dow - 1 ) }
                        if ( dow < 7 ) { changed.push( dow + 1 ) }
                        break

                    case 'shiftSupervisor':
                    case 'isUnderage':
                    case 'rateDictionary':
                        shift![ key ] = val
                        break

                    default:
                        console.log( d, key, val )
                }
            })

            logic.calculate.schedule( res, true, dow, workHours, positions )
            changeDays( changed )

            setData( res )
            setError( false )
            setErrorDate( '' )

            setTimeout( checkErrors, 0 )
        }, 0 )
    }

    const changeOne = ( original: Record<string, any>, key: string, val: any ): void => {
        setWorking( true )
        setTimeout(() => {
            const { res, day: d, pos, shift } = extract(
                original,
                'positionGuid',
                original.guid
            )
            const dow = parseInt( day )
            const changed = [ dow ]
            const workHours = hours.days[ dow - 1 ]

            switch ( key ) {
                case 'position':
                    if ( original.positionGuid === val ) {
                        setWorking( false )
                        return
                    }
                    logic.positions.update( positions, d, pos, shift, val, date, res )
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'employeeName':
                    if ( original.employeeName === val || original.employeeId === val ) {
                        setWorking( false )
                        return
                    }
                    shift!._warning = false
                    logic.employee.set( list, shift, val, date, res )
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'shiftEnd':
                case 'shiftStart':
                    logic.shifts.setTime( shift, key, val, date, res )
                    if ( dow > 1 ) { changed.push( dow - 1 ) }
                    if ( dow < 7 ) { changed.push( dow + 1 ) }
                    logic.calculate.schedule( res, true, dow, workHours, positions )
                    break

                case 'shiftSupervisor':
                    shift![ key ] = val
                    break

                default:
                    console.log( d, key, val )
            }

            changeOneDay()

            setData( res )
            setError( false )
            setErrorDate( '' )

            setTimeout( checkErrors, 0 )
        }, 0 )
    }

    const toggleCompactView = (): void => {
        setWorking( true )

        // @ts-ignore
        document.activeElement?.blur()
        setTimeout(() => {
            setCompactView( !compactView )
            setWorking( false )
        })
    }

    /* TOOLS */

    const extract = (
        original: Record<string, any>,
        pid: string,
        guid?: string | null,
        dow?: string | number
    ): { res: Record<string, any>; pos: Record<string, any>; day?: Record<string, any>; shift?: Record<string, any> } =>
        logic.shifts.extract(
            data,
            dow ?? day,
            original[ pid ],
            original.employeeId,
            positions,
            guid
        )

    const ignoreTomorrowMC = (): void => {
        const dow = parseInt( day )
        const workHours = hours.days[ dow - 1 ]

        setChangeTimer( true )
        setError( false )
        setData({ ...data })
        setIgnoreTomorrowMCState( true )

        setTimeout(() => {
            logic.calculate.schedule( data, true, day, workHours, positions )
            setTimeout( checkErrors, 0 )
        }, 0 )
    }

    /* GET PAGE STATE METHODS */

    const checkErrors = (): void => {
        if ( !data ) {
            return
        }

        data.days.every(( d: Record<string, any> ) =>
            d.positions.every(

                logic.shifts.errors(( a: Record<string, dayjs.Dayjs>, b: Record<string, dayjs.Dayjs> ) => {
                    const date =
                        dayjs( a.shiftStart ).format( 'DD' ) ===
                        dayjs( b.shiftStart ).format( 'DD' )
                            ? dayjs( a.shiftStart ).format( config.format.onlyDayView )
                            : dayjs( a.shiftEnd ).format( config.format.onlyDayView )
                    setError( true )
                    setErrorDate( date )
                })
            )
        )
        setTimeout(() => {
            setChangeDay( false )
            setChangeTimer( null )
        }, 0 )
    }

    const changedOutstaff = ( res: boolean, useOutstaffError = false ): boolean =>
        useOutstaffError &&
        error === 'outstaff_error' &&
        hasOutstaffReturned()
            ? res
            : logic.helpers.changed( res, props, { error, data, changed })

    const hasOutstaffReturned = (): any[] | null => {
        if ( !data ) {
            return null
        }
        const d = logic.datetime.find( data.days, day )

        const res: any[] = []

        d?.positions?.forEach(( pos: Record<string, any> ) => {
            pos.shifts.forEach(( shift: any ) => {
                if ( shift.notAssignedToTheRestaurant ) {
                    res.push( shift )
                }
            })
        })

        if ( res.length > 0 && !error ) {
            setTimeout(() => {
                setError( 'outstaff_error' )
            }, 0 )
        }

        return res.length > 0 ? res : null
    }

    const weekPresent = (): boolean => {
        // @ts-ignore
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'scheduleData' ))

        return (
            schedules &&
            ( schedules.indexOf(
                dayjs( datesInStorage ? datesInStorage.start : date )
                    .startOf( 'week' )
                    .format( config.format.dateFull )
            ) > -1 ||
                dayjs( datesInStorage ? datesInStorage.start : date )
                    .startOf( 'week' )
                    .isSame( dayjs().startOf( 'week' )))
        )
    }

    const enoughData = (): boolean =>
        data && from && to && list

    /* RENDER PARTS */

    const spoiler = ( path: string, obj: any, handler?: () => void ): void => {
        let key = path.split( '.' )[ 0 ] + day
        key += obj.positionGuid ? obj.positionGuid : 'Day'

        obj.__open = obj.open ? !open[ key ] : open[ key ]
        obj.__spoiler = true
        obj.__offset = 1
        obj.__spkey = key
        obj.guid = format.generate.guid()
        obj.rowClick = toggle( key, handler )
    }

    const table = (): JSX.Element | string | null => {
        const {
            positions,
            hours,
            dataSummaryReport,
            deleteSchedulesEmployeesHided,
            postSchedulesEmployeesHided,
            request
        } = props

        const start = dayjs( date, config.format.date ).startOf( 'week' )
        const end = dayjs( start ).endOf( 'week' )
        const currentWeek = {
            dateStart: start.format( config.format.dayAPI ),
            dateEnd: end.format( config.format.dayAPI ),
        }

        if ( compactView && !isSummaryReport || !weekPresent()) {
            return null
        }

        if (( loading || !enoughData()) && !isSummaryReport ) {
            return (
                <div className="spinner-container">
                    <Spinner/>
                </div>
            )
        }

        if ( !weekPresent()) {
            const message = dayjs().startOf( 'week' ).isSame( date ) ? (
                <span>
                    Расписание на текущую неделю недоступно. Можно{' '}
                    <Link onClick={showPopup}>спланировать другие недели</Link>.
                </span>
            ) : (
                <span>
                    Расписание на следующую неделю не составлено.{' '}
                    <Link onClick={showPopup}>Сформировать</Link>.
                </span>
            )

            return <AAlert message={message} type="warning"/>
        }

        if (( !data || !data.days.length ) && !isSummaryReport ) {
            return 'На выбранную неделю расписание не сформировано.'
        }

        return (
            !isSummaryReport ?
                    <ScheduleTable
                        open={open}
                        positions={positions}
                        data={data}
                        day={day}
                        position={position}
                        workHours={hours}
                        from={from}
                        to={to}
                        max={max}
                        onSelect={changePosition}
                        spoiler={spoiler}
                    />
                :
                    <ScheduleSummaryReport
                        data={dataSummaryReport?.currentWeek}
                        request={request}
                        date={date}
                        currentWeek={currentWeek}
                        fetchAllSchedules={fetchSchedules}
                        deleteSchedulesEmployeesHided={deleteSchedulesEmployeesHided}
                        fetchScheduleSummary={fetchScheduleSummary}
                        postSchedulesEmployeesHided={postSchedulesEmployeesHided}
                    />
        )
    }

    const changeSummaryReport = (): void => {
        setIsSummaryReport( !isSummaryReport )
    }

    const chart = (): JSX.Element | null => {
        if ( !data || !data.days.length || !enoughData() || !weekPresent()) {
            return null
        }

        if ( changeDay ) {
            return (
                <div className="spinner-container chart">
                    <Spinner/>
                </div>
            )
        }

        return (
            !isSummaryReport ?
                    <ScheduleChart
                        day={day}
                        from={date}
                        to={to}
                        date={date}
                        data={data}
                        open={open}
                        inlineHours={compactView}
                        ignoreTomorrowMC={ignoreTomorrowMCState}
                        workHours={hours}
                        positions={chartPositions}
                        employees={filteredEmps}
                        onAdd={add}
                        onChange={changeOne}
                        onRemove={remove}
                        onError={throwError}
                        clearError={clearError}
                        onAssign={assign}
                        spoiler={spoiler}
                        changeSelected={changeSelected}
                        onDone={() => setWorking( false )}
                    />
                : null
        )
    }

    return (
        <section className="kfc-schedule kfc-tabbed-page scroll-container fixed-container">
            {highlightColumns && (
                <style dangerouslySetInnerHTML={{ __html: highlightColumns }}/>
            )}
            <Layout>
                <Layout.Header>
                    <AppHeader
                        ready={!loading}
                        timeData={parseInt( request.restaurantId )}
                    />
                </Layout.Header>
                <Layout.Content>
                    <ScheduleControls
                        // @ts-ignore
                        request={request}
                        user={user.info}
                        list={schedules}
                        current={scheduleState}
                        turnover={weekTurnover}
                        compact={compactView}
                        changed={!!changedOutstaff( true )}
                        popup={forcePopup}
                        onWeek={setWeek}
                        onGenerate={generate}
                        getTurnover={getTurnover}
                        setTurnover={changeTurnover}
                        onCompact={toggleCompactView}
                        flushTurnover={flushTurnover}
                        saveTurnover={saveTurnover}
                        errorTurnover={errorTurnover}
                        errorSetTurnover={errorSetTurnover}
                        addServerError={addServerError}
                        isSummaryReport={isSummaryReport}
                        changeSummaryReport={changeSummaryReport}
                    />

                    <InnerContent
                        bigOffset={!!changedOutstaff( true )}
                    >
                        <Card bordered={false}>
                            {weekPresent() && !isSummaryReport && (
                                <ScheduleDays
                                    data={data}
                                    show={!!( data?.days.length )}
                                    day={day}
                                    onDay={setCurrentDay}
                                />
                            )}
                            {table()}
                            {chart()}
                        </Card>
                    </InnerContent>

                    <GenerateProgress
                        active={progress}
                        received={canShow}
                        error={genError}
                        onReady={progressReady}
                        onDone={progressDone}
                    />

                    {changedOutstaff(
                        // @ts-ignore
                        <Alert
                            text={
                                error
                                    ? logic.helpers.errorMessage(
                                        error,
                                        errorDate,
                                        hasOutstaffReturned(),
                                        errorMessage
                                    )
                                    : 'Изменения вступят в силу после сохранения'
                            }
                            error={error}
                            button={!error}
                            buttonText="Сохранить"
                            action={save}
                            cancel={!hasOutstaffReturned()}
                            cancelText={
                                error === 'tomorrow_mc_error'
                                    ? 'Закрыть'
                                    : 'Отменить изменения'
                            }
                            onCancel={
                                error === 'tomorrow_mc_error'
                                    ? ignoreTomorrowMC
                                    : reset
                            }
                        />,
                        true
                    )}

                    {( saving || working ) && (
                        <div className="saving-overlay">
                            <Spinner/>
                        </div>
                    )}
                </Layout.Content>
            </Layout>
        </section>
    )
}


export default connect( mapStateToProps, allActions )( Schedule )
