/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, Alert }      from 'antd'
import dayjs                from 'dayjs'

/* APPLICATION */
import { ReportTable, AddButton, Spinner, Link } from 'components'
import { format }                                from 'tools'
import config                                    from 'config'

class RestaurantShifts extends Component {
    static propTypes = {
        data: PropTypes.array,
        onAdd: PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onChangeView: PropTypes.func,
    }

    prepare = ( record ) => {
        record.disabled = record.disabled || {}
        record.disabled.noctidial = record.holiday

        if ( record.holiday || record.noctidial ) {
            record.disabled.timeStart = true
            record.disabled.timeEnd = true

            if ( record.holiday ) {
                record.noctidial = false
                record.shifts = []
            }
        } else {
            record.disabled.timeStart = false
            record.disabled.timeEnd = false
        }

        return record
    }

    prepareShifts = ( day ) => ( shift, index ) => {
        shift.dayOfWeek = day.dayOfWeek
        shift.key = day.dayOfWeek + '_' + index
        return shift
    }

    moveToTime = () => this.props.onChangeView( 'time' )

    onAdd = ( day ) => {
        return () => this.props.onAdd( day )
    }

    onRemove = ( day ) => {
        return ( record, none, index ) => this.props.onRemove( record, day, index )
    }

    onChange = ( day ) => {
        return ( shift, key, val ) => this.props.onChange( shift, key, val, day )
    }

    table = ( day ) => {
        const isInvalidWorkHours = day.timeStart === null || day.timeEnd === null

        return (
            <div className="report-table offset left-off" key={day.dayOfWeek}>
                <h2>
                    {format.strings.capitalize( dayjs().day( day.dayOfWeek ).format( 'dddd' ))}
                </h2>

                {day.shifts.length > 0 ? (
                    <ReportTable
                        data={format.generate.noPager( day.shifts )}
                        columns={config.tables.settings.shifts}
                        rowKey="timeStart"
                        emptyText={day.holiday ? 'Выходной' : 'Не добавлено ни одной смены'}
                        prepare={this.prepareShifts( day )}
                        onAdd={this.onAdd( day )}
                        onChange={this.onChange( day )}
                        onRemove={this.onRemove( day )}
                    />
                ) : isInvalidWorkHours
                    ? <Alert
                            message={<div>
                                Чтобы добавить смену на выбранный день, необходимо на вкладке <Link onClick={this.moveToTime}>Режим&nbsp;работы</Link> заполнить &quot;Время открытия&quot; и &quot;Время закрытия&quot; ресторана
                            </div>}
                            type="warning"
                        />
                    : (
                            <div className="tabbed-footer nopad">
                                <AddButton text="Добавить" action={this.onAdd( day )} />
                            </div>
                        )}
            </div>
        )
    }
    content = ( days ) =>
        days
            .filter(( d ) => !d.holiday )
            .map( this.prepare )
            .map( this.table )

    render () {
        const { data } = this.props

        if ( !data ) { return <Spinner /> }

        return (
            <Card bordered={false}>
                {data
                    .filter(( d ) => !d.holiday )
                    .map(( r ) => r.shifts.length < 1 )
                    .includes( true ) && (
                    <Alert
                        message="Чтобы сформировать расписание, добавьте хотя бы одну смену на каждый день работы ресторана"
                        type="warning"
                    />
                )}
                <div className="report-table shifts-table">{this.content( data )}</div>
            </Card>
        )
    }
}

export default RestaurantShifts
