import { osatActions, guestVoteActions } from 'services'
import { IState }                        from 'store'

export const allActions = { ...guestVoteActions, ...osatActions }

export const mapStateToProps = ( state: IState ) => ({
    currentFilters: state.guestVote.currentFilters,

    metricsSaleChannelsSearch: state.guestVote.metricsSaleChannelsSearch,
    metricsSaleChannelsSearchLoading: state.guestVote.metricsSaleChannelsSearch_loading,
    metricsSaleChannelsSearchAppendLoading: state.guestVote.metricsSaleChannelsSearchAppend_loading,

    metricsSaleChannelsSummary: state.guestVote.metricsSaleChannelsSummary,
    metricsSaleChannelsSummaryLoading: state.guestVote.metricsSaleChannelsSummary_loading,

    metricsSaleChannels: state.guestVote.metricsSaleChannels,
    metricsSaleChannelsLoading: state.guestVote.metricsSaleChannels_loading
})

export type ConsolidatedStatisticsStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
