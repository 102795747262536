import { TableGroupType }      from '../../Rating'
import { addFiltersToRequest } from '../utils'
import { RatingSorter }        from './useRatingSorter'

export type RatingDataQueries = {
    flushAverageRating: ( ...args: any[]) => void
    fetchAllAverageCheck: ( ...args: any[]) => void
    fetchRatingAverageCheck: ( ...args: any[]) => void
    flushPerformanceRating: ( ...args: any[]) => void
    fetchRatingPerformance: ( ...args: any[]) => void
    fetchSummaryPerformance: ( ...args: any[]) => void
    flushTimeRating: ( ...args: any[]) => void
    fetchAllServiceTime: ( ...args: any[]) => void
    fetchServiceTime: ( ...args: any[]) => void
    flushCategoriesRating: ( ...args: any[]) => void
    fetchFilters: ( ...args: any[]) => void
    fetchCategoriesRating: ( ...args: any[]) => void
    fetchCategoryManagers: ( ...args: any[]) => void
    fetchManagerRestaurants: ( ...args: any[]) => void
    flushTurnoverRating: ( ...args: any[]) => void
    fetchAllTurnover: ( ...args: any[]) => void
    fetchRatingTurnover: ( ...args: any[]) => void,
    flushPerformanceChart: ( ...args: any[]) => void,
    fetchPerformancePlanAndFact: ( ...args: any[]) => void,
    flushTurnoverChart: ( ...args: any[]) => void,
    fetchTurnoverPlanAndFact: ( ...args: any[]) => void,
    flushManagerRestaurants: ( ...args: any[]) => void,
    flushTurnover: ( ...args: any[]) => void,
    flushPerformance: ( ...args: any[]) => void,
    flushAverage: ( ...args: any[]) => void,
    request: Record<string, any>
}

export type RatingFilters = {
    partner: number[],
    cities: number[],
    regionCoaches: number[],
    marketCoaches: number[],
    areaCoaches: number[],
    factsNumbers: number[],
    facilityTypes: number[],
    selected: number[],
    category: {categoryId: string},
    manager: {userId: string},
}

const STORAGE_KEY = 'ratingTableGroup'

export const useLoadRatingData = ( queries: RatingDataQueries, filters: RatingFilters, currentTab: string, sorter: RatingSorter ) => {
    return ( silent?: boolean ) => {
        const props = queries,
              request: Record<string, any> & {dataGroup: TableGroupType} = {
                  ...props.request,
                  dataGroup: sessionStorage.getItem( STORAGE_KEY ) as TableGroupType || 'RESTAURANTS'
              }
        if ( request.restaurantIds ) {
            request.factsNumbers = request.restaurantIds
            delete request.restaurantIds
        }
        addFiltersToRequest( request, filters )

        if ( sorter.tab && sorter.field && sorter.order ) {
            request.sort = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`
        }

        if ( !request?.dateEnd || !request?.dateStart ) { return }

        delete request.restaurantId
        delete request.employeeId
        delete request.zoneId

        switch ( currentTab ) {
            case 'average':
                props.flushAverageRating()
                props.fetchAllAverageCheck( request )
                props.fetchRatingAverageCheck( request )
                break
            case 'performance':
                props.flushPerformanceRating()
                props.fetchRatingPerformance( request )
                props.fetchSummaryPerformance( request )
                break
            case 'time':
                props.flushTimeRating()
                props.fetchAllServiceTime({ ...request, serviceType: null })
                props.fetchServiceTime({ ...request, serviceType: null })
                break
            case 'categories':
                !silent && props.flushCategoriesRating()
                props.fetchFilters()
                props.fetchCategoriesRating({
                    ...request,
                    franchiseeGroupCodes: filters.partner,
                })

                if ( silent ) {
                    const { category, manager } = filters

                    category &&
                    props.fetchCategoryManagers({
                        ...request,
                        categoryId: category.categoryId,
                    })
                    manager &&
                    props.fetchManagerRestaurants({
                        ...request,
                        categoryId: category.categoryId,
                        userId: manager.userId,
                    })
                }
                break
            case 'turnover':
                const req = { ...request }

                props.fetchFilters()
                props.flushTurnoverRating()
                props.fetchAllTurnover( req )
                props.fetchRatingTurnover( req )
                break
            default:
                props.flushTurnoverRating()
                break
        }
    }
}
